import { Button, Grid, Typography } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import cargillEmailSample from "assets/svg/cargillEmailSample.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMobileScreen from "hooks/useMobileScreen.hook";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "state/store";
import { useEffect } from "react";
import { fetchLogo } from "state/actions/home/home.action";

function LetsStart() {
  const classes = useStyles();
  const history = useHistory();
  const [t] = useTranslation();
  const mobileScreen = useMobileScreen();
  const dispatch = useAppDispatch();
  const countryCode = useSelector((state: RootState) => state.user.countryCode);
  const logo = useSelector((state: RootState) => state.home.logoImgSrc);

  useEffect(() => {
    if (countryCode) dispatch(fetchLogo(countryCode));
  }, [countryCode, dispatch]);

  return (
    <div className={classes.root}>
      <Grid container>
        {!mobileScreen && (
          <Grid item xs={12}>
            <img src={logo} className={classes.logo} alt='logo' />
          </Grid>
        )}
        <Grid item xs sm={6} className={classes.container}>
          <Grid container direction='column'>
            {mobileScreen && (
              <Grid item>
                <img src={logo} className={classes.logo} alt='logo' />
              </Grid>
            )}
            <Grid item>
              <Typography variant='h3' className={classes.title}>
                {t("thankyou")} {t("join_club_text")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                {t("thankyou_text_1")}
                <br />
                <br />
                {t("thankyou_text_2")}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                className={classes.cardButton}
                variant='contained'
                color='primary'
                onClick={() => history.push("/home")}
                size='medium'
              >
                {t("lets_start")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.container}>
          <img src={cargillEmailSample} alt='Cargill Email Sample' className={classes.emailImg} />
        </Grid>
      </Grid>
    </div>
  );
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "transparent linear-gradient(to Bottom, #FFE147B3 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
      opacity: 1,
      width: "100vw",
      height: "100vh",
      padding: "2rem",
      "& .MuiCardContent-root": {
        [theme.breakpoints.up("sm")]: {},
      },
      [theme.breakpoints.up("lg")]: {
        padding: "5rem",
        paddingTop: "2rem",
      },
    },
    logo: {
      width: "100%",
      height: "auto",
      display: "block",
      marginBottom: "1rem",
      [theme.breakpoints.up("sm")]: {
        width: "80%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      },
      [theme.breakpoints.up("lg")]: {
        width: "40%",
      },
    },
    container: {
      [theme.breakpoints.up("lg")]: {
        padding: "2rem",
      },
    },
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      marginBottom: "1rem",
      [theme.breakpoints.up("sm")]: {
        margin: "2rem auto",
        fontSize: "1.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.8rem",
      },
    },

    text: {
      fontSize: "1rem",
      marginBottom: "1rem",
      [theme.breakpoints.up("sm")]: {
        margin: "1rem auto",
        fontSize: "1.5rem",
      },
    },

    cardButton: {
      borderRadius: "20px",
      textDecoration: "none",
      width: "80%",
      display: "block",
      margin: "0.5rem auto",
      [theme.breakpoints.up("sm")]: {
        margin: "5rem auto",
        fontSize: "1.5rem",
        marginTop: "8rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2.1rem",
        marginTop: "12rem",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "2rem",
        width: "40%",
        fontSize: "1.5rem",
      },
    },

    emailImg: {
      width: "100%",
      height: "auto",
      margin: "1rem auto",
      borderRadius: "12px",
      [theme.breakpoints.up("sm")]: {
        display: "block",
        marginLeft: "1rem",
        marginRight: "auto",
      },
      [theme.breakpoints.up("lg")]: {
        width: "55%",
        margin: "1rem auto",
      },
    },
  })
);
export default LetsStart;
