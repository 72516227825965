import { MouseEvent, MouseEventHandler, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Toolbar, Typography, Popover, Box, PopoverOrigin, Tooltip } from "@material-ui/core";
import { RootState } from "state/store";
import pointsIcon from "assets/svg/points.svg";
import MenuIcon from "assets/svg/menuIcon.svg";
import referIcon from "assets/svg/referIcon.svg";
import productPointsIcon from "assets/svg/pointsProducts.svg";
import Sidedrawer from "./Sidedrawer.component";
import { PopoverI } from "interface/home.model";
import { useStyles } from "styles/navbar.styles";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";

const PopoverInitState: PopoverI = {
  anchorEle: null,
  id: undefined,
  open: false,
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};

const POINTS = "POINTS";
const REFER = "REFER";

interface MobileNavbarPropsI {
  logoImgSrc: string;
  showPointsMechanismFn: Function;
  navigateToHomeFn: MouseEventHandler<HTMLImageElement>;
  navigateToBasket: MouseEventHandler<HTMLImageElement>;
}

const MobileNavbar = (props: MobileNavbarPropsI) => {
  const [t] = useTranslation();
  const [pointsPopover, setPointsPopover] = useState(PopoverInitState);
  const [referPopOver, setReferPopOver] = useState(PopoverInitState);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState(t("copy"));
  const referralCode = useSelector((state: RootState) => state.user.user.referralCode);

  const classes = useStyles();
  const points = useSelector((state: RootState) => state.user.user.rewardPoints);

  const { logoImgSrc, navigateToHomeFn, showPointsMechanismFn } = props;

  const handlePopoverOpen = (event: MouseEvent, element: string) => {
    const target = event.target as HTMLButtonElement;
    if (element === POINTS) {
      setPointsPopover({
        anchorEle: target,
        id: "Points Popover",
        open: true,
      });
    } else if (element === REFER) {
      setReferPopOver({
        anchorEle: target,
        id: "Refer Popover",
        open: true,
      });
    }
  };

  const handlePopoverClose = (element: string) => {
    if (element === POINTS) setPointsPopover(PopoverInitState);
    else if (element === REFER) setReferPopOver(PopoverInitState);
  };

  const handleReferralCodeCopy = () => {
    navigator.clipboard.writeText(referralCode);
    setTooltipTitle(t("copied"));
  };

  return (
    <Toolbar className={classes.navbarContainer}>
      <Grid container alignItems='center'>
        <Grid item xs>
          <img
            src={productPointsIcon}
            className={classes.icon}
            alt='User Icon'
            onClick={() => showPointsMechanismFn(true)}
          />
        </Grid>
        <Grid item xs>
          <img
            src={pointsIcon}
            alt='Points'
            className={classes.icon}
            onClick={(event: MouseEvent) => handlePopoverOpen(event, POINTS)}
            aria-describedby={pointsPopover.id}
          />
          <Popover
            id={pointsPopover.id}
            open={pointsPopover.open}
            anchorEl={pointsPopover.anchorEle}
            onClose={() => handlePopoverClose(POINTS)}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            classes={{ paper: classes.mobileLangPaper }}
          >
            <Box className={classes.mobileLangSelector}>
              <Typography className={classes.pointsText}>
                {t("my_points")} {points}
              </Typography>
            </Box>
          </Popover>
        </Grid>
        <Grid item className={classes.logoContainer} xs={6} sm={5} md={4}>
          <img src={logoImgSrc} alt='logo' onClick={navigateToHomeFn} className={classes.logo} />
        </Grid>

        <Grid item xs>
          <img
            src={referIcon}
            className={classes.referIcon}
            alt='Refer Icon'
            onClick={(event: MouseEvent) => handlePopoverOpen(event, REFER)}
            style={{ float: "right" }}
          />
          <Popover
            id='refer popover'
            open={referPopOver.open}
            anchorEl={referPopOver.anchorEle}
            onClose={() => handlePopoverClose(REFER)}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            classes={{ paper: classes.referPopoverContainer }}
          >
            <Box>
              <Typography className={classes.referContent}>{t("refer_text")}</Typography>
              <Grid container alignItems='flex-end' className={classes.referralCodeContainer}>
                <Typography className={classes.referralCode}>{t("referral_code")}: </Typography>
                <Typography className={classes.referralCode}>{referralCode}</Typography>
                <Tooltip title={tooltipTitle} classes={{ tooltip: classes.toolTip }}>
                  <CopyIcon className={classes.copyIcon} onClick={handleReferralCodeCopy} />
                </Tooltip>
              </Grid>
            </Box>
          </Popover>
        </Grid>
        <Grid item xs>
          <img
            src={MenuIcon}
            className={classes.icon}
            style={{ float: "right" }}
            alt='User Icon'
            onClick={() => setOpenDrawer(true)}
          />
        </Grid>
      </Grid>
      <Sidedrawer openFl={openDrawer} closeFn={() => setOpenDrawer(false)} />
    </Toolbar>
  );
};

export default MobileNavbar;
