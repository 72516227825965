import { Component } from "react";
import errorFallbackImg from "assets/images/errorFallbackImg.png";
import { Box, Grid, Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) => {
  const largeScreen = theme.breakpoints.up("lg");

  return createStyles({
    errorBoundaryContainer: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-60%)",
      [largeScreen]: {
        position: "static",
        transform: "none",
      },
    },
    errorBoundaryImage: {
      width: "100%",
      height: "auto",
      margin: "auto",
      display: "block",
      [largeScreen]: {
        width: "60%",
      },
    },
    errorBoundaryHeadline: {
      fontSize: "1.5rem",
      margin: "1rem",
    },
    errorBoundaryText: {
      fontSize: "1.3rem",
      margin: "1rem",
    },
  });
};

interface Props extends WithStyles<typeof styles> {}
class ErrorBoundary extends Component<Props> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      return (
        <Box className={classes.errorBoundaryContainer}>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={12}>
              <img src={errorFallbackImg} alt='Error Fallback' className={classes.errorBoundaryImage} />
            </Grid>
            <Grid item>
              <Typography color='primary' variant='h4' className={classes.errorBoundaryHeadline}>
                Your site, AmphoChef is getting a make over!
              </Typography>
              <Typography variant='h3' className={classes.errorBoundaryText}>
                Site under maintenance. Expect us to be back soon!
              </Typography>
            </Grid>
          </Grid>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles, { withTheme: true })(ErrorBoundary);
