import { Button, Card, CardContent, Dialog, Grid, Container, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import CancelIcon from "@material-ui/icons/Cancel";
import Icon1 from "assets/svg/icon1.svg";
import Icon2 from "assets/svg/icon2.svg";
import Icon3 from "assets/svg/icon3.svg";
import Globe from "assets/svg/globe.svg";
import { Link } from "react-scroll";
import Registration from "components/login/Registration.component";
import SignIn from "components/login/SignIn";
import { Typography } from "components/common/Common.component";
import { loginStyles } from "styles/login.styles";
import { getAllZones, tokenExpiredActionCreator } from "state/actions/user/user.action";
import { RootState, useAppDispatch } from "state/store";
import { useTranslation } from "react-i18next";
import LanguageSelect from "components/login/LanguageSelect.component";

import { useState, useEffect } from "react";
import ContactUs from "components/login/ContactUs.component";
import { useSelector } from "react-redux";
import frenchMobileAppAvailable from "assets/images/frenchMobileAppAvailable.jpeg";
import spainMobileAppAvailable from "assets/images/spainMobileAppAvailable.jpg";
import portugalMobileAppAvailable from "assets/images/portugalMobileAppAvailable.jpg";
import { CountryCode } from "interface/user.model";
import { fetchLogo } from "state/actions/home/home.action";
import { makeStyles, Theme } from "@material-ui/core/styles";

interface mobileAppAvailableI {
  backgroundImage: string;
}

const mobileAppAvailableStyles = makeStyles<Theme, mobileAppAvailableI>((theme) => ({
  mobileAppPaper: {
    backgroundImage: (props) => `url(${props.backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "15rem",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      height: "30rem",
    },
  },
}));

const mobileAppImage = (countryCode: CountryCode) => {
  if (countryCode === "FRA") return frenchMobileAppAvailable;
  else if (countryCode === "ESP") return spainMobileAppAvailable;
  else if (countryCode === "PRT") return portugalMobileAppAvailable;
  return "";
};

function Login() {
  const countryCode = useSelector((state: RootState) => state.user.countryCode);
  const countries = useSelector((state: RootState) => state.user.countries);
  const logo = useSelector((state: RootState) => state.home.logoImgSrc);
  const logoContent = useSelector((state: RootState) => state.home.logoContent);
  const mobileAppStyles = mobileAppAvailableStyles({ backgroundImage: mobileAppImage(countryCode) });
  const classes = loginStyles();
  const [t, i18n] = useTranslation();
  const dispatch = useAppDispatch();

  const [showMore, setShowMore] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showAppDialog, setShowAppDialog] = useState(false);
  const [showLangDialog, setShowLangDialog] = useState(true);
  const [displayName, setDisplayName] = useState<string | undefined>(undefined);
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function scroll() {
    window.scroll({
      top: 100,
      left: 0,
      behavior: "smooth",
    });
    clearTimeout();
  }
  useEffect(() => {
    setTimeout(scroll, 1000);
  }, []);

  useEffect(() => {
    setShowAppDialog(!!mobileScreen);
  }, [mobileScreen]);

  useEffect(() => {
    dispatch(tokenExpiredActionCreator(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllZones(countryCode));
  }, [countryCode, dispatch]);

  useEffect(() => {
    if (countryCode) dispatch(fetchLogo(countryCode));
  }, [countryCode, dispatch]);

  useEffect(() => {
    if (countries && countryCode) {
      let lang = countries.find( (country) => {
        return country.countryCode === countryCode 
      });
      setDisplayName(lang?.displayName)
    }
  },[countries, countryCode])

  const handleRegisterScroll = (e: any) => {
    setShowMore(false);
  };

  return (
    <div className={classes.mainDiv}>
      <div className={classes.selectLang}>
        <Button className={classes.changeLang} style={{padding: '5px 10px', margin: '20px'}} onClick={() => setShowLangDialog(true)}>
          <img src={Globe} alt='bigImg' width='20' /> 
          <span className={classes.displayName}>{displayName}</span>
        </Button>
      </div>
      <Container>
        {showMore && (
          <Grid container className={classes.moreInfo}>
            <Grid item xs={12}>
              <CancelIcon
                color='primary'
                fontSize={"large"}
                style={{ cursor: "pointer", float: "right", marginRight: 0 }}
                onClick={() => {
                  setShowMore(false);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.stepContainer}>
              <Typography className={classes.stepContainerHeadline}>{t("step")} 1</Typography>
              <Typography className={classes.stepContainerText}>{t("create_account")}</Typography>
              <Link to='section3' spy={true} smooth={true} className={classes.linkStyle}>
                <p onClick={handleRegisterScroll}>{t("register_here")}</p>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.stepContainer}>
              <Typography className={classes.stepContainerHeadline}>{t("step")} 2</Typography>
              <Typography className={classes.stepContainerText}>{t("send_invoice")}</Typography>
              <br />
              <Typography className={classes.stepIconText}>
                <img src={Icon1} alt='bigImg' className={classes.icon} width='24' />
                {t("via_website")}
              </Typography>
              <Typography className={classes.stepIconText}>
                <img src={Icon2} alt='bigImg' className={classes.icon} width='24' />
                {t("via_email")}
              </Typography>
              <Typography className={classes.stepIconText}>
                <img src={Icon3} alt='bigImg' className={classes.icon} width='24' />
                {t("via_post")}
              </Typography>
              <br />
              <Typography className={classes.stepCreditText}>
                <b>{t("crediting_text")} </b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.stepContainer}>
              <Typography className={classes.stepContainerHeadline}>{t("step")} 3</Typography>
              <Typography className={classes.stepContainerText}>{t("choose_gift")}</Typography>
              <br />
              <Typography className={classes.stepCollectPoints}>
                {t("collect_points")}
                <br />
                <b>{t("delivery_text")} </b>
              </Typography>
            </Grid>
          </Grid>
        )}
        <div id='section1'>
          <div className={classes.topDiv}>
            <Card className={classes.loginCard}>
              <CardContent className={classes.cardContainer}>
                <Grid container direction='column'>
                  <Grid item>
                    <img src={logo} height={145} alt='logo' style={{padding: "25px 25px 30px 25px"}}/>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.cardLoyaltyText}>
                      {logoContent}
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: "100%", margin: "0.5rem 0", paddingRight: "2rem" }}>
                    <Typography
                      className={classes.moreInfoText}
                      onClick={() => {
                        setShowMore(true);
                      }}
                    >
                      {t("more_info")}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Grid container direction={mobileScreen ? "column" : "row"}>
                <Grid item xs>
                  <Link to='section2' spy={true} smooth={true}>
                    <Button className={classes.loginBtn}>{t("member_login")}</Button>
                  </Link>
                </Grid>
                <Grid item xs>
                  <Link to='section3' spy={true} smooth={true}>
                    <Button className={classes.joinBtn}>{t("join_club")}</Button>
                  </Link>
                </Grid>
              </Grid>
            </Card>
            <Button className={classes.changeLang} onClick={() => setShowDialog(true)}>
              {t("contact_us")}
            </Button>
          </div>
        </div>
        <div id='section2'>
          <div className={classes.signInDiv}>
            <SignIn language={i18n.language} />
          </div>
        </div>
        <div id='section3'>
          <div className={classes.registrationDiv}>
            <Registration />
          </div>
        </div>

        <ContactUs openFl={showDialog} closeFn={() => setShowDialog(false)} />

        <Dialog
          open={showAppDialog}
          onClose={() => setShowAppDialog(false)}
          maxWidth={"sm"}
          classes={{ paper: mobileAppStyles.mobileAppPaper }}
        >
          <CancelIcon
            color='primary'
            fontSize={"large"}
            className={classes.cancelIcon}
            onClick={() => setShowAppDialog(false)}
          />
        </Dialog>
      </Container>
      <LanguageSelect
        showLangDialog = {showLangDialog}
        setShowLangDialog = {() => setShowLangDialog(false)}
      />
    </div>
  );
}

export default Login;
