import { Basket, ArticleInBasketI, ArticleItemImgI, UpdateArticleInBasketI } from "interface/basket.model";

// cart types
export const BASKET_ITEMS = "basket_items";
export const BASKET_ITEM_IMG_SRC = "basket_item_img_src";
export const ADD_ARTICLE_TO_BASKET = "add_article_to_basket";
export const UPDATE_ARTICLE_IN_BASKET = "update_article_in_basket";
export const REMOVE_ARTICLE_FROM_BASKET = "remove_article_from_cart";
export const UPDATE_POINTS_IN_BASKET = "update_points_in_basket";
export const UPDATE_BASKET_ADDRESS_ID = "update_basket_address_id";
export const QUANTITY_ERROR = "quantity_error";
export const LOADER_FOR_GIFTS = "loader_for_gifts";

// ActionTypes

interface BasketItemsActionType {
  type: typeof BASKET_ITEMS;
  payload: Basket;
}

interface BasketItemImgSrcActionType {
  type: typeof BASKET_ITEM_IMG_SRC;
  payload: ArticleItemImgI;
}

interface AddArticleToBasketActionType {
  type: typeof ADD_ARTICLE_TO_BASKET;
  payload: ArticleInBasketI;
}

interface UpdateArticleInBasketActionType {
  type: typeof UPDATE_ARTICLE_IN_BASKET;
  payload: UpdateArticleInBasketI;
}

interface RemoveArticleFromBasketActionType {
  type: typeof REMOVE_ARTICLE_FROM_BASKET;
  payload: number;
}

interface UpdateBasketPointsActionType {
  type: typeof UPDATE_POINTS_IN_BASKET;
  payload: number;
}

interface UpdateBasketAddressId {
  type: typeof UPDATE_BASKET_ADDRESS_ID;
  payload: number;
}

interface QuantityErrActionType {
  type: typeof QUANTITY_ERROR;
  payload: any;
}

interface LoaderForGiftsActionType {
  type: typeof LOADER_FOR_GIFTS;
  payload: any;
}

export type BasketActionType =
  | BasketItemsActionType
  | BasketItemImgSrcActionType
  | AddArticleToBasketActionType
  | UpdateArticleInBasketActionType
  | RemoveArticleFromBasketActionType
  | UpdateBasketPointsActionType
  | UpdateBasketAddressId
  | QuantityErrActionType
  | LoaderForGiftsActionType;
