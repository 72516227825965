import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const useExtraLargeScreen = (): boolean => {
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up("lg"));
  if (isWideScreen) return true;
  else return false;
};

export default useExtraLargeScreen;
