import {
  ADD_ADDRESS,
  DELETE_ADDRESS,
  REGISTER_USER,
  REGISTER_USER_ERROR_MESSAGE,
  SET_LANG,
  SET_OKTAID,
  SET_TOKEN,
  SET_USER,
  UPDATE_ADDRESS,
  UPDATE_USER,
  UPDATE_USER_POINTS,
  SHOW_MOBILE_QR_CODE_DIALOG,
  CustomerActionType,
  COUNTRIES,
  TOKEN_EXPIRED,
  ZONE,
  COUNTRY_CODE,
} from "../actions/user/user.type";

import { UserI, UserStateI } from "interface/user.model";

const intialUser: UserI = {
  userId: 0,
  oktaId: "0",
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  restaurant: "",
  function: "",
  rewardPoints: 0,
  userLocale: "fr",
  siretNumber: "",
  userAddresses: [],
  countryCode: "FRA",
  referredByCode: undefined,
  referralCode: "",
  referralStatus: false,
  rejectedReason: undefined,
};

const initState: UserStateI = {
  localeCode: "fr",
  user: intialUser,
  userRegistrationStatus: "",
  userRegistrationErrorMsg: "",
  oktaId: "",
  oktaToken: "",
  showMobileQRCodeDialog: false,
  countries: [],
  isTokenExpired: false,
  zone: undefined,
  countryCode: "FRA",
};

export const userReducer = (state: UserStateI = initState, action: CustomerActionType): UserStateI => {
  switch (action.type) {
    case REGISTER_USER: {
      return { ...state, userRegistrationStatus: action.payload };
    }
    case REGISTER_USER_ERROR_MESSAGE: {
      return { ...state, userRegistrationErrorMsg: action.payload };
    }
    case SET_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case UPDATE_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          siretNumber: action.payload.siretNumber,
          restaurant: action.payload.restaurant,
          function: action.payload.function,
        },
      };
    }
    case UPDATE_USER_POINTS: {
      return {
        ...state,
        user: {
          ...state.user,
          rewardPoints: action.payload,
        },
      };
    }
    case ADD_ADDRESS: {
      return {
        ...state,
        user: {
          ...state.user,
          userAddresses: [...state.user.userAddresses, action.payload],
        },
      };
    }
    case UPDATE_ADDRESS: {
      return {
        ...state,
        user: {
          ...state.user,
          userAddresses: state.user.userAddresses.map((address) => {
            if (address.userAddressId === action.payload.userAddressId) {
              return {
                ...address,
                name: action.payload.name,
                street: action.payload.street,
                city: action.payload.city,
                postalCode: action.payload.postalCode,
                phoneNumber: action.payload.phoneNumber,
              };
            }
            return address;
          }),
        },
      };
    }
    case DELETE_ADDRESS: {
      return {
        ...state,
        user: {
          ...state.user,
          userAddresses: state.user.userAddresses.filter((address) => address.userAddressId !== action.payload),
        },
      };
    }
    case SET_OKTAID: {
      return {
        ...state,
        oktaId: action.payload,
      };
    }
    case SET_TOKEN: {
      return {
        ...state,
        oktaToken: action.payload,
      };
    }
    case SET_LANG: {
      localStorage.setItem("lang", action.payload);
      return {
        ...state,
        localeCode: action.payload,
      };
    }
    case SHOW_MOBILE_QR_CODE_DIALOG: {
      return {
        ...state,
        showMobileQRCodeDialog: action.payload,
      };
    }
    case COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case TOKEN_EXPIRED:
      return {
        ...state,
        isTokenExpired: action.payload,
      };
    case ZONE:
      return {
        ...state,
        zone: action.payload,
      };
    case COUNTRY_CODE: {
      sessionStorage.setItem("countryCode", action.payload);
      return {
        ...state,
        countryCode: action.payload,
      };
    }
    default:
      return state;
  }
};
