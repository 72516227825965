import { MouseEventHandler } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Badge, Grid, Toolbar } from "@material-ui/core";
import { navbarPages } from "routes";

import cartIcon from "assets/svg/cart.svg";
import useGiftsCountInCart from "hooks/useGiftsCountInCart.hook";
import { useStyles } from "styles/navbar.styles";

interface WideNavbarPropsI {
  logoImgSrc: string;
  navigateToHomeFn: MouseEventHandler<HTMLImageElement>;
  navigateToBasket: MouseEventHandler<HTMLImageElement>;
}

// const PopoverInitState: PopoverI = {
//   anchorEle: null,
//   id: undefined,
//   open: false,
// };

const WideNavbar = (props: WideNavbarPropsI) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const giftsCount = useGiftsCountInCart();
  // const [cartPopover, setCartPopover] = useState(PopoverInitState);

  const { navigateToBasket } = props;

  return (
    <Toolbar className={classes.wideNavbarContainer}>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Grid container justifyContent='flex-start'>
            {navbarPages.map((page, index) => (
              <Grid item key={index} className={classes.navEle}>
                <NavLink className={classes.navlink} to={page.pageLink} exact>
                  {t(page.name)}
                </NavLink>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item className={classes.iconContainer}>
          <Badge badgeContent={giftsCount} color='secondary' anchorOrigin={{ vertical: "top", horizontal: "right" }}>
            <img src={cartIcon} height={41} alt='Cart Icon' onClick={navigateToBasket} />
          </Badge>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default WideNavbar;
