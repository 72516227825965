import { DistributorDataI, FileSrc, InvoiceHistoryI } from "interface/invoice.model";

// constants
export const DISTRIBUTORS = "distributors";
export const DISTRIBUTOR_REFERENCE_MAP = "distributor_reference_map";

export const PRODUCTS = "products";
export const PRODUCT_REFERENCE_MAP = "product_reference_map";
export const PRODUCTS_CAPACITY_LIST = "products_capacity_list";

export const INVOICE_UPLOAD_ATTACHMENT_STATUS = "invoice_upload_attachment_status";
export const INVOICE_ATTCHMENT_NAME = "invoice_attachment_name";
export const INVOICE_ATTACHMENT_UPLOAD_PROGRESS = "invoice_attachment_upload_progress";
export const INVALID_FILE = "invalid_file";

export const INVOICE_SUCCESS = "invoice_success";
export const INVOICE_FAILURE_MESSAGE = "invoice_failure_message";

export const NEW_INVOICES = "new_invoices";
export const ACCEPTED_INVOICES = "accepted_invoices";
export const REJECTED_INVOICES = "rejected_invoices";
export const FILE_SRC = "file_src";

interface DistributorDataActionType {
  type: typeof DISTRIBUTOR_REFERENCE_MAP;
  payload: DistributorDataI[];
}

interface DistributorActionType {
  type: typeof DISTRIBUTORS;
  payload: [];
}

interface ProductsActionType {
  type: typeof PRODUCTS;
  payload: [];
}

interface ProductsCapacityActionType {
  type: typeof PRODUCTS_CAPACITY_LIST;
  payload: {};
}

interface ProductsRefMapActionType {
  type: typeof PRODUCT_REFERENCE_MAP;
  payload: {};
}

interface InvoiceUploadProgressActionType {
  type: typeof INVOICE_ATTACHMENT_UPLOAD_PROGRESS;
  payload: number;
}

interface InvoiceUploadStatusActionType {
  type: typeof INVOICE_UPLOAD_ATTACHMENT_STATUS;
  payload: boolean | undefined;
}

interface InvalidFileActionType {
  type: typeof INVALID_FILE;
  payload: boolean;
}

interface InvoiceNameActionType {
  type: typeof INVOICE_ATTCHMENT_NAME;
  payload: string;
}

interface InvoiceSuccessActionType {
  type: typeof INVOICE_SUCCESS;
  payload: boolean | undefined;
}

interface InvoiceFailMsgActionType {
  type: typeof INVOICE_FAILURE_MESSAGE;
  payload: string;
}

interface InvoicesProcessedActionType {
  type: typeof NEW_INVOICES;
  payload: Array<InvoiceHistoryI>;
}

interface InvoicesAcceptedActionType {
  type: typeof ACCEPTED_INVOICES;
  payload: Array<InvoiceHistoryI>;
}

interface InvoicesRejectedActionType {
  type: typeof REJECTED_INVOICES;
  payload: Array<InvoiceHistoryI>;
}

interface FileSrcActionType {
  type: typeof FILE_SRC;
  payload: FileSrc;
}

export type DistributorsActionType = DistributorActionType | DistributorDataActionType;

export type InvoiceProductsActionType = ProductsActionType | ProductsCapacityActionType | ProductsRefMapActionType;

export type InvoiceAttachmentActionType =
  | InvoiceUploadProgressActionType
  | InvoiceUploadStatusActionType
  | InvalidFileActionType
  | InvoiceNameActionType
  | FileSrcActionType;

export type InvoiceFormActionType = InvoiceSuccessActionType | InvoiceFailMsgActionType;

export type ProcessedInvoicesActionType =
  | InvoicesProcessedActionType
  | InvoicesAcceptedActionType
  | InvoicesRejectedActionType;

export type InvoiceActionType =
  | DistributorsActionType
  | InvoiceProductsActionType
  | InvoiceAttachmentActionType
  | InvoiceFormActionType
  | ProcessedInvoicesActionType;
