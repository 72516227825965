import React, { useState } from "react";
import { AppBar, useScrollTrigger } from "@material-ui/core";
import { RootState } from "state/store";

import PointsMechanismModal from "../common/PointsMechanismDialog.component";
import HeaderStrip from "./HeaderStrip.component";
import WideNavbar from "./WideNavbar.component";
import MobileNavbar from "./MobileNavbar.component";

import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import history from "routes/history";
import { useStyles } from "styles/navbar.styles";
import { useSelector } from "react-redux";
import useLogout from "hooks/useLogout.hook";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 0 : 0,
  });
}

export function NavBar() {
  const classes = useStyles();
  const theme = useTheme();
  const tabScreen = useMediaQuery(theme.breakpoints.down("md"));
  const logout = useLogout();

  const logoImgSrc = useSelector((state: RootState) => state.home.logoImgSrc);

  const [mechanismDlg, setMechansimDlg] = useState(false);

  const navigateToHome = () => {
    history.push("/");
  };

    const navigateToBasket = () => {
      history.push("/basket");
    };

  return (
    <ElevationScroll>
      <AppBar className={classes.appBar}>
        <HeaderStrip
          showPointsMechanismFn={setMechansimDlg}
          logout={logout}
          logoImgSrc={logoImgSrc}
          navigateToHomeFn={navigateToHome}
        />
        {tabScreen ? (
          <MobileNavbar
            logoImgSrc={logoImgSrc}
            navigateToHomeFn={navigateToHome}
            navigateToBasket={navigateToBasket}
            showPointsMechanismFn={setMechansimDlg}
          />
        ) : (
          <WideNavbar logoImgSrc={logoImgSrc} navigateToHomeFn={navigateToHome} navigateToBasket={navigateToBasket} />
        )}
        <PointsMechanismModal mechanismDlg={mechanismDlg} setMechansimDlg={setMechansimDlg} />
      </AppBar>
    </ElevationScroll>
  );
}
