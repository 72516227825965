import { OfferObjI, PointsMechanismI, LegalDocsObjI } from "interface/home.model";

//offer action types
export const OFFERS = "set_offers";
export const UPLOAD_SERVICE_REQUEST = "upload_service_request";
export const SHOW_LOADER = "show_loader";
export const HOME_MOUNTED = "home_mounted";
export const PRODUCT_VOLUME_POINTS = "product_volume_points";

export const LOGO = "logo";
export const LOGO_CONTENT = "logo_content";
export const LEGALDOCS =  "legal_docs";

interface OffersAction {
  type: typeof OFFERS;
  payload: OfferObjI[];
}

interface UploadServiceRequestActionType {
  type: typeof UPLOAD_SERVICE_REQUEST;
  payload: boolean | undefined;
}

interface ShowLoaderActionType {
  type: typeof SHOW_LOADER;
  payload: boolean;
}

interface isHomeCompMounted {
  type: typeof HOME_MOUNTED;
  payload: boolean;
}

interface fetchLogo {
  type: typeof LOGO;
  payload: string;
}

interface fetchLogoContent {
  type: typeof LOGO_CONTENT;
  payload: string;
}

interface fetchLegalDocs {
  type: typeof LEGALDOCS;
  payload: LegalDocsObjI;
}

interface ProductVolumePointsActionType{
  type: typeof PRODUCT_VOLUME_POINTS;
  payload: PointsMechanismI;
}

export type homeActionType =
  | OffersAction
  | UploadServiceRequestActionType
  | ShowLoaderActionType
  | isHomeCompMounted
  | fetchLogo
  | fetchLogoContent
  | fetchLegalDocs
  | ProductVolumePointsActionType;
