import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import "./App.css";
import RootNavigator from "./routes/RootNavigator";

import { theme } from "styles/theme";
import { Provider } from "react-redux";
import store, { persistor } from "./state/store";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "components/common/ErrorBoundary.component";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <div className='App'>
            {/* The persist gate would not only receive the store values but also 
                rehydrate the state whenever our application refreshes */}
            <PersistGate persistor={persistor}>
              <RootNavigator />
            </PersistGate>
          </div>
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
