import { Dialog, Grid } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { useStyles } from "styles/navbar.styles";
import { useTranslation } from "react-i18next";
import { Typography } from "components/common/Common.component";
import useMobileScreen from "hooks/useMobileScreen.hook";
import { ProductPointsI } from "interface/home.model";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { useEffect, useState } from "react";

interface Props {
  mechanismDlg: boolean;
  setMechansimDlg: Function;
}

const PointsMechanismModal = (props: Props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const isMobileScreen = useMobileScreen();
  const [products, setProducts] = useState<ProductPointsI[]>([]);
  const [bonus, setBonus] = useState<ProductPointsI[]>([]);

  const productVolumes = useSelector((state: RootState) => state.home.productsVolumeAndPoints?.productVolumes);
  const productVolumeBonus = useSelector((state: RootState) => state.home.productsVolumeAndPoints?.productVolumeBonus);

  useEffect(() => {
    if (productVolumes && productVolumes.length > 0) setProducts(productVolumes);
    if (productVolumeBonus && productVolumeBonus.length > 0) setBonus(productVolumeBonus);
  }, [productVolumeBonus, productVolumes]);

  const { mechanismDlg, setMechansimDlg } = props;
  return (
    <Dialog
      open={mechanismDlg}
      onClose={() => setMechansimDlg(false)}
      maxWidth={"sm"}
      fullWidth
      classes={{ paper: classes.mechanismBackgroundStyles }}
    >
      <div className={classes.dialogDiv}>
        <CancelIcon
          color='primary'
          fontSize={isMobileScreen ? "medium" : "large"}
          className={classes.cancelIcon}
          onClick={() => setMechansimDlg(false)}
        />
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9} sm={7}>
                <Typography className={classes.productsTitle}>{t("products")}</Typography>
              </Grid>
              <Grid item sm={4}></Grid>
              <Grid item xs={3} sm={1}>
                <Typography className={classes.pointsTitle}>{t("points")}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {products.map((product, index) => (
                <Grid container key={index} className={classes.productRow}>
                  <Grid item xs={9} sm={6}>
                    <Typography className={classes.productName}>
                      {product.productName} {!isMobileScreen ? product.volume : null}
                    </Typography>
                    {isMobileScreen && <Typography className={classes.productName}>{product.volume}</Typography>}
                  </Grid>
                  {!isMobileScreen && (
                    <Grid item xs={6} sm={5}>
                      <div className={classes.border} />
                    </Grid>
                  )}
                  <Grid item xs={2} sm={1}>
                    <Typography className={classes.productPoints}>{Number(product.points)}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} sm={2}>
            <Typography className={classes.productsTitle}>{t("bonus")}</Typography>
          </Grid>
        </Grid>
        {bonus.map((bonusCat, index) => (
          <Grid key={index} container>
            <Grid item xs={9} sm={4}>
              <Typography className={classes.productName}>{t(bonusCat.productName)}</Typography>
            </Grid>
            {!isMobileScreen && (
              <Grid item xs={6} sm={7}>
                <div className={classes.border} />
              </Grid>
            )}
            <Grid item xs={2} sm={1}>
              <Typography className={classes.productPoints}>{bonusCat.points}</Typography>
            </Grid>
          </Grid>
        ))}
        <Typography className={classes.supportSection}>{t("regulations")}</Typography>
      </div>
    </Dialog>
  );
};

export default PointsMechanismModal;
