import { Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  src: string;
}

const useStyles = makeStyles({
  embed: {
    height: "100vh",
    width: "100%",
  },
});
export function PdfEmbeder(props: Props) {
  const { src } = props;
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <Box>
      {src ? (
        <embed className={classes.embed} src={src} title={"pdf"} type='application/pdf' />
       ) : (
        <Typography align='center' style={{margin: "10%"}}>{t("pdf_unavailable_text")}</Typography>
       )}
    </Box>
  );
}
