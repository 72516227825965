const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "{clientId}";
const ISSUER = process.env.REACT_APP_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = `${window.location.origin}/login/callback`;
let USE_INTERACTION_CODE = false;
const APP_URL = process.env.REACT_APP_BASE_URL;
const APP_VERSION = process.env.REACT_APP_VERSION;
if (process.env.USE_INTERACTION_CODE === "true") {
  USE_INTERACTION_CODE = true;
}
const API_KEY = process.env.REACT_APP_APIKEY;

export const oidc = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ["openid", "profile", "email", "amphora.api.default"],
  pkce: true,
  disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  useInteractionCode: USE_INTERACTION_CODE,
};

const app_version = APP_VERSION ? APP_VERSION.split(".") : [];
export const BASE_URL = `${APP_URL}${app_version[0]}/`;

window.__APP_CONFIG__ = {
  login: {
    entry: '/login',
    redirectAfterLogin: '/home',
    redirectAfterLogout: '/login'
  },
  server: {
    host: window.location.origin.split("/login")[0],
    path: '/login',
  },
  oktaOIDC: {
    baseUrl: ISSUER && ISSUER.split("/oauth2")[0],
    clientId: CLIENT_ID,
    issuerPath: ISSUER && ISSUER.split("/oauth2")[1],
    loginPath: "/login",
    redirectUri: "/login/callback",
    samlFromUri: window.location.origin,
    samlRedirectUri: "/home",
    samlUri: ISSUER,
  },
}

export function authHeader() {
  var oktaToken: any = "";
  // return authorization header with jwt token
  oktaToken = localStorage.getItem("okta-token-storage");
  // var oktaToken1 = localStorage.getItem("okta-token-storage1");
  let user = JSON.parse(oktaToken);

  if (user !== null && user !== undefined && Object.keys(user).length !== 0 && user.accessToken) {
    const { accessToken } = user.accessToken;
    return { Authorization: `Bearer ${accessToken}` };
  } else {
    return null;
  }
}

export function getAPIKey() {
  return { apiKey: API_KEY };
}

export function getOktaId() {
  var oktaToken: any = "";
  // return authorization header with jwt token
  oktaToken = localStorage.getItem("okta-token-storage");
  let user = JSON.parse(oktaToken);
  if (user === null || user === undefined || Object.keys(user).length === 0 || user.idToken === undefined) {
    return undefined;
  }
  return user.idToken.claims.sub;
}

export function _base64toImageFile(b64Str: any, filename: string): File {
  const arr = b64Str.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  var n: number = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const file: File = new File([u8arr], filename, { type: mime });
  return file;
}
