import { MessageI, NotificationPreferencesI, PointsHistoryI } from "interface/account.model";

export const MESSAGES = "messages";
export const USER_PREFERENCES = "user_preferences";
export const UPDATE_USER_PREFERENCES = "update_user_preferences";
export const POINTS_HISTORY = "points_history";

interface MessagesActionType {
  type: typeof MESSAGES;
  payload: MessageI;
}

interface UserPreferencesActionType {
  type: typeof USER_PREFERENCES;
  payload: NotificationPreferencesI;
}

interface UpdateUserPrefActionType {
  type: typeof UPDATE_USER_PREFERENCES;
  payload: boolean;
}

interface PointsHistoryActionType {
  type: typeof POINTS_HISTORY;
  payload: PointsHistoryI;
}

export type AccountActionType =
  | MessagesActionType
  | UserPreferencesActionType
  | UpdateUserPrefActionType
  | PointsHistoryActionType;
