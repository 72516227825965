import MuiTypography, { TypographyProps } from "@material-ui/core/Typography";
import { Theme, styled } from "@material-ui/core/styles";

import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import MuiButton from "@material-ui/core/Button";
import MuiGrid from "@material-ui/core/Grid";
import MuiTextField from "@material-ui/core/TextField";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { spacing } from "@material-ui/system";

// import { theme } from "styles/theme";

export const Button = styled(MuiButton)(spacing);
export const TextField = styled(MuiTextField)(spacing);
export const Grid = styled(MuiGrid)(spacing);

interface TypoProps extends TypographyProps {
  fs?: string;
  fw?: React.CSSProperties["fontWeight"];
  ff?: React.CSSProperties["fontFamily"];
  lh?: React.CSSProperties["lineHeight"];
  ls?: React.CSSProperties["letterSpacing"];
  fc?: React.CSSProperties["color"];
  theme: Theme;
}
const Typography1 = styled(MuiTypography)(spacing);
// export const Typography2 = styled(({fs, ...otherProps}))
export const Typography = styled(Typography1)((props: TypoProps) => {
  return {
    fontSize: props.fs,
    // fontWeight : props.fw,
    fontFamily: props.ff,
    lineHeight: props.lh,
    color: props.fc,
    letterSpacing: props.ls,
  };
});

export const LeftArrow = styled(ArrowBackSharpIcon)({
  height: "30px",
  width: "30px",
});

export const PlusCircle = styled(RemoveCircleOutlineIcon)({});
