import { TextField } from "./Common.component";
import { styled } from "@material-ui/core";

export const InputText = styled(TextField)({
  width: "100%",
  backgroundColor: "#63656A14",
  "& .MuiInputBase-root": {
    padding: "3px 0px 0 8px",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "&:hover .MuiInput-underline:before": {
    borderBottom: "none",
  },
});
