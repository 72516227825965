import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const useMobileScreen = (): boolean => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  if (mobileScreen) return true;
  else return false;
};

export default useMobileScreen;
