export const registration = {
  loyalty_text_1: {
    en: "Join the loyalty program and",
    fr: "Rejoignez le programme de fidélité et",
    es: "Únete al programa de fidelización ",
    pt: "Una-se ao clube",
  },
  loyalty_text_2: {
    en: "receive",
    fr: "recevez",
    es: "y recibe",
    pt: "e receber",
  },
  loyalty_text_3: {
    en: "your free welcome kit*",
    fr: "votre kit de bienvenue* ",
    es: "tu kit de bienvenida",
    pt: "seu kit de boas vindas",
  },

  offer_availability: {
    en: "*Offer available for the first 100 subscriptions – Welcome kit will be dispatched after receiving the first invoice",
    fr: "*Offre valable pour les 100 premières inscriptions – envoi du kit dès réception de la première facture",
    es: "Oferta válida para las primeras 100 inscripciones - envío del kit al recibir la primera factura",
    pt: "Oferta válida para as primeiras 100 inscrições - envio do kit no recebimento da primeira fatura",
  },
  option: {
    en: "OPTION",
    fr: "OPTION",
    es: "OPCIÓN",
    pt: "OPÇÃO",
  },
  option_1_text: {
    en: "Consult your AMPHORA sales representative. He will give you a form and take care of your registration",
    fr: "Consultez votre commercial AMPHORA. Il vous donnera un formulaire à remplir et s'occupera de votre inscription",
    es: "Consulte a su delegado comercial de AMPHORA",
    pt: "Consulte o seu representante de vendas AMPHORA",
  },
  option_2_text: {
    en: "Register online by filling out the form below",
    fr: "Enregistrez-vous en ligne en remplissant ce formulaire",
    es: "Regístrese en línea completando este formulario",
    pt: "Registre-se online preenchendo este formulário",
  },
  first_name: {
    en: "First Name",
    fr: "Prénom ",
    es: "1er Apellido",
    pt: "1.º Apelido",
  },
  last_name: {
    en: "Last Name",
    fr: "Nom de famille",
    es: "2º Apellido",
    pt: "2.º Apelido",
  },
  restaurant_name: {
    en: "Restaurant Name",
    fr: "Nom de l'établissement",
    es: "Restaurante",
    pt: "Restaurante",
  },
  job_title: {
    en: "Job Title",
    fr: "Fonction",
    es: "Título profesional",
    pt: "Título profissional / Cargo",
  },
  email: {
    en: "Email",
    fr: "Email",
    es: "Email",
    pt: "Email",
  },
  rest_address: {
    en: "Restaurant Address",
    fr: "Adresse du restaurant",
    es: "Dirección del restaurante",
    pt: "Endereço do restaurante",
  },
  address: {
    en: "Address",
    fr: "Adresse",
    es: "Dirección",
    pt: "Endereços",
  },
  phone: {
    en: "Phone Number",
    fr: "Téléphone",
    es: "Número de teléfono",
    pt: "Telefone",
  },
  country: {
    en: "Country",
    fr: "Pays",
    es: "País",
    pt: "País",
  },
  check_box_t1: {
    en: "By checking the box,  I authorize that I have read and agree to the",
    fr: "En cochant la case, j’ai lu et accepte les",
    es: "He leído y acepto los Términos y Condiciones del ",
    pt: "Li e aceito os Termos e Condições do",
  },
  check_box_t2: {
    en: " of the Loyalty Program.",
    fr: " du programme de fidélité.",
    es: " Programa de Fidelización.",
    pt: " Programa de Fidelização.",
  },
  of_cargill: {
    fr: "de Cargill",
    en: "of Cargill",
    es: "de Cargill",
    pt: "de Cargill",
  },
  register: {
    en: "Register",
    fr: "S'enregistrer",
    es: "Registrarse",
    pt: "Registar-se",
  },
  street: {
    en: "Street",
    fr: "Rue",
    es: "Calle",
    pt: "Rua",
  },
  city: {
    en: "City",
    fr: "Ville",
    es: "Ciudad",
    pt: "Localidade",
  },
  zip_code: {
    en: "Zip Code",
    fr: "Code postal",
    es: "codigo postal",
    pt: "Código postal",
  },
  name: {
    en: "Name",
    fr: "Nom",
    es: "Nombre",
    pt: "Nome",
  },
  siret_number: {
    en: "SIRET Number",
    fr: "Numéro de SIRET",
    es: "NIF",
    pt: "Número de contribuinte",
  },
  required_field: {
    en: "Required",
    fr: "Obligatoire",
    es: "Requerido",
    pt: "Requerida",
  },
  email_error: {
    en: "Enter a valid Email",
    fr: "Entrez une adresse mail valide ",
    es: "por favor, introduzca una dirección de email valida",
    pt: "Por favor insira um endereço de e-mail válido",
  },
  siret_number_error: {
    en: "Siret Number must be of",
    fr: "Le numéro de SIRET doit contenir",
    es: "El número SIRET debe contener",
    pt: "O número de contribuinte tem de ter",
  },
  zipcode_error: {
    en: "Zip Code must be of",
    fr: "Le code postal doit contenir",
    es: "codigo postal debe tener",
    pt: "O código postal tem de ter",
  },
  referrence_error: {
    en: "Referral Code must be of 8 characters",
    fr: "Le code de Parrainage doit être de 8 caractères",
    es: "El código de referencia debe tener 8 caracteres",
    pt: "o código de referência deve ter 8 caracteres",
  },
  number_must: {
    en: "Must be digits - Only numbers accepted",
    fr: "Seuls des chiffres sont acceptés ",
    es: "debe consistir en digitos - Solo se permiten números",
    pt: "Tem de consistir em algarismos",
  },
  notifcation_consent: {
    en: "I agree to receive notifications containing product information, promotional information, newsletters and special offers for Amphora products from Cargill.",
    fr: "J’accepte de recevoir des notifications sur les produits, les promotions, les newsletter et les offres spéciales pour les produits Amphora.",
    pt: "Aceito receber notificações que contenham informações sobre produtos, informação promocional, boletins informativos e ofertas especiais para os produtos Amphora da Cargill.",
  },
  email_consent: {
    en: "Emails to my Inbox",
    fr: "par mail",
    es: "por correo",
    pt: "pelo correio",
  },
  mobile_consent: {
    en: "Mobile app notifications",
    fr: "par telephone",
    es: "por notificación móvil",
    pt: "Notificações da aplicação para telemóvel",
  },
  calls_consent: {
    en: "Calls to my number",
    fr: "par appel téléphonique",
    es: "por llamada telefónica",
    pt: "Telefonemas para o meu número",
  },
  accept_the_terms: {
    en: "Please select the Terms and Conditions Checkbox.",
    fr: "Merci de cocher la case correspondantes aux conditions générales.",
    es: "Marque la casilla de verificación de términos y condiciones",
    pt: "As preferências de notificação do utilizador foram corretamente atualizadas ",
  },
  phone_number_error: {
    en: "Phone Number must be of",
    fr: "Le numéro de téléphone doit contenir",
    es: "El número de telefono debe tener",
    pt: "O número de telefone deve ter",
  },
  digits: {
    en: "digits",
    fr: "chiffres",
    es: "digitos",
    pt: "dígitos",
  },
  noSplChar: {
    en: "Cannot contain special characters and numbers",
    fr: "Ne peut pas contenir de caractères spéciaux et de chiffres",
    es: "No puede contener caracteres especiales ni números.",
    pt: "Não pode conter caracteres especiais ou números.",
  },
  enter_valid_captcha: {
    fr: "Merci de saisir correctement le Captcha ",
    en: "Please Enter Captcha Correctly",
    es: "Ingrese el Captcha correctamente",
    pt: "Digite o Captcha corretamente",
  },
  select_language: {
    en: "Select Your Language",
    fr: "Choisissez votre langue",
    es: "Elige tu idioma",
    pt: "Escolha seu idioma",
  },
  amphochef_store: {
    en: "AmphoChef app is available in the App store and Play store.",
    fr: "L'application AmphoChef est disponible sur l'App Store et le Play store!",
    es: "¡La aplicación AmphoChef está disponible en App Store y Play Store!",
    pt: "O aplicativo AmphoChef está disponível na App Store e na Play Store!",
  },
  download_now: {
    en: "Download Now!",
    fr: "Télécharger maintenant!",
    es: "¡Descargar ahora!",
    pt: "Baixe Agora!",
  },
  no_spl_char: {
    en: "Cannot contain special characters and numbers",
    fr: "Ne peut pas contenir de caractères spéciaux et de chiffres",
    es: "No puede contener caracteres especiales ni números.",
    pt: "Não pode conter caracteres especiais e números",
  },
};

export const thankyouCard = {
  thankyou: {
    en: "Thank you",
    fr: "Merci ",
    es: "¡Gracias",
    pt: "Obrigado",
  },
  join_club_text: {
    en: "for joining the Club!",
    fr: "d’avoir rejoint le Club !",
    es: " por unirse al Club!",
    pt: "para se juntar ao Clube!",
  },
  thankyou_text_1: {
    en: "You will receive an email from Amphora’s parent company Cargill on your registered email address to complete the Account activation.",
    fr: "Vous allez recevoir un email de la part de Cargill, société mère de la marque Amphora, à l’adresse email que vous avez indiqué afin d’activer votre compte.",
    es: "Recibirá un correo electrónico de la empresa matriz de Amphora, Cargill, en su dirección de correo electrónico registrada para completar la activación de la cuenta.",
    pt: "Para completar a ativação da conta receberá uma mensagem de correio eletrónico da Cargill, a empresa matriz da Amphora, no seu endereço de correio eletrónico registado.",
  },
  thankyou_text_2: {
    en: "If you did not receive the email, then contact us!",
    fr: "Si vous ne recevez pas l’email contactez nous !",
    es: "Si no recibió el correo electrónico, ¡contáctenos!",
    pt: "Se não receber a mensagem, experimente consultar a sua pasta de correio não desejado.",
  },

  lets_start: {
    en: "Continue",
    fr: "Commencer",
    es: "Continue",
    pt: "Continuar",
  },
};

export const login_steps = {
  step: {
    en: "Step",
    fr: "Étape",
    es: "Paso",
    pt: "Degraus",
  },
  create_account: {
    en: "Create your account",
    fr: "Créez votre compte",
    es: "Cree su cuenta",
    pt: "crie sua conta",
  },
  register_here: {
    en: "Register here",
    fr: "Enregistrez-vous ici",
    es: "Registrar aquí",
    pt: "Registre-se aqui",
  },
  send_invoice: {
    en: "Send your invoice",
    fr: "Envoyez vos factures",
    es: "Envíe su factura",
    pt: "Envie sua fatura",
  },
  via_website: {
    en: "via this website",
    fr: "via le site internet",
    es: "a través del sitio web",
    pt: "através do site",
  },
  via_email: {
    en: "via a photo by email",
    fr: "via une photo par email",
    es: "a través de una foto por correo electrónico",
    pt: "através de uma foto por e-mail",
  },
  via_post: {
    en: "via post",
    fr: "via courrier",
    es: "por correo",
    pt: "para correo",
  },
  crediting_text: {
    en: "WE TAKE CARE OF CREDITING POINTS",
    fr: "NOUS NOUS OCCUPONS DES POINTS À CRÉDITER",
    es: "NOS CUIDAMOS DE LOS PUNTOS DE CRÉDITO",
    pt: "NÓS CUIDAMOS DOS PONTOS DE CRÉDITO",
  },
  choose_gift: {
    en: "Choose your gift",
    fr: "Choisissez votre cadeau",
    es: "Elige tu regalo",
    pt: "escolha seu presente",
  },
  collect_points: {
    en: "Collect points and choose the gift of your choice from our wide selection.",
    fr: "Collectez des points et choisissez votre cadeau parmi notre large sélection.",
    es: "Acumule puntos y elija su regalo de nuestra gran selección.",
    pt: "Acumule pontos e escolha o seu presente da nossa grande seleção.",
  },
  delivery_text: {
    en: "WE TAKE CARE OF THE DELIVERY",
    fr: "NOUS PRENONS SOIN DE LA LIVRAISON",
    es: "NOS ENCARGAMOS DE LA ENTREGA",
    pt: "NÓS CUIDAMOS DA ENTREGA",
  },
  amphora_loyalty_1: {
    en: "The Amphora Chefs club is a loyalty program where you accumulate points that allow you to obtain gifts.",
    fr: "Le Club des Chefs Amphora est un programme de fidélité à l’attention de la restauration commerciale indépendante où vous cumulez des points qui vous permettent d'obtenir des cadeaux.",
    es: "El Amphora chefs Club es un programa de fidelización donde puedes acumular puntos que te permitirán obtener regalos.",
    pt: "O Amphora chefs Club é um programa de fidelização onde pode acumular pontos que lhe permitirão obter brindes.",
  },
  amphora_loyalty_2: {
    en: " you accumulate points that allow you to obtain gifts.",
    fr: " vous cumulez des points qui vous permettent d'obtenir des cadeaux.",
    es: " puedes acumular puntos que te permitirán obtener regalos.",
    pt: " você pode acumular pontos que permitirão que você ganhe presentes.",
  },
  amphora_loyalty_3: {
    en: " Loyalty is rewarded!",
    fr: " La fidélité se récompense!",
    es: " La fidelidad tiene premio",
    pt: " Lealdade tem um prêmio",
  },
  more_info: {
    en: "More Info",
    fr: "Plus d'informations",
    es: "Mas información",
    pt: "Mais informação",
  },
  member_login: {
    en: "Member Login",
    fr: "Se connecter",
    es: "Inicio de sesión",
    pt: "Conecte-Se",
  },
  join_club: {
    en: "Join the club",
    fr: "S’inscrire",
    es: "únete al club",
    pt: "Junte-se ao clube",
  },
  contact_us: {
    en: "Contact Us",
    fr: "Nous contacter",
    es: "Contáctenos",
    pt: "Contatos",
  },
  contact_text: {
    en: "Do you have a question? Leave us a message and your local Amphora sales representative will get back to you quickly.",
    fr: "Tiene una pregunta? Déjenos un mensaje y el delegado de ventas Amphora de su zona se comunicará con usted rápidamente.",
    es: "¿Tiene una pregunta? Déjenos un mensaje y el delegado de ventas Amphora de su zona se comunicará con usted rápidamente.",
    pt: "Você tem uma pergunta? Deixe-nos uma mensagem e o representante de vendas da Amphora em sua área entrará em contato com você rapidamente.",
  },
  zone: {
    en: "Zone",
    fr: "Indiquez votre région",
    es: "Indique su zona",
    pt: "Indique sua área",
  },
  establishment_name: {
    en: "Name of your establishment",
    fr: "Nom de votre établissement",
    es: "Nombre de su establecimiento",
    pt: "Nome do seu estabelecimento",
  },
  establishment_address: {
    en: "Establishment address",
    fr: "Address de établissement",
    es: "Dirección del establecimiento",
    pt: "Endereço do estabelecimento",
  },
  comments: {
    en: "Comments",
    fr: "Application",
    es: "Solicitud",
    pt: "Solicitar",
  },
  contact_success: {
    en: "Contact form submitted successfully",
    fr: "Formulaire de contact soumis avec succès",
    es: "Formulario de contacto enviado correctamente",
    pt: "Formulário de contato enviado com sucesso",
  },
  zone_name: {
    en: "Zone",
    fr: "Zone",
    es: "Zona",
    pt: "Zona",
  }
};
