import * as Yup from "yup";

import { Grid, InputLabel, Container, Button, MenuItem } from "@material-ui/core";
import { Checkbox, CheckboxWithLabel, TextField } from "formik-mui";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";

import Captcha from "./Captcha.component";
import Message from "components/common/Message.component";
import { Typography } from "components/common/Common.component";
import giftRegisterFranceImg from "assets/images/giftRegisterFrance.png";
import giftRegisterSpainImg from "assets/images/giftRegisterSpain.png";
import giftRegisterPortugalImg from "assets/images/giftRegisterPortugal.png";
import { useSelector } from "react-redux";
import { loginStyles } from "styles/login.styles";
import { RootState, useAppDispatch } from "state/store";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchCountries, registerUserActionCreator, userRegistration } from "state/actions/user/user.action";
import useMobileScreen from "hooks/useMobileScreen.hook";
import { RegistrationFormI } from "interface/login.model";
import { noSplCharForName } from "utils/common";
import { CountryCode } from "interface/user.model";
import { telephoneCodes } from "constants/telephoneCodes";
import { TFunction } from "i18next";

const REGISTRATION_FORM_INIT_STATE: RegistrationFormI = {
  firstName: "",
  lastName: "",
  restaurant: "",
  function: "",
  email: "",
  street: "",
  postalCode: "",
  city: "",
  phoneNumber: "",
  siretNumber: "",
  termsAndConditions: false,
  userPreferencesDTO: {
    isEmail: false,
    isMobile: false,
    isPhone: false,
  },
  referredByCode: undefined,
  countryCode: "",
};

const getRegistrationFormValidations = (
  t: TFunction,
  maxPhoneDigits: number,
  minSiretNumber: number,
  maxSiretNumber: number,
  maxPostalDigits: number
) =>
  Yup.object().shape({
    firstName: Yup.string().required(t("required_field")).matches(noSplCharForName, t("noSplChar")),
    lastName: Yup.string().required(t("required_field")).matches(noSplCharForName, t("noSplChar")),
    restaurant: Yup.string().required(t("required_field")),
    function: Yup.string().required(t("required_field")),
    email: Yup.string().required(t("required_field")).email(t("email_error")),
    countryCode: Yup.string().required(),
    siretNumber: Yup.string()
      .when('countryCode', {
        is: 'ESP',
        then: Yup.string()
          .matches(/^[a-z0-9A-Z]+$/, t("number_must")),
        otherwise: Yup.string()
          .matches(/^[0-9]+$/, t("number_must"))
      })
      .min(minSiretNumber, `${t("siret_number_error")} ${minSiretNumber} ${t("digits")}`)
      .max(maxSiretNumber, `${t("siret_number_error")} ${maxSiretNumber} ${t("digits")}`)
      .required(t("required_field")),
    street: Yup.string().required(t("required_field")),
    postalCode: Yup.string()
      .required()
      .matches(/^[0-9]+$/, t("number_must"))
      .min(maxPostalDigits, `${t("zipcode_error")} ${maxPostalDigits} ${t("digits")}`)
      .max(maxPostalDigits, `${t("zipcode_error")} ${maxPostalDigits} ${t("digits")}`)
      .required(t("required_field")),
    city: Yup.string().required(t("required_field")),
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, t("number_must"))
      .min(maxPhoneDigits, `${t("phone_number_error")} ${maxPhoneDigits} ${t("digits")}`)
      .max(maxPhoneDigits, `${t("phone_number_error")} ${maxPhoneDigits} ${t("digits")}`)
      .required(t("required_field")),
    termsAndConditions: Yup.bool(),
    userPreferencesDTO: Yup.object().shape({
      isEmail: Yup.bool(),
      isMobile: Yup.bool(),
      isPhone: Yup.bool(),
    }),
    referredByCode: Yup.string().min(8, t("referrence_error")).max(8, t("referrence_error")),
  });

const additional_attributes_for_registration = {
  oktaId: 0,
  enableOktaNotRequired: false,
  deviceId: "1234",
  deviceType: "deviceType",
};

function Registration(props: any) {
  const [snackOpen, setSnackOpen] = useState(false);
  const [captchaSnackOpen, setCaptchaSnackOpen] = useState(false);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [termsMsgDisplay, setTermsMsgDisplay] = useState(false);
  const [countryTelephoneCode, setCountryTelephoneCode] = useState("");
  const [maxPhoneDigits, setMaxPhoneDigits] = useState<9 | 10>(10);
  const [minSiretNumber, setMinSiretNumber] = useState<9 | 14>(14);
  const [maxSiretNumber, setMaxSiretNumber] = useState<9 | 14>(14);
  const [maxPostalDigits, setMaxPostaldigits] = useState<5 | 7>(5);
  const [welcomeKitImg, setWelcomeKitImg] = useState(giftRegisterFranceImg);

  const userRegistrationStatus = useSelector((state: RootState) => state.user.userRegistrationStatus);
  const userRegistrationErrorMsg = useSelector((state: RootState) => state.user.userRegistrationErrorMsg);
  const countries = useSelector((state: RootState) => state.user.countries);
  const countryCode = useSelector((state: RootState) => state.user.countryCode);

  const [t] = useTranslation();
  const classes = loginStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const formikRef = useRef<FormikProps<RegistrationFormI>>(null);
  const mobileScreen = useMobileScreen();

  const handleSubmit = (formData: RegistrationFormI, formikHelpers: FormikHelpers<RegistrationFormI>) => {
    const { termsAndConditions } = formData;
    if (!termsAndConditions) {
      setTermsMsgDisplay(true);
      // If setSubmitting is missed then the fields on Registration page will freeze onSubmit
      formikHelpers.setSubmitting(false);
    } else {
      if (!captchaSuccess) {
        setCaptchaSnackOpen(true);
        formikHelpers.setSubmitting(false);
      } else {
        delete formData.termsAndConditions;
        dispatch(
          userRegistration({
            ...formData,
            ...additional_attributes_for_registration,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (userRegistrationStatus === true) {
      history.push("/lets-start");
      dispatch(registerUserActionCreator(undefined));
    }
    if (userRegistrationStatus === false) {
      setSnackOpen(true);
      formikRef.current?.setSubmitting(false);
      dispatch(registerUserActionCreator(undefined));
    }
  }, [dispatch, history, userRegistrationStatus]);

  const handleCallback = (validity: any) => {
    setCaptchaSuccess(validity);
  };

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  useEffect(() => {
    if (countryCode === "FRA") setWelcomeKitImg(giftRegisterFranceImg);
    else if (countryCode === "ESP") setWelcomeKitImg(giftRegisterSpainImg);
    else if (countryCode === "PRT") setWelcomeKitImg(giftRegisterPortugalImg);
  }, [countryCode]);

  const handleCountryCode = (event: any) => {
    const { value }: { value: CountryCode } = event.target;
    setCountryTelephoneCode(telephoneCodes[value]);
    setMaxPhoneDigits(value === "FRA" ? 10 : 9);
    setMinSiretNumber(value === "FRA" ? 14 : 9)
    setMaxSiretNumber(14);
    setMaxPostaldigits(value === "PRT" ? 7 : 5);
  };

  return (
    <Container className={classes.registrationContainer}>
      {/* Title */}
      <Grid container justifyContent='flex-start' direction='column' spacing={2}>
        <Grid item className={classes.registrationTitleDiv}>
          {!mobileScreen && <img src={welcomeKitImg} alt='bigImg' className={classes.welcomeKit} height={500} />}
          <Typography variant='h4' className={classes.loyaltyText}>
            {t("loyalty_text_1")}
            {!mobileScreen && <br />} {t("loyalty_text_2")} <i> {t("loyalty_text_3")}</i>
          </Typography>
        </Grid>
        <Grid item style={{ marginBottom: "1rem" }}>
          <Typography className={classes.title}>{t("offer_availability")}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.title}>{t("option")} 1:</Typography>
          <Typography className={classes.content}>{t("option_1_text")}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.title}>{t("option")} 2:</Typography>
          <Typography className={classes.content}>{t("option_2_text")}</Typography>
        </Grid>
      </Grid>

      {/* Registration Form */}
      <Formik
        initialValues={{ ...REGISTRATION_FORM_INIT_STATE }}
        validationSchema={getRegistrationFormValidations(t, maxPhoneDigits, minSiretNumber, maxSiretNumber, maxPostalDigits)}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({ submitForm, isSubmitting, touched, errors, handleChange }) => (
          <Form className={classes.formDiv} style={{ marginTop: "2rem" }}>
            <Grid container spacing={3}>
              {/* Form Fields */}
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.content}>{t("first_name")} *</InputLabel>
                <Field component={TextField} name='firstName' type='text' fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.content}>{t("last_name")} *</InputLabel>
                <Field component={TextField} name='lastName' type='text' fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.content}>{t("restaurant_name")} *</InputLabel>
                <Field component={TextField} name='restaurant' type='text' fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.content}>{t("job_title")} *</InputLabel>
                <Field component={TextField} name='function' type='text' fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.content}>{t("email")} *</InputLabel>
                <Field component={TextField} name='email' type='email' fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className={classes.content}>{t("referral_code")}</InputLabel>
                <Field
                  component={TextField}
                  name='referredByCode'
                  inputProps={{ maxLength: 8 }}
                  type='text'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} className={classes.addressField}>
                <InputLabel className={classes.content}>{t("address")} *</InputLabel>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field component={TextField} name='street' type='text' label={`${t("street")}*`} fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field component={TextField} name='city' type='text' label={`${t("city")}*`} fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  component={TextField}
                  name='postalCode'
                  type='text'
                  label={`${t("zip_code")}*`}
                  inputProps={{ maxLength: maxPostalDigits }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={classes.content}>{t("country")} *</InputLabel>
                <Field
                  component={TextField}
                  select
                  name='countryCode'
                  fullWidth
                  onChange={(e: any) => {
                    handleChange(e);
                    handleCountryCode(e);
                  }}
                >
                  {countries.map((country, index) => (
                    <MenuItem key={country.countryId} value={country.countryCode}>
                      {country.displayName}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel className={classes.content}>{t("phone")} *</InputLabel>
                <>
                  <p className={classes.phoneCode}>{countryTelephoneCode}</p>
                  <Field
                    component={TextField}
                    name='phoneNumber'
                    inputProps={{ maxLength: maxPhoneDigits }}
                    type='text'
                    className={classes.phoneNumber}
                  />
                </>
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel className={classes.content}>{t("siret_number")} *</InputLabel>
                <Field
                  component={TextField}
                  name='siretNumber'
                  inputProps={{ maxLength: maxSiretNumber, minLength: minSiretNumber }}
                  type='text'
                  fullWidth
                />
              </Grid>

              {/* Checkbox Terms & Condition */}
              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center'>
                  <span>
                    <Field component={Checkbox} name='termsAndConditions' type='checkbox' />
                    <Typography className={classes.content} style={{ display: "inline" }}>
                      {t("check_box_t1")}
                      <a className={classes.termsLink} href='/web-terms' rel='noreferrer' target='_blank'>
                        {t("terms_conditions")}
                      </a>
                      {t("check_box_t2")}
                    </Typography>
                  </span>
                </Grid>
              </Grid>

              {/* Checkbox on Notification Consent */}
              <Grid item xs={12} className={classes.notificationContainer}>
                <Grid container alignItems='flex-start' direction='column'>
                  <Typography className={classes.content}>{t("notifcation_consent")} </Typography>
                  <Grid item>
                    <Field
                      component={CheckboxWithLabel}
                      name='userPreferencesDTO.isEmail'
                      type='checkbox'
                      Label={{ label: t("email_consent") }}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      component={CheckboxWithLabel}
                      name='userPreferencesDTO.isMobile'
                      type='checkbox'
                      Label={{ label: t("mobile_consent") }}
                    />
                  </Grid>
                  {/* <Grid item>
                    <Field
                      component={CheckboxWithLabel}
                      name='userPreferencesDTO.isPhone'
                      type='checkbox'
                      Label={{ label: t("calls_consent") }}
                    />
                  </Grid> */}
                </Grid>
              </Grid>

              {/* Captcha and Submit */}
              <Grid item xs={12}>
                <Grid container direction='column' alignItems='center'>
                  <Captcha parentCallback={handleCallback} />
                  <Button
                    color='primary'
                    variant='contained'
                    style={{ width: "200px", marginTop: "20px" }}
                    type='submit'
                  >
                    {t("register")}
                  </Button>
                </Grid>
              </Grid>

              {/* Privacy Policy Link */}
              <Grid item xs={12}>
                <Grid container direction='column' alignItems='center'>
                  <a
                    className={classes.termsLink}
                    href='https://www.cargill.com/page/privacy'
                    rel='noreferrer'
                    target='_blank'
                  >
                    {t("privacy_policy")}
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {/* Additional Components */}
      <Message
        openFl={snackOpen}
        closeMessageFn={() => setSnackOpen(false)}
        duration={6000}
        variant='error'
        textToDisplay={userRegistrationErrorMsg}
      />
      <Message
        openFl={captchaSnackOpen}
        closeMessageFn={() => setCaptchaSnackOpen(false)}
        duration={6000}
        variant='error'
        textToDisplay={t("enter_valid_captcha")}
      />
      <Message
        openFl={termsMsgDisplay}
        closeMessageFn={() => setTermsMsgDisplay(false)}
        duration={6000}
        variant='error'
        textToDisplay={t("accept_the_terms")}
      />
    </Container>
  );
}

export default Registration;
