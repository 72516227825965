import { Snackbar, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";

import ClearIcon from "@material-ui/icons/Clear";
import { Typography } from "./Common.component";
import errorImg from "assets/svg/Error.svg";
import successImg from "assets/svg/success.svg";

const MessageCSSClasses = makeStyles((theme) => ({
  container: {
    display: "flex",
    border: "2px solid #D57F00",
    backgroundColor: "white",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "10px",
  },
  imageContainer: {
    marginTop: "8px",
    paddingRight: "10px",
  },
  text: {
    fontSize: "15px",
    marginTop: "0.7rem",
  },
  closeIconContainer: {
    marginTop: "10px",
    paddingLeft: "10px",
  },
}));

type SUCCESS = "success";
type ERROR = "error";

interface MessageProps {
  openFl: boolean;
  closeMessageFn?: Function;
  textToDisplay: string;
  variant: SUCCESS | ERROR;
  duration?: number;
}

const Message = (props: MessageProps) => {
  const [imageSrc, setImgSrc] = useState("");
  const [altText, setAltText] = useState("");
  const [openMsg, setOpenMsg] = useState(false);

  const { openFl, textToDisplay, variant, closeMessageFn, duration = 4000 } = props;

  useEffect(() => {
    if (variant === "success") {
      setImgSrc(successImg);
      setAltText("success");
    } else {
      setImgSrc(errorImg);
      setAltText("error");
    }

    setOpenMsg(openFl);
  }, [openFl, variant]);

  const handleCloseMsg = () => {
    // closeMessageFn prop is for resetting the state
    // if this is missed then your state will remain in true state and Message component won't display again
    if (closeMessageFn) closeMessageFn();
    setOpenMsg(false);
  };

  const messageStyles = MessageCSSClasses();

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openMsg}
        autoHideDuration={duration}
        onClose={handleCloseMsg}
      >
        <div className={messageStyles.container}>
          <div className={messageStyles.imageContainer}>
            <img src={imageSrc} width={28} height={28} alt={altText} />
          </div>
          <Typography className={messageStyles.text}>{textToDisplay}</Typography>
          <div className={messageStyles.closeIconContainer}>
            <ClearIcon onClick={handleCloseMsg} />
          </div>
        </div>
      </Snackbar>
    </div>
  );
};

export default Message;
