import {
  MESSAGES,
  USER_PREFERENCES,
  UPDATE_USER_PREFERENCES,
  POINTS_HISTORY,
} from "state/actions/accounts/account.type";
import { MessageI, NotificationPreferencesI, PointsHistoryI } from "interface/account.model";

const MSG_INIT_STATE: MessageI = {
  messagesOpen: [],
  messagesClosed: [],
};

const NOTIFY_PREF_INIT_STATE: NotificationPreferencesI = {
  userPreferenceId: 1,
  isEmail: false,
  isMobile: false,
  isPhone: false,
  isError: false,
};

interface AccountState {
  messages: MessageI;
  notificationPreferences: NotificationPreferencesI;
  isUserPrefUpdateSuccess: boolean;
  pointsHistory: PointsHistoryI | undefined;
}

const INIT_STATE: AccountState = {
  messages: MSG_INIT_STATE,
  notificationPreferences: NOTIFY_PREF_INIT_STATE,
  isUserPrefUpdateSuccess: false,
  pointsHistory: undefined,
};

export const accountReducer = (state: AccountState = INIT_STATE, action: any): AccountState => {
  const { type, payload } = action;
  switch (type) {
    case MESSAGES:
      return { ...state, messages: payload };
    case USER_PREFERENCES:
      return { ...state, notificationPreferences: payload };
    case UPDATE_USER_PREFERENCES:
      return { ...state, isUserPrefUpdateSuccess: payload };
    case POINTS_HISTORY:
      return { ...state, pointsHistory: action.payload };
    default:
      return state;
  }
};
