import { HomeStateI } from "interface/home.model";
import {
  UPLOAD_SERVICE_REQUEST,
  OFFERS,
  homeActionType,
  SHOW_LOADER,
  HOME_MOUNTED,
  LOGO,
  PRODUCT_VOLUME_POINTS,
  LEGALDOCS,
  LOGO_CONTENT
} from "state/actions/home/home.type";

const INITIAL_STATE: HomeStateI = {
  serviceReqStatus: undefined,
  offerObj: [],
  showLoader: false,
  isHomeMounted: false,
  logoImgSrc: "",
  logoContent: "",
  legalDocsObj: {
    webTermPdfSrc: "",
    loyaltyTermPdfSrc: "",
  },
  productsVolumeAndPoints: {
    productVolumeBonus: [],
    productVolumes: [],
  },
};

export const homeReducer = (state: HomeStateI = INITIAL_STATE, action: homeActionType): HomeStateI => {
  switch (action.type) {
    case UPLOAD_SERVICE_REQUEST:
      return { ...state, serviceReqStatus: action.payload };
    case OFFERS: {
      return { ...state, offerObj: action.payload };
    }
    case SHOW_LOADER:
      return { ...state, showLoader: action.payload };
    case HOME_MOUNTED:
      return { ...state, isHomeMounted: action.payload };
    case LOGO:
      return { ...state, logoImgSrc: action.payload };
    case LOGO_CONTENT:
      return { ...state, logoContent: action.payload };
    case LEGALDOCS:
      return { ...state, legalDocsObj: action.payload };
    case PRODUCT_VOLUME_POINTS:
      return { ...state, productsVolumeAndPoints: action.payload };
    default:
      return state;
  }
};
