import { AppDispatch, AppThunk } from "state/store";
import {
  BASKET_ITEMS,
  BASKET_ITEM_IMG_SRC,
  UPDATE_ARTICLE_IN_BASKET,
  REMOVE_ARTICLE_FROM_BASKET,
  UPDATE_POINTS_IN_BASKET,
  UPDATE_BASKET_ADDRESS_ID,
  QUANTITY_ERROR,
  BasketActionType,
  LOADER_FOR_GIFTS,
} from "./basket.type";
import {
  ArticleItemImgI,
  QuantityErrorI,
  AddArticleToBasketI,
  UpdateArticleInBasketI,
  Basket,
  ProductLoaderI,
} from "interface/basket.model";
import { axiosInstance } from "utils/axios";
import getApiParameters from "utils/apiParameters";

export const basketItemsActionCreator = (items: Basket): BasketActionType => {
  return {
    type: BASKET_ITEMS,
    payload: items,
  };
};

export const basketItemImgSrcActionCreator = (data: ArticleItemImgI): BasketActionType => {
  return {
    type: BASKET_ITEM_IMG_SRC,
    payload: data,
  };
};

export const updateArticleInBasketActionCreator = (item: UpdateArticleInBasketI): BasketActionType => {
  return {
    type: UPDATE_ARTICLE_IN_BASKET,
    payload: item,
  };
};

export const removeArticleFromBasketActionCreator = (basketId: number): BasketActionType => {
  return {
    type: REMOVE_ARTICLE_FROM_BASKET,
    payload: basketId,
  };
};

export const UpdateBasketPointsActionCreator = (points: number): BasketActionType => {
  return {
    type: UPDATE_POINTS_IN_BASKET,
    payload: points,
  };
};

export const updateBasketAddressIdActionCreator = (addressId: number): BasketActionType => {
  return {
    type: UPDATE_BASKET_ADDRESS_ID,
    payload: addressId,
  };
};

export const QuantityErrActionCreator = (err: QuantityErrorI): BasketActionType => {
  return {
    type: QUANTITY_ERROR,
    payload: err,
  };
};

export const GiftLoaderActionCreator = (data: ProductLoaderI): BasketActionType => {
  return {
    type: LOADER_FOR_GIFTS,
    payload: data,
  };
};

// cart actions
export const getCartItems = (): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    const pathParameter = "getAllBasketProductByLocaleAndUserId";
    const params = getApiParameters(getState);
    axiosInstance
      .get(pathParameter, {
        params: params,
      })
      .then((resp) => {
        const cartItems: Basket = resp.data;
        const cartPoints: number = cartItems.map((item) => item.points).reduce((a, b) => a + b, 0);
        dispatch(UpdateBasketPointsActionCreator(cartPoints));
        dispatch(updateBasketAddressIdActionCreator(getState().user.user.userAddresses[0]?.userAddressId || -1));

        for (var i = 0; i < cartItems.length; i++) {
          let cartItem = cartItems[i];
          cartItem.type = "cart";
          const fileName = cartItem.image1;
          cartItem.quantity = Number(cartItem.quantity);

          const giftPro = getState().products.products.find((product) => product.productId === cartItem.productId);
          const src = giftPro?.image1src;

          if (fileName !== "" && (src === undefined || src === "")) {
            const pathParameter = "downloadImage/" + fileName;
            axiosInstance
              .get(pathParameter, {
                params: { type: "gift", countryCode: getState().user.user.countryCode },
              })
              .then((resp) => {
                const ext = fileName.split(".")[1];
                const src = `data:image/${ext};base64,${resp.data}`;
                dispatch(basketItemImgSrcActionCreator({ basketId: cartItem.basketId, src: src }));
              });
          } else if (giftPro !== undefined) {
            cartItem.image1src = giftPro.image1src;
          }
        }
        dispatch(basketItemsActionCreator(cartItems));
      })
      .catch((error) => {
        console.error("Failure while fetching cart items", error);
      });
  };
};

export const updateCart = (updateParam: UpdateArticleInBasketI): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    dispatch(QuantityErrActionCreator({ msg: "", flag: true }));
    const pathParameter = "updateBasket";
    axiosInstance
      .put(pathParameter, updateParam)
      .then((resp) => {
        dispatch(updateArticleInBasketActionCreator(updateParam));
        dispatch(
          UpdateBasketPointsActionCreator(
            getState()
              .cart.cartItems.map((item) => item.points)
              .reduce((a, b) => a + b, 0)
          )
        );
        dispatch(QuantityErrActionCreator({ msg: "item_update", flag: false }));
      })
      .catch((err) => {
        dispatch(QuantityErrActionCreator({ msg: err.response.data, flag: false }));
      });
  };
};

export const addToCart = (item: AddArticleToBasketI, points: string): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    const cartItems = getState().cart.cartItems;
    var cartInd: number = -1;
    for (var i = 0; i < cartItems.length; i++) {
      const cart = cartItems[i];
      if (cart.productId === item.giftProductId) {
        cartInd = i;
        break;
      }
    }
    dispatch(QuantityErrActionCreator({ msg: "", flag: true }));

    if (cartInd === -1) {
      const pathParameter = "addProductToBasket";
      if (item.points) {
        delete item.points;
      }
      axiosInstance
        .post(pathParameter, item)
        .then((resp) => {
          dispatch(getCartItems());
          dispatch(QuantityErrActionCreator({ msg: "item_success", flag: false }));
        })
        .catch((err) => {
          dispatch(GiftLoaderActionCreator({ productId: item.giftProductId, loader: false }));
        });
    } else {
      const updateCartdata: UpdateArticleInBasketI = {
        basketId: cartItems[cartInd].basketId.toString(),
        quantity: Number(cartItems[cartInd].quantity) + 1,
        points: cartItems[cartInd].points + parseInt(points),
        giftProductId: cartItems[cartInd].productId,
        giftProductReferenceNumber: cartItems[cartInd].referenceNumber,
      };
      dispatch(updateCart(updateCartdata));
      dispatch(GiftLoaderActionCreator({ productId: item.giftProductId, loader: false }));
    }
  };
};

export const removeFromCart = (basketId: number): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    dispatch(QuantityErrActionCreator({ msg: "", flag: true }));
    const pathParameter = "deleteBasketProduct";
    axiosInstance
      .delete(pathParameter, {
        params: { basketId: basketId },
      })
      .then((resp) => {
        dispatch(removeArticleFromBasketActionCreator(basketId));
        dispatch(
          UpdateBasketPointsActionCreator(
            getState()
              .cart.cartItems.map((item) => item.points)
              .reduce((a, b) => a + b, 0)
          )
        );
        dispatch(QuantityErrActionCreator({ msg: "item_delete", flag: false }));
      })
      .catch((err) => {
        dispatch(QuantityErrActionCreator({ msg: err.response.data, flag: false }));
      });
  };
};
