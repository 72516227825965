import {
  languages,
  UserI,
  AddressFormI,
  EditUserI,
  CountryI,
  ZoneI,
  ContactUsI,
  CountryCode,
} from "interface/user.model";

// constants
export const REGISTER_USER = "register_user";
export const REGISTER_USER_ERROR_MESSAGE = "register_user_error_msg";
export const TOKEN_EXPIRED = "token_expired";

export const SET_OKTAID = "set_oktaid";
export const SET_TOKEN = "set_token";
export const SET_LANG = "set_lang";
export const SET_USER = "set_user";
export const UPDATE_USER = "update_user";
export const UPDATE_USER_POINTS = "update_user_points";

export const ADD_ADDRESS = "add_address";
export const UPDATE_ADDRESS = "update_address";
export const DELETE_ADDRESS = "delete_address";

export const SHOW_MOBILE_QR_CODE_DIALOG = "show_mobile_qr_code_dialog";

export const COUNTRIES = "countries";
export const ZONE = "zone";
export const CONTACT_US = "contact_us";
export const COUNTRY_CODE = "country_code";

// Action-Type
interface RegisterUserActionType {
  type: typeof REGISTER_USER;
  payload: boolean | undefined;
}

interface RegisterUserErrorActionType {
  type: typeof REGISTER_USER_ERROR_MESSAGE;
  payload: string;
}

interface SetOktaIdActionType {
  type: typeof SET_OKTAID;
  payload: string;
}

interface SetOktaTokenActionType {
  type: typeof SET_TOKEN;
  payload: string;
}

interface SetLangActionType {
  type: typeof SET_LANG;
  payload: languages;
}

interface SetUserActionType {
  type: typeof SET_USER;
  payload: UserI;
}

interface EditUserActionType {
  type: typeof UPDATE_USER;
  payload: EditUserI;
}

interface UpdateUserPointsActionType {
  type: typeof UPDATE_USER_POINTS;
  payload: number;
}

interface AddAddressActionType {
  type: typeof ADD_ADDRESS;
  payload: AddressFormI;
}

interface UpdateAddressActionType {
  type: typeof UPDATE_ADDRESS;
  payload: AddressFormI;
}

interface DeleteAddressActionType {
  type: typeof DELETE_ADDRESS;
  payload: number;
}

interface MobileQRActionType {
  type: typeof SHOW_MOBILE_QR_CODE_DIALOG;
  payload: boolean;
}

interface CountryActionType {
  type: typeof COUNTRIES;
  payload: CountryI[];
}

interface TokenExpiredType {
  type: typeof TOKEN_EXPIRED;
  payload: boolean;
}

interface ZoneActionType {
  type: typeof ZONE;
  payload: ZoneI;
}

interface ContactUsActionType {
  type: typeof CONTACT_US;
  payload: ContactUsI;
}

interface CountryCodeActionType {
  type: typeof COUNTRY_CODE;
  payload: CountryCode;
}

// combining ActionTypes
export type UserRegisterActionType = RegisterUserActionType | RegisterUserErrorActionType | CountryActionType;

export type UserActionType =
  | SetLangActionType
  | SetOktaIdActionType
  | SetOktaIdActionType
  | SetOktaTokenActionType
  | SetUserActionType
  | EditUserActionType
  | UpdateUserPointsActionType
  | MobileQRActionType
  | TokenExpiredType
  | CountryCodeActionType;

export type addressActionType = AddAddressActionType | UpdateAddressActionType | DeleteAddressActionType;

export type ContactUs = ZoneActionType | ContactUsActionType;

export type CustomerActionType = UserRegisterActionType | UserActionType | addressActionType | ContactUs;
