export const account_screen = {
  referral_code: {
    en: "Referral Code",
    fr: "Code de Parrainage",
    es: "Código de referencia",
    pt: "Código de Referencia",
  },
  my_profile: {
    en: "My Profile",
    fr: "Mon Profil",
    es: "Perfil",
    pt: "Perfil",
  },
  my_addresses: {
    en: "My Addresses",
    fr: "Mes Adresses",
    es: "Direcciones",
    pt: "Endereços",
  },
  my_messages: {
    en: "My Messages",
    fr: "Mes Messages",
    es: "Mensajes",
    pt: "Mensagens",
  },
  my_distributors: {
    en: "Distributors",
    fr: "Distributeur ",
    es: "Distribuidores",
    pt: "Distribuidores",
  },
  address_list: {
    en: "Address List",
    fr: "Liste d'adresses",
    es: "Lista de direcciones",
    pt: "Lista de endereços",
  },
  my_preferences: {
    en: "My Preferences",
    fr: "Mes préférences",
    es: "Mis preferencias",
    pt: "As minhas preferências",
  },
  email_notification: {
    en: "Send me New Offers & Promotions Emails",
    fr: "Me tenir informé des dernières offres et promotions par mail",
    es: "Envíeme correos electrónicos de nuevas ofertas y promociones. Mantenerme informado de las últimas ofertas y promociones por correo electrónico.",
    pt: "Desejo receber correios eletrónicos de novas ofertas e promoções. Desejo ser informado sobre as últimas ofertas e promoções por correio eletrónico.",
  },
  mobile_notification: {
    en: "Send me New Offers & Promotions Mobile notifications",
    fr: "Me tenir informé des dernières offres et promotions via les notifications mobiles",
    es: "Enviarme nuevas ofertas y promociones.",
    pt: "Desejo receber notificações da aplicação para telemóvel sobre novas ofertas e promoções ",
  },
  call_notification: {
    en: "Call me to update on New Offers & Promotions",
    fr: "M’appeler pour me tenir informé des dernières offres et promotions",
    es: "Llámame para estar al día sobre nuevas ofertas y promociones.",
    pt: "Desejo receber informação por telefone sobre novas ofertas e promoções",
  },
  preferences_note: {
    en: "Note - Email and Mobile app notifications for Order and Invoices are sent by default",
    fr: "A noter – les notifications concernant les commandes et/ou les factures sont automatiquement envoyées par mail ou sur l’application. ",
    es: "Nota: las notificaciones por correo electrónico y aplicaciones móviles para pedidos y facturas se envían por defecto",
    pt: "Nota: As notificações por correio eletrónico e pela aplicação para telemóvel sobre encomendas e faturas são enviadas de modo predefinido.",
  },
  preferences_load_error: {
    en: "Unable to load the preferences, kindly close the panel and try again",
    fr: "Impossible de charger les données, veuillez fermer la page et réessayer",
    es: "No se pueden cargar las preferencias, por favor cierre el panel e inténtelo nuevamente",
    pt: "As preferências não puderam ser carregadas. Feche o painel e volte a tentar.",
  },
  preferences_update_success: {
    en: "Notification Preferences Updated Successfully",
    fr: "les données ont été mises à jour avec succès",
    es: "Las preferencias de notificación del usuario se actualizaron correctamente",
    pt: "As preferências de notificação do utilizador foram corretamente atualizadas",
  },
  address_submit_success: {
    en: "Address Submitted Successfully",
    fr: "Adresse enregistrée avec succès",
    es: "Dirección enviada con éxito.",
    pt: "Endereço corretamente enviado",
  },
  address_submit_fail: {
    en: "Address Submission Failed",
    fr: "L’enregistrement de cette adresse a échoué",
    es: "Error al enviar la dirección.",
    pt: "Erro ao enviar o endereço",
  },
  address_edit_success: {
    en: "Address Updated Successfully",
    fr: "Adresse mise à jour avec succès",
    es: "Dirección actualizada correctamente",
    pt: "Endereço corretamente atualizado ",
  },
  address_edit_fail: {
    en: "Address Updation Failed",
    fr: "La mise à jour de cette adresse a échoué ",
    es: "Error al actualizar la dirección",
    pt: "Falha na atualização de endereço",
  },
  delete_text: {
    en: "Are you sure to delete this address?",
    fr: "Êtes-vous sûr de vouloir supprimer cette adresse?",
    es: "¿Está seguro de que desea eliminar esta dirección?",
    pt: "Tem a certeza de que deseja eliminar este endereço?",
  },
  delete_button: {
    en: "Delete",
    fr: "Effacer",
    es: "Borrar",
    pt: "Eliminar",
  },
  add_address: {
    en: "Add Address",
    fr: "Ajouter une adresse",
    es: "Agregar nueva dirección",
    pt: "Agregar novo endereço",
  },
  edit_address: {
    en: "Edit Address",
    fr: "Modifier l'adresse",
    es: "Editar dirección",
    pt: "Editar Endereço",
  },
  back: {
    en: "Back",
    fr: "Revenir en arrière",
    es: "espalda",
    pt: "costas",
  },
  no_messages_to_show: {
    en: "No messages to show",
    fr: "Aucun message",
    es: "Sin mensaje",
    pt: "Nenhuma mensagem",
  },

  open: {
    fr: "ouvert",
    en: "Open",
    es: "Abierto",
    pt: "Aberto",
  },
  closed: {
    fr: "fermé",
    en: "Closed",
    es: "Cerrado",
    pt: "Fechado",
  },
  points_history: {
    en: "Points History",
    fr: "Historique des points",
    es: "Historial de puntos",
    pt: "Histórico de pontos",
  },
  points_utilized: {
    en: "Points Utilized",
    fr: "Points utilisés",
    es: "Puntos utilizados",
    pt: "Pontos utilizados",
  },
  points_available: {
    en: "Points Available",
    fr: "Points disponibles",
    es: "Puntos disponibles",
    pt: "pontos disponíveis",
  },
};
