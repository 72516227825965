import { lazy } from "react";

const Account = lazy(() => import("views/Accounts.page"));
const Home = lazy(() => import("views/Home.page"));
const Basket = lazy(() => import("views/Basket.page"));
const Invoice = lazy(() => import("views/Invoice.page"));
const ProductScreen = lazy(() => import("views/GiftScreen.page"));
const OfferScreen = lazy(() => import("components/home/OfferScreen.component"));
const Orders = lazy(() => import("views/Orders.page"));

export const navbarPages = [
  { name: "home", pageLink: "/home", view: Home },
  { name: "my_orders", pageLink: "/orders", view: Orders },
  { name: "invoices", pageLink: "/invoices", view: Invoice },
  { name: "my_account", pageLink: "/account", view: Account },
];

export const additionalPages = [
  { pageLink: "/basket", view: Basket },
  { pageLink: "/product/:reference", view: ProductScreen },
  { pageLink: "/offer", view: OfferScreen },
];
