import { GIFTS, GIFTS_IMG_SRC, GIFTS_IND, GiftsActionType } from "../actions/gift/gift.type";

import { CategoryI, GiftItemI, GiftsState } from "interface/gift.model";
import { BasketActionType, LOADER_FOR_GIFTS } from "state/actions/basket/basket.type";

const intialState: GiftsState = {
  categories: [],
  products: [],
  selectedProdInd: 0,
};

export const giftReducer = (state: GiftsState = intialState, action: GiftsActionType | BasketActionType): GiftsState => {
  switch (action.type) {
    case GIFTS: {
      const categories = action.payload.categories;
      const products = action.payload.products;
      products.forEach((product:GiftItemI) => {
        const catId = product.category.categoryId;
        categories.forEach((category:CategoryI) => {
          if (category.categoryId === catId) {
            category.count++;
          }
        });
      });
      return {
        ...state,
        categories: categories,
        products: products,
      };
    }
    case GIFTS_IMG_SRC: {
      const reference = action.payload.reference;
      const image_ind = action.payload.imageInd;
      const image_src = action.payload.src;

      const ctrl_name = "image" + image_ind.toString() + "src";
      return {
        ...state,
        products: state.products.map((prod, index) => {
          if (prod.referenceNumber === reference) {
            const new_prod = {
              ...prod,
              [ctrl_name]: image_src,
            };
            return new_prod;
          }
          return prod;
        }),
      };
    }
    case GIFTS_IND: {
      const ind = action.payload;

      return {
        ...state,
        selectedProdInd: ind,
      };
    }
    case LOADER_FOR_GIFTS: {
      const productId: number = action.payload.productId;
      const loader: boolean = action.payload.loader;
      return {
        ...state,
        products: state.products.map((item, index) => {
          if (item.productId === productId) {
            return { ...item, loader: loader };
          }
          return item;
        }),
      };
    }
    default:
      return state;
  }
};
