import { home } from "./home.translations";
import { footer } from "./footer.translations";
import { common } from "./common.translations";
import { basket } from "./basket.translations";
import { registration, thankyouCard, login_steps } from "./login.translations";
import { invoice_screen } from "./invoice.translations";
import { account_screen } from "./account.translations";
import { orders } from "./order.translations";

const lang = [
  home,
  registration,
  thankyouCard,
  login_steps,
  basket,
  common,
  invoice_screen,
  account_screen,
  footer,
  orders,
];

const translationObject = Object.assign(home, ...lang);
export const getTranslation = (mode: string) => {
  let trans: { [key: string]: string } = {};
  for (let key in translationObject) {
    trans[key] = translationObject[key][mode];
  }
  return trans;
};
