import { OrderDetailsViewI, OrderI, OrderImg, PlacedOrderResponseI } from "interface/order.model";

export const ORDERS = "orders";
export const ORDER_IMGSRC = "order_imgsrc";
export const PLACED_ORDER = "placed_order";
export const PLACED_ORDER_IMGSRC = "placed_order_imgsrc";
export const ORDER_COMPONENT_MOUNTED = "order_component_mounted";
export const ORDER_DETAILS_VIEW = "order_details_view";

interface ordersActionType {
  type: typeof ORDERS;
  payload: OrderI[];
}

interface orderImgSrcActionType {
  type: typeof ORDER_IMGSRC;
  payload: OrderImg;
}

interface placedOrderActionType {
  type: typeof PLACED_ORDER;
  payload: PlacedOrderResponseI;
}

interface placedOrderImgSrcActionType {
  type: typeof PLACED_ORDER_IMGSRC;
  payload: OrderImg;
}

interface isOrderMountedActionType {
  type: typeof ORDER_COMPONENT_MOUNTED;
  payload: boolean;
}

interface orderDetailsViewActionType {
  type: typeof ORDER_DETAILS_VIEW;
  payload: OrderDetailsViewI | undefined;
}

export type orderActionType =
  | ordersActionType
  | orderImgSrcActionType
  | placedOrderActionType
  | placedOrderImgSrcActionType
  | isOrderMountedActionType
  | orderDetailsViewActionType;
