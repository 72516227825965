import { Suspense, useEffect } from "react";
import { Box } from "@material-ui/core";
import { NavBar } from "components/navbar/NavBar.component";
import { additionalPages, navbarPages } from "routes";
import { Route } from "react-router-dom";
import Footer from "components/common/Footer.component";

import { CookieBanner } from "components/common/Cookie.component";
import { getOktaId } from "config";
import { RootState, useAppDispatch } from "state/store";
import { getUser, showMobileQrDialog } from "state/actions/user/user.action";
import MobileQRCodeDialog from "components/login/MobileQRDialog.component";
import CircularSpinner from "components/common/CircularSpinner.component";
import { useStyles } from "styles/home.styles";
import useLogout from "hooks/useLogout.hook";
import { useSelector } from "react-redux";
import useWideScreen from "hooks/useWideScreen.hook";

export function Landing() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const logout = useLogout();
  const isTokenExpired = useSelector((state: RootState) => state.user.isTokenExpired);
  const isWideScreen = useWideScreen();

  useEffect(() => {
    if (getOktaId() !== undefined) {
      dispatch(getUser());
      if (!sessionStorage.getItem("isMobileQRDialogDisplayed")) {
        dispatch(showMobileQrDialog(true));
        sessionStorage.setItem("isMobileQRDialogDisplayed", "true");
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (isTokenExpired) logout();
  }, [isTokenExpired, logout]);

  return (
    <Box>
      <NavBar />
      <Suspense fallback={<CircularSpinner />}>
        <Box className={classes.outerContainer}>
          <CookieBanner />
          <Box className={classes.landingBox}>
            {navbarPages.map((page, index) => (
              <Box key={index}>
                <Route key={index} path={page.pageLink} component={page.view} />
              </Box>
            ))}
            {additionalPages.map((page, index) => (
              <Route key={index} exact path={page.pageLink} component={page.view} />
            ))}
          </Box>
        </Box>
        <Footer />
      </Suspense>
      {isWideScreen && <MobileQRCodeDialog />}
      <CircularSpinner />
    </Box>
  );
}
