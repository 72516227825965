import React, { useEffect, useState, useRef, useCallback } from "react";
import { loginStyles } from "styles/login.styles";

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

const WIDTH = 200;
const HEIGHT = 50;

function Captcha(props: any) {
  const [solution, setSolution] = useState(getRandomInt(111111, 999999));
  const [input, setInput] = useState("");
  const canvas = useRef() as React.MutableRefObject<HTMLCanvasElement>;

  const refresh = () => {
    setSolution(getRandomInt(111111, 999999));
    setInput("");
    props.parentCallback(false);
  };

  const drawCaptcha = useCallback(() => {
    const canvasEle = canvas.current;
    canvasEle.width = canvasEle.clientWidth;
    canvasEle.height = canvasEle.clientHeight;
    const ctx = canvasEle.getContext("2d");
    if (ctx) {
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      ctx.font = "40px serif";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(solution.toString(), WIDTH / 2, HEIGHT / 2 + 3);
      ctx.strokeStyle = "#000000";
      ctx.beginPath();
      ctx.moveTo(getRandomInt(5, 20), getRandomInt(5, 20));
      ctx.lineTo(WIDTH - getRandomInt(5, 20), HEIGHT - getRandomInt(5, 20));
      ctx.stroke();
      ctx.moveTo(getRandomInt(5, 20), HEIGHT - getRandomInt(5, 20));
      ctx.lineTo(WIDTH - getRandomInt(5, 20), getRandomInt(5, 20));
      ctx.stroke();
      ctx.closePath();
    }
  }, [solution]);

  useEffect(() => {
    drawCaptcha();
  }, [drawCaptcha]);

  const handleChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setInput(target.value);
    if (solution === parseInt(target.value)) {
      props.parentCallback(true);
    } else {
      props.parentCallback(false);
    }
  };
  const classes = loginStyles();
  return (
    <div className={classes.rnc}>
      <div className={classes.rncRow}>
        <canvas ref={canvas} width={WIDTH} height={HEIGHT} className={classes.rncCanvas} data-testid='captcha-canvas' />
        <div className={classes.rncColumn}>
          <button
            type='button'
            aria-label='get new captcha'
            onClick={refresh}
            className={classes.rncButton}
            data-testid='captcha-refresh'
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
              <g data-name='Layer 2'>
                <g data-name='refresh'>
                  <rect width='24' height='24' opacity='0' />
                  <path d='M20.3 13.43a1 1 0 0 0-1.25.65A7.14 7.14 0 0 1 12.18 19 7.1 7.1 0 0 1 5 12a7.1 7.1 0 0 1 7.18-7 7.26 7.26 0 0 1 4.65 1.67l-2.17-.36a1 1 0 0 0-1.15.83 1 1 0 0 0 .83 1.15l4.24.7h.17a1 1 0 0 0 .34-.06.33.33 0 0 0 .1-.06.78.78 0 0 0 .2-.11l.09-.11c0-.05.09-.09.13-.15s0-.1.05-.14a1.34 1.34 0 0 0 .07-.18l.75-4a1 1 0 0 0-2-.38l-.27 1.45A9.21 9.21 0 0 0 12.18 3 9.1 9.1 0 0 0 3 12a9.1 9.1 0 0 0 9.18 9A9.12 9.12 0 0 0 21 14.68a1 1 0 0 0-.7-1.25z' />
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
      <input type='number' value={input} onChange={handleChange} placeholder={"Enter Captcha"} className={classes.rncInput} />
    </div>
  );
}

export default Captcha;
