import { Backdrop, CircularProgress } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "state/store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: 1450,
      color: "#fff",
      opacity: "0.1",
    },
  })
);

interface Props {
  openFlag?: boolean;
}

const CircularSpinner = (props: Props) => {
  const classes = useStyles();
  const showLoader = useSelector((state: RootState) => state.home.showLoader);
  const open = showLoader || props.openFlag || false;
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color='primary' />
    </Backdrop>
  );
};

export default CircularSpinner;
