import * as Yup from "yup";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "state/store";
import { ContactUsI } from "interface/user.model";
import { TFunction } from "i18next";
import { noSplCharForName } from "utils/common";
import { Field, Form, Formik } from "formik";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
  TextareaAutosize,
} from "@material-ui/core";
import Message from "components/common/Message.component";
import { Fragment, useEffect, useState } from "react";
import { DialogInterfaceI } from "interface/invoice.model";
import CancelIcon from "@material-ui/icons/Cancel";
import { TextField } from "formik-mui";
import { useTranslation } from "react-i18next";
import useMobileScreen from "hooks/useMobileScreen.hook";
import { submitContactForm } from "state/actions/user/user.action";
import { loginStyles } from "styles/login.styles";
import { ReactComponent as ContactIcon } from "assets/svg/contactUsIcon.svg";

const countryCode = sessionStorage.getItem("countryCode");

const contactUsInitState: ContactUsI = {
  requestedComment: "",
  unRegisteredUserDTO: {
    name: "",
    email: "",
    phoneNumber: "",
    postalCode: "",
    addressDetails: "",
    zone: "",
    city: "",
    countryCode: countryCode !== null ? countryCode : "",
    restaurantName: "",
  },
};

const getContactUsFormValidationSchema = (t: TFunction, maxPhoneDigits: number, maxPostalDigits: number) => {
  return Yup.object().shape({
    requestedComment: Yup.string().required(t("required_field")),
    unRegisteredUserDTO: Yup.object().shape({
      name: Yup.string().required(t("required_field")).matches(noSplCharForName, t("noSplChar")),
      email: Yup.string().required(t("required_field")).email(t("email_error")),
      city: Yup.string().required(t("required_field")),
      postalCode: Yup.string()
        .required()
        .matches(/^[0-9]+$/, t("number_must"))
        .min(maxPostalDigits, `${t("zipcode_error")} ${maxPostalDigits} ${t("digits")}`)
        .max(maxPostalDigits, `${t("zipcode_error")} ${maxPostalDigits} ${t("digits")}`)
        .required(t("required_field")),
      phoneNumber: Yup.string()
        .matches(/^[0-9]+$/, t("number_must"))
        .min(maxPhoneDigits, `${t("phone_number_error")} ${maxPhoneDigits} ${t("digits")}`)
        .max(maxPhoneDigits, `${t("phone_number_error")} ${maxPhoneDigits} ${t("digits")}`)
        .required(t("required_field")),
      zone: Yup.string().required(t("required_field")),
      restaurantName: Yup.string().required(t("required_field")),
      addressDetails: Yup.string().required(t("required_field")),
    }),
  });
};

const ContactUs = (props: DialogInterfaceI) => {
  const [msgVariant, setMsgVariant] = useState<"success" | "error">("success");
  const [showMsg, setShowMsg] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [maxPostalDigits, setMaxPostaldigits] = useState<5 | 7>(5);
  const [maxPhoneDigits, setMaxPhoneDigits] = useState<9 | 10>(10);

  const zone = useSelector((state: RootState) => state.user.zone);
  const countryCode = useSelector((state: RootState) => state.user.countryCode);
  console.log("🚀 ~ file: ContactUs.component.tsx ~ line 81 ~ ContactUs ~ countryCode", countryCode);
  const [t] = useTranslation();
  const isMobileScreen = useMobileScreen();
  const dispatch = useAppDispatch();
  const classes = loginStyles();

  const handleClose = () => {
    closeFn();
  };

  const handleSubmitFn = (contactUs: ContactUsI) => {
    dispatch(submitContactForm(contactUs))
      .then((resp) => {
        if (resp === "Success") {
          setShowMsg(true);
          setMsgVariant("success");
          setMsgText(t("contact_success"));
          handleClose();
        }
      })
      .catch((error) => {
        setShowMsg(true);
        setMsgVariant("error");
        setMsgText(error);
        handleClose();
      });
  };

  useEffect(() => {
    setMaxPhoneDigits(countryCode === "FRA" ? 10 : 9);
    setMaxPostaldigits(countryCode === "PRT" ? 7 : 5);
  }, [countryCode]);

  const { openFl, closeFn } = props;
  const contactUsFormValidation = getContactUsFormValidationSchema(t, maxPhoneDigits, maxPostalDigits);
  return (
    <Fragment>
      <Dialog open={openFl} onClose={handleClose} classes={{ paper: classes.contactDialogPaper }} maxWidth='lg'>
        <Formik
          initialValues={{ ...contactUsInitState }}
          validationSchema={contactUsFormValidation}
          onSubmit={handleSubmitFn}
        >
          {({ submitForm, isSubmitting, touched, errors, values }) => {
            return (
              <Form>
                <DialogTitle>
                  <Grid container alignItems='center'>
                    <ContactIcon className={classes.contactUsIcon} />
                    <Typography variant='h3' className={classes.contactUsTitle}>
                      {t("contact_us")}
                    </Typography>
                  </Grid>
                  <CancelIcon
                    color='primary'
                    fontSize={isMobileScreen ? "medium" : "large"}
                    onClick={handleClose}
                    className={classes.cancelIcon}
                  />
                </DialogTitle>
                <DialogContent classes={{ root: classes.contactDialogRoot }}>
                  <Grid container direction='row-reverse'>
                    <Grid item xs={12} sm style={{ overflow: "hidden", padding: "2%" }}>
                      <img src={zone?.imagePresignedURL} alt='Country Map' className={classes.mapImg} />
                    </Grid>
                    <Grid item className={classes.fieldsContainer} sm>
                      <Grid container>
                        <Grid item xs={12} className={classes.contactText}>
                          <Typography>{t("contact_text")}</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                          <Field
                            component={TextField}
                            name='unRegisteredUserDTO.name'
                            type='text'
                            size='small'
                            variant='standard'
                            fullWidth
                          />
                          <InputLabel className={classes.label} htmlFor='unRegisteredUserDTO.name'>{`${t(
                            "name"
                          )}*`}</InputLabel>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                          <Field
                            component={TextField}
                            name='unRegisteredUserDTO.zone'
                            select
                            variant='standard'
                            fullWidth
                          >
                            {zone?.zoneDTO.map((option) => (
                              <MenuItem key={option.id} value={option.name}>
                                {t('zone_name')} {option.name.split(' ')[1]}
                              </MenuItem>
                            ))}
                          </Field>
                          <InputLabel className={classes.label} htmlFor='zone'>{`${t("zone")}*`}</InputLabel>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                          <Field
                            component={TextField}
                            name='unRegisteredUserDTO.restaurantName'
                            type='text'
                            size='small'
                            variant='standard'
                            fullWidth
                          />
                          <InputLabel className={classes.label} htmlFor='unRegisteredUserDTO.restaurantName'>{`${t(
                            "establishment_name"
                          )}*`}</InputLabel>
                        </Grid>

                        <Grid item xs={12} sm={6} className={classes.field}>
                          <Field
                            component={TextField}
                            name='unRegisteredUserDTO.addressDetails'
                            type='text'
                            size='small'
                            variant='standard'
                            fullWidth
                          />
                          <InputLabel className={classes.label} htmlFor='unRegisteredUserDTO.addressDetails'>{`${t(
                            "establishment_address"
                          )}*`}</InputLabel>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                          <Field
                            component={TextField}
                            name='unRegisteredUserDTO.city'
                            type='text'
                            size='small'
                            variant='standard'
                            fullWidth
                          />
                          <InputLabel className={classes.label} htmlFor='unRegisteredUserDTO.city'>{`${t(
                            "city"
                          )}*`}</InputLabel>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                          <Field
                            component={TextField}
                            name='unRegisteredUserDTO.postalCode'
                            type='text'
                            size='small'
                            variant='standard'
                            fullWidth
                            inputProps={{ maxLength: maxPostalDigits }}
                          />
                          <InputLabel className={classes.label} htmlFor='unRegisteredUserDTO.postalCode'>{`${t(
                            "zip_code"
                          )}*`}</InputLabel>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                          <Field
                            component={TextField}
                            name='unRegisteredUserDTO.phoneNumber'
                            type='text'
                            fullWidth
                            size='small'
                            variant='standard'
                            inputProps={{ maxLength: 10 }}
                          />
                          <InputLabel className={classes.label} htmlFor='unRegisteredUserDTO.phoneNumber'>{`${t(
                            "phone"
                          )}*`}</InputLabel>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                          <Field
                            component={TextField}
                            name='unRegisteredUserDTO.email'
                            type='text'
                            size='small'
                            variant='standard'
                            fullWidth
                          />
                          <InputLabel className={classes.label} htmlFor='unRegisteredUserDTO.email'>{`${t(
                            "email"
                          )}*`}</InputLabel>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                          <Field as={TextareaAutosize} name='requestedComment' minRows={7} style={{ width: "100%" }} />
                          <InputLabel className={classes.label} htmlFor='requestedComment'>{`${t(
                            "comments"
                          )}*`}</InputLabel>
                        </Grid>
                        <Grid item xs={12} className={classes.buttonContainer}>
                          <Grid container alignItems='center'>
                            <Grid item xs={12} sm={6}>
                              <Button
                                size={"medium"}
                                variant='contained'
                                className={classes.cancelButton}
                                onClick={handleClose}
                              >
                                {t("cancel")}
                              </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Button
                                size={"medium"}
                                variant='contained'
                                color='primary'
                                type='submit'
                                className={classes.contactBtn}
                              >
                                {t("send")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Form>
            );
          }}
        </Formik>
      </Dialog>

      <Message openFl={showMsg} closeMessageFn={() => setShowMsg(false)} variant={msgVariant} textToDisplay={msgText} />
    </Fragment>
  );
};

export default ContactUs;
