import { RootState, useAppDispatch } from "state/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Toolbar, Typography, Grid, PopoverOrigin, Popover, Tooltip } from "@material-ui/core";
import { showMobileQrDialog } from "state/actions/user/user.action";
import { useStyles } from "styles/navbar.styles";
import { ReactComponent as ReferIcon } from "assets/svg/referIcon.svg";
import { ReactComponent as MyPointsIcon } from "assets/svg/myPointsIcon.svg";
import { ReactComponent as ProductPointsIcon } from "assets/svg/pointsProducts.svg";
import { ReactComponent as LogoutIcon } from "assets/svg/logout.svg";
import { ReactComponent as MobileIcon } from "assets/svg/mobileIcon.svg";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import { MouseEventHandler, SyntheticEvent, useState } from "react";
import { Box } from "@mui/system";
import { PopoverI } from "interface/home.model";
import useExtraLargeScreen from "hooks/useExtraLargeScreen.hook";

interface HeaderProps {
  showPointsMechanismFn: Function;
  logoImgSrc: string;
  logout: () => void;
  navigateToHomeFn: MouseEventHandler<HTMLImageElement>;
}

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const PopoverInitState: PopoverI = {
  anchorEle: null,
  id: undefined,
  open: false,
};

const HeaderStrip = (props: HeaderProps) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const extraLargeScreen = useExtraLargeScreen();

  const points = useSelector((state: RootState) => state.user.user.rewardPoints);
  const referralCode = useSelector((state: RootState) => state.user.user.referralCode);
  const [referPopOver, setReferPopOver] = useState(PopoverInitState);
  const [tooltipTitle, setTooltipTitle] = useState(t("copy"));

  const { showPointsMechanismFn, logout, logoImgSrc, navigateToHomeFn } = props;

  const handleMobileIconPopupDisplay = () => {
    dispatch(showMobileQrDialog(true));
  };

  const handlePopoverOpen = (event: SyntheticEvent) => {
    const target = event.target as HTMLButtonElement;

    setReferPopOver({
      anchorEle: target,
      id: "Refer Popover",
      open: true,
    });
  };

  const handlePopoverClose = () => {
    setReferPopOver(PopoverInitState);
    setTooltipTitle(t("copy"));
  };

  const handleReferralCodeCopy = () => {
    navigator.clipboard.writeText(referralCode);
    setTooltipTitle(t("copied"));
  };

  return (
    <div>
      {extraLargeScreen && (
        <Toolbar disableGutters>
          <Grid container direction='row' style={{ height: "90px" }}>
            <Grid item xs={12} className={classes.logoutSection}>
              <Grid
                container
                direction='column'
                alignItems='flex-end'
                justifyContent='center'
                style={{ padding: "9px", paddingRight: "15px" }}
                onClick={logout}
              >
                <Grid item>
                  <LogoutIcon className={classes.headerIcons} style={{ marginRight: "50px" }} />
                </Grid>
                <Grid item>
                  <Typography className={classes.logoutText}>{t("logout")}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} className={classes.mobileSection} onClick={handleMobileIconPopupDisplay}>
              <Grid container alignItems='center' justifyContent='flex-end'>
                <Grid item>
                  <MobileIcon className={classes.headerIcons} />
                </Grid>
                <Grid item>
                  <Typography className={classes.headerText}>{t("mobile_app")}</Typography>
                  <Typography className={classes.headerLowerText}>
                    Amphochef<sup>&#174;</sup>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} className={classes.referSection} onClick={(event) => handlePopoverOpen(event)}>
              <Grid container alignItems='center' justifyContent='flex-end'>
                <Grid item>
                  <ReferIcon className={classes.headerIcons} />
                </Grid>
                <Grid item>
                  <Typography className={classes.headerText}>{t("header_refer_friend_1")}</Typography>
                  <Typography className={classes.headerLowerText}>{t("header_refer_friend_2")}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={7} className={classes.mechanismSection}>
              <Grid container alignItems='center' justifyContent='flex-end' onClick={() => showPointsMechanismFn(true)}>
                <Grid item>
                  <ProductPointsIcon className={classes.headerIcons} />
                </Grid>
                <Grid item>
                  <Typography className={classes.headerText}>{t("header_mechanism_1")}</Typography>
                  <Typography className={classes.headerLowerText}>{t("header_mechanism_2")}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.logoSection}>
              <Grid container justifyContent='space-between'>
                <Grid item md>
                  <img
                    src={logoImgSrc}
                    height={80}
                    alt='logo'
                    onClick={navigateToHomeFn}
                    style={{ cursor: "pointer" }}
                  />
                </Grid>
                <Grid item className={classes.myPointsSection} md={3} style={{ marginRight: "10px" }}>
                  <Grid container alignItems='center' justifyContent='flex-end'>
                    <Grid item>
                      <MyPointsIcon className={classes.headerIcons} />
                    </Grid>
                    <Grid item md>
                      <Typography className={classes.headerText}>{t("my_points")}</Typography>
                      <Typography className={classes.headerLowerText}>{points}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      )}
      {!extraLargeScreen && <div className={classes.nonLargeScreenHeader} />}
      <Popover
        id='refer popover'
        open={referPopOver.open}
        anchorEl={referPopOver.anchorEle}
        onClose={handlePopoverClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        classes={{ paper: classes.referPopoverContainer }}
      >
        <Box>
          <Typography className={classes.referContent}>{t("refer_text")}</Typography>
          <Grid container alignItems='flex-end' className={classes.referralCodeContainer}>
            <Typography className={classes.referralCode}>{t("referral_code")}: </Typography>
            <Typography className={classes.referralCode}>{referralCode}</Typography>
            <Tooltip title={tooltipTitle} classes={{ tooltip: classes.toolTip }}>
              <CopyIcon className={classes.copyIcon} onClick={handleReferralCodeCopy} />
            </Tooltip>
          </Grid>
        </Box>
      </Popover>
    </div>
  );
};

export default HeaderStrip;
