import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import { ReactComponent as MailIcon } from "assets/svg/my-mail.svg";
import { ReactComponent as CartIcon } from "assets/svg/cart.svg";
import { ReactComponent as OrderIcon } from "assets/svg/my-orders.svg";
import { ReactComponent as UserIcon } from "assets/svg/user.svg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useTranslation } from "react-i18next";
import { DialogInterfaceI } from "interface/invoice.model";
import history from "routes/history";
import useLogout from "hooks/useLogout.hook";

const useStyles = makeStyles((theme: Theme) => {
  const tabScreen = theme.breakpoints.up("sm");

  return createStyles({
    drawer: {},
    drawerPaper: {
      width: "95vw",
      height: "95vh",
      backgroundColor: "#AC2641",
      [tabScreen]: {
        width: "50vw",
        height: "50vh",
      },
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    navText: {
      color: "#fff",
    },
    exitIcon: {
      fontSize: "32px",
      color: "#fff",
    },
  });
});

const Sidedrawer = (props: DialogInterfaceI) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const logout = useLogout();

  const { openFl, closeFn } = props;

  const handleDrawerClose = () => {
    closeFn();
  };

  const pages = [
    { icon: <CartIcon />, name: t("basket"), pageLink: "/basket" },
    { icon: <OrderIcon />, name: t("my_orders"), pageLink: "/orders" },
    { icon: <MailIcon />, name: t("invoices"), pageLink: "/invoices" },
    { icon: <UserIcon />, name: t("my_account"), pageLink: "/account" },
    { icon: <ExitToAppIcon className={classes.exitIcon} />, name: t("logout") },
  ];

  const handleRouting = (pageIndex: number) => {
    const page = pages[pageIndex];
    if (pageIndex < 4 && page.pageLink) {
      history.push(page.pageLink);
      handleDrawerClose();
    } else {
      logout();
    }
  };
  return (
    <Drawer
      variant='persistent'
      anchor='right'
      open={openFl}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon style={{ color: "#fff" }} />
        </IconButton>
      </div>
      <Divider />
      <List>
        {pages.map((pageObj, index) => (
          <ListItem button key={pageObj.name} onClick={() => handleRouting(index)}>
            <ListItemIcon>{pageObj.icon}</ListItemIcon>
            <ListItemText primary={pageObj.name} classes={{ primary: classes.navText }} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidedrawer;
