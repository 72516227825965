export const home = {
  mechanism: {
    fr: "Points par produits",
    en: "Mechanism",
    es: "mecanismo de  puntos",
    pt: "mecanismo de pontos",
  },
  header_mechanism_1: {
    en: "Product ",
    fr: "Points par ",
    es: "Mecanismo ",
    pt: "Mecanismo",
  },
  header_mechanism_2: {
    en: "Mechanism",
    fr: "produits",
    es: "de  puntos",
    pt: "de pontos",
  },
  products: {
    fr: "Des produits",
    en: "Products",
    es: "Productos",
    pt: "Produtos",
  },
  bonus: {
    fr: "Prime",
    en: "Bonus",
    es: "Bonus",
    pt: "Bônus",
  },
  Welcome: {
    fr: "Bienvenue",
    en: "Welcome",
    es: "Bienvenido",
    pt: "Damos-lhe as boas-vindas",
  },
  Sponsorship: {
    fr: "Parrainage",
    en: "Sponsorship",
    es: "Patrocinio",
    pt: "Patrocínio",
  },
  Seniority: {
    fr: "Ancienneté",
    en: "Seniority",
    es: "Antigüedad",
    pt: "Antiguidade",
  },
  my_points: {
    fr: "Mes points",
    en: "My points",
    es: "Mis puntos",
    pt: "Os meus pontos",
  },
  home: {
    fr: "Accueil",
    en: "Home",
    es: "Inicio",
    pt: "Início",
  },
  basket: {
    fr: "Panier",
    en: "Basket",
    es: "Cesta",
    pt: "Cesto",
  },
  my_orders: {
    fr: "Mes commandes",
    en: "My Orders",
    es: "Mis pedidos",
    pt: "As minhas encomendas",
  },
  invoices: {
    fr: "Mes factures",
    en: "Invoices",
    es: "Facturas",
    pt: "Facturas",
  },
  my_account: {
    fr: "Mon compte",
    en: "My Account",
    es: "Mi cuenta",
    pt: "A minha conta",
  },
  logout: {
    fr: "Se déconnecter",
    en: "Logout",
    es: "Cerrar sesión",
    pt: "Fechar Sessão",
  },
  regulations: {
    en: "*Refer to the regulations available at the bottom of the page in the support section",
    fr: "*Se référer au règlem ent disponible en bas de page dans la rubrique support",
    es: "Consulte las regulaciones disponibles en la parte inferior de la página en la sección de soporte.",
    pt: "Consulte os regulamentos disponíveis na parte inferior da página na seção de suporte.",
  },

  filter_text: {
    fr: "Découvrez la boutique cadeaux",
    en: "Discover The Whole Store",
    es: "Descubre toda la tienda",
    pt: "Conheça toda a loja",
  },
  category_home: {
    fr: "Choisir une catégorie",
    en: "Choose a Category",
    es: "Elige una categoría",
    pt: "Escolha uma categoria",
  },
  brand: {
    fr: "Choisir une marque",
    en: "Choose a Brand",
    es: "Elige una marca",
    pt: "Escolha uma marca",
  },
  brand_text: {
    en: "Brand",
    fr: "Marque",
    es: "Marca",
    pt: "Marca",
  },
  points_between: {
    fr: "Points entre",
    en: "Points Between",
    es: "Puntos entre",
    pt: "Pontos entre",
  },
  search: {
    fr: "Rechercher",
    en: "Search",
    es: "Buscar",
    pt: "Pesquisar",
  },
  apply_filter: {
    fr: "Filtres",
    en: "Filter",
    es: "Filtro",
    pt: "Aplicar filtro",
  },
  reset_filter: {
    en: "Reset",
    fr: "Réinitialiser",
    es: "Restablecer",
    pt: "Reestabelecer filtro",
  },

  search_products: {
    fr: "Rechercher un produit",
    en: "Search Products",
    es: "Buscar Productos",
    pt: "Pesquisar produtos",
  },

  no_search_result_home: {
    fr: "Pas de résultat.",
    en: "No search results found for the criteria.",
    es: "Sin resultados.",
    pt: "Não há prémios para mostrar",
  },

  cart: {
    fr: "Ajouter au panier",
    en: "Add To Cart",
    es: "Añadir al carrito",
    pt: "Acrescentar ao carrinho",
  },
  points: {
    fr: "Points",
    en: "Points",
    es: "Puntos",
    pt: "Pontos",
  },
  techinal_sheet: {
    fr: "Fiche technique",
    en: "Technical Sheet",
    es: "Ficha Técnica",
    pt: "Ficha técnica",
  },

  item_success: {
    en: "Item added to Cart!",
    fr: "Article ajouté au panier !",
    es: "Artículo agregado al carrito",
    pt: "Artigo acrescentado ao carrinho!",
  },
  only: {
    en: "Only ",
    fr: "Plus que",
    es: "Solamente",
    pt: "Somente",
  },
  stock_left: {
    en: "left in stock",
    fr: "articles disponibles",
    es: "dejado en stock",
    pt: "deixado em estoque",
  },
  item_delete: {
    en: "Item removed from the cart.",
    fr: "Article retiré du panier.",
    es: "Artículo retirado de la cesta.",
    pt: "Item removido do carrinho.",
  },
  item_update: {
    en: "Item quantity adjusted successfully!",
    fr: "Le nombre d’article est maintenant ajusté",
    es: "El número de artículo ahora está ajustado",
    pt: "Quantidade de artigos ajustada corretamente",
  },
  out_of_stock: {
    en: " Out of Stock",
    fr: " Rupture de stock",
    es: " Agotado",
    pt: " Artigo esgotado",
  },

  empty_cart: {
    en: "Cart is empty",
    fr: "Le panier est vide",
    es: "la canasta está vacía",
    pt: "carrinho esta vazio",
  },

  points_error: {
    en: "There aren't enough points",
    fr: "Vous n’avez pas suffisamment de points",
    es: "No hay suficientes puntos",
    pt: "Não tem pontos suficientes para realizar esta encomenda",
  },

  no_active_orders: {
    en: "No Active orders",
    fr: "Il n’y a pas de commande en cours",
    es: "No hay ningún pedido en curso",
    pt: "Não há encomendas ativas",
  },

  no_completed_orders: {
    en: "No Completed orders",
    fr: "Il n’y a pas de commande finalisée ",
    es: "No hay un pedido finalizado",
    pt: "Não há encomendas completadas",
  },
  copy: {
    en: "Copy",
    fr: "Copie",
    es: "Dupdo",
    pt: "cópia de",
  },
  copied: {
    en: "Copied",
    fr: "Copié",
    es: "Copiado",
    pt: "Copiado",
  },
  header_refer_friend_1: {
    en: "Refer a",
    fr: "Parrainez",
    es: "Recomendar",
    pt: "Indique",
  },
  header_refer_friend_2: {
    en: "friend",
    fr: "un ami",
    es: "a un amigo",
    pt: "um Amigo",
  },
  refer_text: {
    en: "Refer your friend and earn 50 points on their first successful invoice upload",
    fr: "Parrainez votre ami et gagnez 50 points lors de son premier téléchargement de facture réussi",
    es: "Recomiende a su amigo y gane 50 puntos en su primera carga de factura exitosa",
    pt: "Indique seu amigo e ganhe 50 pontos no primeiro upload de fatura bem-sucedido",
  },
  mobile_app: {
    en: "Mobile App",
    fr: "Application mobile",
    es: "Aplicación movil",
    pt: "Aplicativo móvel",
  },
};
