import { Dialog, Slide, Grid, Button, Typography } from "@material-ui/core";

import CancelIcon from "@material-ui/icons/Cancel";
import { useEffect, useState } from "react";
import useMobileScreen from "hooks/useMobileScreen.hook";
import { useTranslation } from "react-i18next";
import { loginStyles } from "styles/login.styles";
import { RootState, useAppDispatch } from "state/store";
import { countryCodeActionCreator, setLangActionCreator } from "state/actions/user/user.action";
import { languages, RegionSelectI, CountryI } from "interface/user.model";
import { useSelector } from "react-redux";
import amphora1x from "assets/images/amphora1x.png";
import amphora2x from "assets/images/amphora2x.png";
// import { changeTrustArcSrc } from "utils/changeTrustArcSrc";

interface LanguageSelectProps {
  showLangDialog: boolean;
  setShowLangDialog: () => void;
}

const LanguageSelect = (props: LanguageSelectProps) => {
  const [t] = useTranslation();
  const classes = loginStyles();
  const [isSelected, setIsSelected] = useState<languages | null>(null);
  const isMobileScreen = useMobileScreen();

  const [regionsButtonObj, setRegionsButtonObj] = useState<RegionSelectI>();
  const dispatch = useAppDispatch();
  const countries = useSelector((state: RootState) => state.user.countries);

  useEffect(() => {
    if (countries.length > 0) {
      let regionsTempObj = {} as RegionSelectI;
      countries.forEach((country: CountryI) => {
        regionsTempObj[country.localeCode] = {
          languageText: `${country.countryName} (${country.displayName})`,
          countryCode: country.countryCode,
        };
      });
      setRegionsButtonObj(regionsTempObj);
    }
  }, [countries]);

  const handleLanguageSelect = (localeCode: languages) => {
    dispatch(setLangActionCreator(localeCode));
    setIsSelected(localeCode);
    if (regionsButtonObj) {
      dispatch(countryCodeActionCreator(regionsButtonObj[localeCode].countryCode));
      // changeTrustArcSrc(localeCode);
    }
  };

  const getAmphoraLogo = () => {
    return isMobileScreen ? amphora1x : amphora2x;
  };

  return (
    <Dialog
      open={props.showLangDialog}
      maxWidth={"lg"}
      onClose={props.setShowLangDialog}
      classes={{ paper: classes.languageSelectPaper }}
      fullWidth
      aria-labelledby='Language Select'
      aria-describedby='Language Select Pop-up'
      TransitionComponent={Slide}
      transitionDuration={500}
    >
      <div>
        <CancelIcon
          color='secondary'
          fontSize={isMobileScreen ? "medium" : "large"}
          className={classes.cancelIcon}
          onClick={props.setShowLangDialog}
        />
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12}>
            <img src={getAmphoraLogo()} alt='Amhora Logo' style={{ margin: "0rem auto 1rem auto", display: "block" }} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.languageSelectText}>{t("select_language")}</Typography>
          </Grid>
          {regionsButtonObj &&
            (Object.keys(regionsButtonObj) as Array<keyof typeof regionsButtonObj>).map((localeCode, index) => (
              <Grid item key={index} xs={12}>
                <Button
                  variant={isSelected === localeCode ? "contained" : "outlined"}
                  color={isSelected === localeCode ? "secondary" : undefined}
                  className={classes.countryButton}
                  onClick={() => handleLanguageSelect(localeCode)}
                >
                  {regionsButtonObj[localeCode].languageText}
                </Button>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Button
                variant='contained'
                className={classes.proceedButton}
                onClick={() => {
                  props.setShowLangDialog()
              }}>
                {t("proceed")}
              </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default LanguageSelect;
