export const common = {
  cancel: {
    en: "Cancel",
    fr: "Annuler",
    es: "Cancelar",
    pt: "Cancelar",
  },
  update: {
    en: "Update",
    fr: "Actualiser",
    es: "Actualizar",
    pt: "Atualizar",
  },
  edit: {
    en: "Edit",
    fr: "Modifier",
    es: "Editar",
    pt: "Editar",
  },
  delete: {
    en: "Delete",
    fr: "Supprimer",
    es: "Borrar",
    pt: "Eliminar",
  },
  send: {
    en: "Send",
    fr: "Envoyer",
    es: "Enviar",
    pt: "Enviar",
  },
  close: {
    en: "Close",
    fr: "Fermer",
    es: "Cerrado",
    pt: "Fechado",
  },
  privacy_policy: {
    en: "Privacy Policy",
    fr: "Politique de confidentialité ",
    es: "Política de privacidad",
    pt: "Política de Privacidade",
  },
  cookies_text: {
    en: "We use cookies to ensure you get the best experience on our website. By browsing it, we consider you accept its use.",
    fr: "Nous utilisons des cookies pour vous fournir un contenu personnalisé. En poursuivant votre navigation sur notre site nous considérons que vous acceptez l'utilisation de ces cookies",
    es: "Usamos cookies para asegurarnos de que obtenga la mejor experiencia en nuestro sitio web.",
    pt: "Usamos cookies para garantir que você tenha a melhor experiência em nosso site. Ao navegar, consideramos que aceita a sua utilização.",
  },
  view_privacy_policy: {
    en: "View our privacy policy",
    fr: "Consultez notre politique de confidentialité",
    es: "Observe nuestra política de privacidad",
    pt: "Veja nossa Política de Privacidade",
  },
  cookies_setting: {
    en: "Cookie Settings",
    fr: "Paramètre des cookies",
    es: "Configuración de cookies",
    pt: "Configurações de cookies",
  },
  accept_all: {
    en: "Accept all",
    fr: "Tout accepter",
    es: "Aceptar todo",
    pt: "Aceitar tudo",
  },
  required_cookies: {
    en: "Required Cookies",
    fr: "Cookies requis",
    es: "Cookies requeridas",
    pt: "Cookies obrigatórios",
  },
  performance_cookies: {
    en: "Performance Cookies",
    fr: "Performance des cookies",
    es: "Rendimiento de las cookies",
    pt: "Cookies de desempenho",
  },
  functional_cookies: {
    en: "Functional Cookies",
    fr: "Cookies en fonction",
    es: "Cookies en funcionamiento",
    pt: "Cookies funcionais",
  },
  required_cookies_text: {
    en: "This cookies are required for proper functioning of our website and cannot be disabled. This help us make our website usable by enabling basic functions such as page navigation, site security and access to information that requires authentication.",
    fr: "Ces cookies sont obligatoires pour le bon fonctionnement de notre site, ils ne peuvent être désactiver. Cela nous permet de rendre le site accessible à travers de simples fonctionnalités telles que les pages de navigation, la sécurité du site et l'accès aux informations qui requièrent une authentification.",
    es: "Estas cookies son necesarias para el correcto funcionamiento de nuestro sitio, no se pueden desactivar. Esto nos permite hacer que el sitio sea accesible a través de funciones simples como páginas de navegación, seguridad del sitio y acceso a información que requiere autenticación.",
    pt: "Esses cookies são necessários para o bom funcionamento do nosso site e não podem ser desativados. Isso nos ajuda a tornar nosso site utilizável, habilitando funções básicas, como navegação na página, segurança do site e acesso a informações que requerem autenticação.",
  },
  performance_cookies_text: {
    en: "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.",
    fr: "Ces cookies nous permettent de connaître le nombre de visites ainsi que les sources du traffic sur notre site afin de mesurer et améliorer ses performances. Ils nous permettent de savoir quelles pages sont les plus et moins populaires et de comprendre comment les utilisateurs naviguent sur le site. Toutes les informations collectées par les cookies sont agrégées et de ce fait sont anonymes. Si vous n'acceptez pas ces cookies nous ne serons pas en mesure de savoir si vous avez visité notre site, et nous ne pourrons pas améliorer ses performances.",
    es: "Estas cookies nos permiten conocer el número de visitas así como las fuentes de tráfico en nuestro sitio para medir y mejorar su rendimiento. Nos permiten saber qué páginas son las más y las menos populares y comprender cómo navegan los usuarios por el sitio. Toda la información recopilada por las cookies es agregada y, por lo tanto, anónima. Si no acepta estas cookies, no podremos saber si ha visitado nuestro sitio y no podremos mejorar su rendimiento.",
    pt: "Esses cookies nos permitem contar visitas e fontes de tráfego para que possamos medir e melhorar o desempenho do nosso site. Eles nos ajudam a saber quais páginas são as mais e menos populares e ver como os visitantes se movimentam pelo site. Todas as informações que esses cookies coletam são agregadas e, portanto, anônimas. Se você não permitir esses cookies, não saberemos quando você visitou nosso site e não poderemos monitorar seu desempenho.",
  },
  advanced_cookie_settings: {
    en: "Advanced Cookie settings",
    fr: "Paramètres de cookies avancés",
    es: "Configuración avanzada de cookies",
    pt: "Configurações avançadas de cookies",
  },
  confirm_my_choices: {
    en: "Confirm My choices",
    fr: "confirmer mes choix",
    es: "confirmar mis elecciones",
    pt: "Confirmar minhas escolhas",
  },
  functional_cookies_text: {
    en: "These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.",
    fr: "Ces cookies permettent des fonctionnalités améliorées et une personnalisation du site. Ils peuvent être gérer par nous ou par une tierce partie dont les services sont inclus sur notre site. Si vous n'acceptez pas ces cookies le bon fonctionnement de certains voire tous les services n'est pas assuré.",
    es: "Estas cookies permiten una mejor funcionalidad y personalización del sitio. Pueden ser administrados por nosotros o por un tercero cuyos servicios están incluidos en nuestro sitio. Si no acepta estas cookies, no se garantiza el correcto funcionamiento de algunos o de todos los servicios.",
    pt: "Esses cookies permitem que o site forneça funcionalidade e personalização aprimoradas. Eles podem ser definidos por nós ou por terceiros cujos serviços adicionamos às nossas páginas. Se você não permitir esses cookies, alguns ou todos esses serviços podem não funcionar corretamente.",
  },
  pdf_unavailable_text: {
    en: "Document will soon be uploaded and available for use.",
    fr: "Le document sera bientôt téléchargé et disponible pour utilisation.",
    es: "El documento pronto se cargará y estará disponible para su uso.",
    pt: "O documento será carregado em breve e estará disponível para uso.",
  }
};
