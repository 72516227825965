// import i18n, { LanguageDetectorAsyncModule, Services, InitOptions } from 'i18next';
// import { initReactI18next } from 'react-i18next';
import { getTranslation } from "./index";

import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

const en = getTranslation("en");
const fr = getTranslation("fr");
const es = getTranslation("es"); //Spanish
const pt = getTranslation("pt"); //Portugal
const lang = localStorage.getItem("lang");

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: lang || undefined,
    fallbackLng: "en",
    debug: false,
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      es: {
        translation: es,
      },
      pt: {
        translation: pt,
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
