import { Button, Dialog, DialogContent, Grid, InputLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "state/store";
import { connect, useSelector } from "react-redux";

import CancelIcon from "@material-ui/icons/Cancel";
import { InputText } from "components/common/InputText.component";
import PointsMechanismModal from "components/common/PointsMechanismDialog.component";
import { Typography } from "components/common/Common.component";
import { UPLOAD_SERVICE_REQUEST } from "state/actions/home/home.type";
import { footerStyles } from "styles/footer.styles";
import { uploadServiceRequest } from "state/actions/home/home.action";
import { useTranslation } from "react-i18next";
import FrenchLogo from "assets/images/frenchLogo.png";
import SpainLogo from "assets/images/spainLogo.png";
import portugalLogo from "assets/images/portugalLogo.png";
import CargillLogo from "assets/svg/cargillLogo.svg";
import Message from "./Message.component";
import useMobileScreen from "hooks/useMobileScreen.hook";
import { ReactComponent as AmphoraIcon } from "assets/svg/amphoraIcon.svg";

function Footer(props: any) {
  const classes = footerStyles();
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const { countryCode } = user;
  const isMobileScreen = useMobileScreen();

  const [logo, setLogo] = useState("");
  const [contactDlg, setContactDlg] = useState(false);
  const [successSnack, setSuccessSnack] = useState(false);
  const [failureSnack, setFailureSnack] = useState(false);
  const [contactMail, setContactMail] = useState('');

  const [serviceRequest, setServiceRequest] = useState({
    orderNumber: "",
    deliveryReceiptNumber: "",
    subject: "",
    message: "",
    userId: user.userId,
  });

  useEffect(() => {
    if (props.serviceReqStatus === true) {
      setSuccessSnack(true);
      dispatch({
        type: UPLOAD_SERVICE_REQUEST,
        payload: "undefined",
      });
      handleServiceClose();
    }
    if (props.serviceReqStatus === false) {
      setFailureSnack(true);
      dispatch({
        type: UPLOAD_SERVICE_REQUEST,
        payload: "undefined",
      });
      handleServiceClose();
    } // eslint-disable-next-line
  }, [props.serviceReqStatus]);

  const handleServiceRequest = (event: any) => {
    const { name, value } = event.target;
    setServiceRequest((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (countryCode.toLowerCase() === "esp") {
      setLogo(SpainLogo);
      setContactMail('clubdechefs@Cargill.com');
    } else if (countryCode.toLowerCase() === "fra") {
      setLogo(FrenchLogo);
      setContactMail("leclubdeschefs@Cargill.com");
    } else if (countryCode.toLowerCase() === "prt") {
      setLogo(portugalLogo);
      setContactMail("clubdechefs@Cargill.com");
    }
  }, [countryCode]);

  const handleServiceClose = () => {
    setContactDlg(false);
    setServiceRequest({
      orderNumber: "",
      deliveryReceiptNumber: "",
      subject: "",
      message: "",
      userId: user.userId,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(uploadServiceRequest(serviceRequest));
  };

  const [mechanismDlg, setMechansimDlg] = React.useState(false);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <img
            src={logo}
            height={60}
            alt='Amphora Logo'
            style={{ display: "block", margin: "10px auto", marginBottom: 0 }}
          />
        </Grid>
        <Grid xs={12} item className={classes.outerDiv}>
          <Grid container>
            {countryCode !== "PRT" && (
              <Grid item xs={12} sm={6} md className={classes.listDiv}>
                <Typography variant='subtitle2' className={classes.font3} style={{ marginLeft: 0 }}>
                  {t("about_us_text_1")}
                  <br /> {t("about_us_text_2")} <br />
                  <a className={classes.link} href='https://amphora-restauration.fr/' rel='noreferrer' target='_blank'>
                    {t("website")}
                  </a>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md className={classes.mt10}>
              <Typography variant='h3' className={classes.font1}>
                {t("support")}
              </Typography>
              <Grid container>
                <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                  <Grid container justifyContent='flex-start' alignItems='center'>
                    <AmphoraIcon />
                    <Typography className={classes.font3} onClick={() => setMechansimDlg(true)}>
                      {t("support_text")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                  <Grid container justifyContent='flex-start' alignItems='center'>
                    <AmphoraIcon />
                    <Typography className={classes.font3}>
                      <a className={classes.link} href='/loyalty-terms' rel='noreferrer' target='_blank'>
                        {t("rules")}
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                  <Grid container justifyContent='flex-start' alignItems='center'>
                    <Grid item>
                      <AmphoraIcon />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.font3} onClick={() => setContactDlg(true)}>
                        {t("gift_text_1")}
                      </Typography>
                      <Typography className={classes.font3} onClick={() => setContactDlg(true)}>
                        {t("gift_text_2")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                  <Grid container justifyContent='flex-start' alignItems='center'>
                    <Grid item>
                      <AmphoraIcon />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.font3}>
                        <span id="teconsent"></span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                  <Grid container justifyContent='flex-start' alignItems='center'>
                    <Grid item>
                      <Typography className={classes.font3}>
                        <span id="trustarc-irm-placeholder"></span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                  <Grid container justifyContent='flex-start' alignItems='center'>
                    <Grid item>
                      <Typography className={classes.font3}>
                        <span id="consent_blackbar" className={classes.consentBlackbar}></span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md>
              <Typography variant='h3' className={`${classes.font1} ${classes.mt10}`}>
                {t("contact_us")}
              </Typography>
              {
                countryCode && countryCode === 'FRA'
                ? (
                  <Typography variant='subtitle2' className={classes.address}>
                    Le Club des Chefs Amphora
                    <br />
                    95 rue Denfert Rochereau
                    <br />
                    69004 LYON
                    <br />
                    Email :
                    <br />
                    <a href={`mailto:${contactMail}`} className={classes.emailId}>
                      <span>{contactMail}</span>
                    </a>
                  </Typography>
                  )
                : (
                  <Typography variant='subtitle2' className={classes.address}>
                    Cargill-SLU
                    <br />
                    Att. Ruben Muñoz
                    <br />
                    Av. Can Fatjó dels Aurons, 9
                    <br />
                    08174 Sant Cugat del Vallès
                    <br />
                    Barcelona
                    <br />
                    Email :
                    <br />
                    <a href={`mailto:${contactMail}`} className={classes.emailId}>
                      <span>{contactMail}</span>
                    </a>
                  </Typography>
                  )
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.cargillLogoContainer}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <img src={CargillLogo} alt='Cargill Logo' height='50' />
            </Grid>
            <Grid item>
              <Grid container justifyContent='flex-end' direction='column'>
                <Grid item>
                  <Typography variant='subtitle1' className={classes.copyrightText}>
                    <a className={classes.termsLink} href='/web-terms' rel='noreferrer' target='_blank'>
                      {t("terms")}
                    </a>
                    |
                    <a
                      className={classes.termsLink}
                      href='https://www.cargill.com/page/privacy'
                      rel='noreferrer'
                      target='_blank'
                    >
                      {t("privacy_policy")}
                    </a>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant='subtitle1'
                    className={classes.copyrightText}
                  >{`${new Date().getFullYear()} Cargill, Incorporated,`}</Typography>
                  <Typography variant='subtitle1' className={classes.copyrightText}>
                    All Rights Reserved
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={contactDlg}
        onClose={() => handleServiceClose()}
        maxWidth={"sm"}
        classes={{ paper: classes.serviceRequestContainer }}
      >
        <form style={{ margin: "0.5rem" }} onSubmit={(event: any) => handleSubmit(event)}>
          <DialogContent>
            <Typography variant='h5'>{t("service_request")}</Typography>
            <CancelIcon
              fontSize={isMobileScreen ? "medium" : "large"}
              className={classes.cancelIcon}
              onClick={() => handleServiceClose()}
              color='primary'
            />
            <br />
            <Typography variant='body1'>
              {t("service_request_info1")}
              <br />
              {t("service_request_info2")}
            </Typography>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputText
                  type='number'
                  inputProps={{ required: true, min: "0" }}
                  value={serviceRequest.orderNumber}
                  onChange={handleServiceRequest}
                  name='orderNumber'
                  size='small'
                  fullWidth
                  required
                />
                <InputLabel className={classes.label}>{t("order_number")}</InputLabel>
              </Grid>

              <Grid item xs={12}>
                <InputText
                  inputProps={{ required: true }}
                  value={serviceRequest.subject}
                  onChange={handleServiceRequest}
                  name='subject'
                  size='small'
                  fullWidth
                  required
                  variant='standard'
                />
                <InputLabel className={classes.label}>{t("subject_of_request")}</InputLabel>
              </Grid>
              <Grid item xs={12}>
                <InputText
                  inputProps={{ required: true }}
                  multiline
                  rows={7}
                  value={serviceRequest.message}
                  name='message'
                  onChange={handleServiceRequest}
                  size='small'
                  fullWidth
                  required
                />
                <InputLabel className={classes.label}>{t("message_of_request")}</InputLabel>
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-end' spacing={5}>
              <Grid item>
                <Button variant='contained' onClick={() => handleServiceClose()} className={classes.cancelButton}>
                  {t("cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button type='submit' color='primary' variant='contained' className={classes.successButton}>
                  {t("send")}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>

      <PointsMechanismModal mechanismDlg={mechanismDlg} setMechansimDlg={setMechansimDlg} />

      <Message
        closeMessageFn={() => setSuccessSnack(false)}
        variant='success'
        openFl={successSnack}
        textToDisplay={t("service_request_successfull")}
      />

      <Message
        openFl={failureSnack}
        closeMessageFn={() => setFailureSnack(false)}
        variant='error'
        textToDisplay={t("service_request_failed")}
      />
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    serviceReqStatus: state.home.serviceReqStatus,
  };
};

export default connect(mapStateToProps, {})(Footer);
