export const footer = {
  about_us: {
    en: "About Us",
    fr: "À propos de nous",
    es: "Sobre nosotros",
    pt: "Sobre nós",
  },
  about_us_text_1: {
    en: "For more information about AMPHORA.",
    fr: "Pour plus d'informations sur AMPHORA,",
    es: "Para obtener más información sobre AMPHORA.",
    pt: "Para mais informações sobre AMPHORA.",
  },
  about_us_text_2: {
    en: "please visit our website -",
    fr: " veuillez visiter notre site Web -",
    es: " visite nuestro sitio web -",
    pt: " visite o nosso site -",
  },
  support: {
    en: "Support",
    fr: "Support",
    es: "Ayuda",
    pt: "Apoio, suporte",
  },
  support_text: {
    en: "Point-bearing products",
    fr: "Points par produits",
    es: "Mecanismo de  puntos",
    pt: "Produtos de ponta",
  },
  rules: {
    en: "Rules",
    fr: "Réglement",
    es: "Normas",
    pt: "Regras",
  },
  gift_text_1: {
    en: "Gift after-sales service ",
    fr: "Demande de service après-vente ",
    es: "Solicitud de servicio posventa ",
    pt: "Serviço pós-venda de presentes",
  },
  gift_text_2: {
    en: "request (only)",
    fr: "(commande de cadeaux uniquement)",
    es: "(solo pedidos de obsequios)",
    pt: "pedido (somente)",
  },
  terms: {
    en: "Terms",
    fr: "Termes",
    es: "Términos",
    pt: "Termos",
  },
  conditions: {
    en: "Conditions",
    fr: "Conditions",
    es: "condiciones",
    pt: "Condições",
  },
  service_request: {
    en: "Service Request",
    fr: "Demande de SAV",
    es: "Solicitud de servicio",
    pt: "Requisiçao de serviço",
  },
  service_request_info1: {
    en: "You have an issue with the article ordered",
    fr: "Vous avez une panne sur un article que vous avez commandé.",
    es: "Tiene un problema con un artículo que solicitó.",
    pt: "Você tem um problema com um item que você pediu.",
  },
  service_request_info2: {
    en: "To start the “after-sales service” process, indicate the order number associated to this order",
    fr: "Pour lancer la procédure SAV, merci de saisir le numéro de commande et/ou le numéro de BL associé à cette commande.",
    es: "Para iniciar el procedimiento de servicio posventa, ingrese el número de pedido asociado con este pedido.",
    pt: "Para iniciar o procedimento de serviço pós-venda, insira o número do pedido associado a este pedido.",
  },
  order_number: {
    en: "Order Number *",
    fr: "N° de commande *",
    es: "Número de pedido *",
    pt: "Número de pedido *",
  },
  delivery_receipt_number: {
    en: "or Delivery Receipt Number",
    fr: "ou votre n° de BL",
    es: "o Número de recibo de entrega",
    pt: "o Número do recibo de entrega",
  },
  subject_of_request: {
    en: "Subject of your request *",
    fr: "Objet de votre demande *",
    es: "Tema de tu solicitud *",
    pt: "Assunto do seu pedido *",
  },
  message_of_request: {
    en: "Message (Details of the issue) *",
    fr: "message (référence article, détail de la panne) *",
    es: "mensaje (número de artículo, detalle del problema) *",
    pt: "Mensagem (detalhes do problema) *",
  },
  info_mandatory: {
    en: "(*) fields mandatory",
    fr: "(*) : zones obligatoires",
    es: "(*) campos obligatorios",
    pt: " (*) Campos obrigatórios",
  },
  service_request_successfull: {
    fr: "Demande envoyée avec succès",
    en: "Service request submitted successfully",
    es: "Solicitud enviada con éxito",
    pt: "Solicitação enviada com sucesso",
  },
  website: {
    en: "",
    fr: "www.amphora-restauration.fr",
    es: "www.amphora-restauracion.es/",
    pt: "",
  },

  service_request_failed: {
    en: "Request submission failed",
    fr: "Échec de la soumission de la demande",
    es: "El envío de la solicitud falló",
    pt: "Falha no envio da solicitação",
  },
};
