import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

import * as OktaSignIn from "@okta/okta-signin-widget";

import { useEffect, useRef } from "react";
import { setOktaIdActionCreator, setTokenActionCreator } from "state/actions/user/user.action";

import { oidc } from "../../config";
import { useAppDispatch } from "state/store";
import { useOktaAuth } from "@okta/okta-react";

const SignIn = (language) => {
  const dispatch = useAppDispatch();
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef();

  const lang = language.language === "pt" ? "pt-BR" : language.language;

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }
    const { issuer, clientId, redirectUri, scopes, useInteractionCode } = oidc;
    const widget = new OktaSignIn({
      baseUrl: issuer.split("/oauth2")[0],
      clientId,
      redirectUri,
      language: lang,
      authParams: {
        issuer,
        scopes,
      },
      feature: {
        autoFocus: false,
      },
      autoFocus: false,
      pkce: true,
      useInteractionCodeFlow: useInteractionCode, // Set to true, if your org is OIE enabled
    });

    widget.renderEl(
      { el: widgetRef.current },
      (res) => {
        const oktaId = res.tokens.idToken.claims.sub;
        const accessToken = res.tokens.accessToken.accessToken;
        dispatch(setOktaIdActionCreator(oktaId));
        dispatch(setTokenActionCreator(accessToken));
        oktaAuth.handleLoginRedirect(res.tokens);
      },
      (err) => {
        throw err;
      }
    );

    return () => widget.remove();
  }, [oktaAuth, lang, dispatch]);

  return (
    <div>
      <div ref={widgetRef} />
    </div>
  );
};
export default SignIn;
