import {
  UPLOAD_SERVICE_REQUEST,
  OFFERS,
  homeActionType,
  SHOW_LOADER,
  HOME_MOUNTED,
  LOGO,
  LOGO_CONTENT,
  LEGALDOCS,
  PRODUCT_VOLUME_POINTS,
} from "./home.type";
import { AppDispatch, AppThunk } from "state/store";
import { axiosInstance } from "utils/axios";
import { OfferObjI, PointsMechanismI, LegalDocsObjI } from "interface/home.model";

export const uploadServiceRequestActionCreator = (data: boolean | undefined): homeActionType => {
  return {
    type: UPLOAD_SERVICE_REQUEST,
    payload: data,
  };
};

export const offersActionCreator = (data: OfferObjI[]): homeActionType => {
  return {
    type: OFFERS,
    payload: data,
  };
};

export const showLoaderActionCreator = (data: boolean): homeActionType => {
  return {
    type: SHOW_LOADER,
    payload: data,
  };
};

export const isHomeCompMounted = (data: boolean): homeActionType => {
  return {
    type: HOME_MOUNTED,
    payload: data,
  };
};

export const logoActionCreator = (imgSrc: string): homeActionType => {
  return {
    type: LOGO,
    payload: imgSrc,
  };
};

export const logoContentActionCreator = (content: string): homeActionType => {
  return {
    type: LOGO_CONTENT,
    payload: content,
  };
}

export const legalDocsActionCreator = (legalDocsObj: LegalDocsObjI): homeActionType => {
  return {
    type: LEGALDOCS,
    payload: legalDocsObj,
  };
};

export const ProductVolumePointsActionCreator = (data: PointsMechanismI): homeActionType => {
  return {
    type: PRODUCT_VOLUME_POINTS,
    payload: data,
  };
};

export const getOfferDetails = (): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    const getOfferURL = "getAllOnGoingPromotionsOffers";
    axiosInstance
      .get(getOfferURL, {
        params: {
          countryCode: getState().user.user.countryCode,
        },
      })
      .then((response) => {
        dispatch(offersActionCreator(response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const uploadServiceRequest = (service_data: any): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    const uploadReq = "addServiceRequest";
    axiosInstance
      .post(uploadReq, {
        ...service_data,
        localeCode: getState().user.localeCode,
        countryCode: getState().user.countryCode,
      })
      .then((response) => {
        dispatch(uploadServiceRequestActionCreator(true));
      })
      .catch((error) => {
        dispatch(uploadServiceRequestActionCreator(false));
      });
  };
};

/**
 * This Action will store the Src of the Logo Image in the reducer.
 * 1. Fetch the Terraform S3 bucket url from the API
 * 2. Send url to the reducer
 * @param countryCode
 * @returns
 */
export const fetchLogo = (countryCode: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      const pathParameter = "brandingLogo";
      const response = await axiosInstance.get(pathParameter, {
        params: {
          countryCode,
        },
      });
      dispatch(logoActionCreator(response.data.logoUrl));
      dispatch(logoContentActionCreator(response.data.content));
    } catch (error) {
      console.error("Logo Image Couldn't be fetched.");
    }
  };
};

export const fetchLegalDocs = (countryCode: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      const pathParameter = "legalDocs";
      const response = await axiosInstance.get(pathParameter, {
        params: {
          countryCode,
        },
      });
      dispatch(legalDocsActionCreator({
        webTermPdfSrc: response.data.docFileURLWeb,
        loyaltyTermPdfSrc: response.data.docFileURLLoyalty
      }));
    } catch (error) {
      console.error("Legal Documents Couldn't be fetched.");
    }
  };
};

export const getPointsMechanismData = (): AppThunk => {
  return async (dispatch: AppDispatch, getState) => {
    const localeCode = getState().user.localeCode;
    const countryCode = getState().user.user.countryCode;
    try {
      const pathParameter = "getProductVolumeAndPoint";
      const { data }: { data: PointsMechanismI } = await axiosInstance.get(pathParameter, {
        params: {
          localeCode,
          countryCode,
        },
      });
      dispatch(ProductVolumePointsActionCreator(data));
    } catch (error) {
      console.error("Product Points data couldn't be fetched.", error);
    }
  };
};
