import { makeStyles, Theme } from "@material-ui/core/styles";
import BG from "assets/images/loginBG.jpg";
import thankYouBG from "assets/svg/thankYouBG.svg";
import mapImg from "assets/images/map.png";

export const loginStyles = makeStyles((theme: Theme) => {
  const tabScreen = theme.breakpoints.up("sm");
  const largeScreen = theme.breakpoints.up("md");
  return {
    root: {
      flexGrow: 1,
    },
    logoCard: {
      backgroundColor: "var(--primary-orange) !important",
      height: "90vh",
      padding: "5vw",
      margin: "5vh",
      minWidth: "200px",
    },
    stepper: {
      background: "none",
      boxShadow: "0px 0px 0px ",
      width: "30vw",
    },
    step: {
      color: "#fff",
      marginTop: "30px",
    },
    img: {
      height: 255,
      // maxWidth: 400,
      overflow: "hidden",
      display: "block",
      width: "100%",
    },
    cardText: {
      color: "white",
    },
    cardButton: {
      backgroundColor: "white",
      color: "var(--primary-orange)",
      margin: "20px",
    },
    step1Btn: {
      backgroundColor: "white",
      color: "var(--primary-orange)",
      float: "right",
    },
    logoImg: {
      float: "left",
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "left",
    },
    link: {
      color: "#000000",
      fontWeight: "bold",
    },

    mainDiv: {
      backgroundImage: `url(${BG})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    formDiv: {
      "& .MuiFormLabel-root": {
        color: "white",
        fontWeight: "600",
        marginBottom: "0.5rem",
      },
      "& .MuiTypography-body1": {
        color: "white",
      },
      "& .MuiInputLabel-root": {
        padding: "0px 10px ",
      },
      "& .MuiFormControl-root": {
        color: "white",
        backgroundColor: "#ffffff40",
        borderRadius: "5px",
      },
      "& .MuiCheckbox-root": {
        color: "white",
      },
      "& .MuiSvgIcon-root": {
        color: "white",
      },
      "& .MuiInputBase-input": {
        color: "white",
      },
      "& .Mui-error": {
        color: "#fff",
        "& :after": {
          borderBottomColor: "#fff",
        },
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "white",
        },
      },
      "& label.Mui-focused": {
        color: "white",
      },
      "& .MuiFormHelperText-root.Mui-error": {
        color: theme.palette.secondary.main,
      },
      "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.secondary.main} !important`,
      },
    },

    topDiv: {
      height: "1000px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
        margin: "0 10vw",
      },
    },

    signInDiv: {
      height: "1000px",
      paddingTop: "200px",
      backgroundImage: `url(${thankYouBG})`,
    },

    registrationDiv: {
      minHeight: "1000px",
      paddingBottom: "70px",
    },

    loginCard: {
      backgroundColor: "#ffffff !important",
      minWidth: "25vw",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: "#FFFFFF",
      borderRadius: "0 0 20px 20px",
      marginTop: "-15vh",
      [theme.breakpoints.down("sm")]: {
        width: "95vw",
        marginLeft: "-5vw",
        borderRadius: "0",
      },
      "@media screen and (max-width: 400px)": {
        width: "100vw",
      },
    },

    cardContainer: {
      padding: "4vw 2vw 4vw 2vw",
      [theme.breakpoints.down("sm")]: {
        padding: "4vw",
      },
    },

    cardLoyaltyText: {
      fontSize: "1rem",
      color: "#63656A",
      overflowWrap: "break-word",
      width: "500px",
      [theme.breakpoints.down("xs")]: {
        width: "80vw",
        margin: "auto",
      },
    },

    moreInfo: {
      backgroundColor: "#000000b3",
      width: "100%",
      position: "fixed",
      left: 0,
      bottom: "0",
      padding: "1rem",
      zIndex: 200000,
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        padding: "2%",
      },
    },

    moreInfoText: {
      cursor: "pointer",
      float: "right",
      color: "#d57f00",
    },

    loginBtn: {
      backgroundColor: "#d57f00",
      height: "55px",
      width: "99%",
      marginRight: "1%",
      "&:hover": {
        backgroundColor: "#d57f00",
      },
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        marginBottom: "0.5rem",
        marginLeft: "5%",
        marginRight: "5%",
      },
    },

    joinBtn: {
      backgroundColor: "#C2531B",
      height: "55px",
      width: "99%",
      marginLeft: "1%",
      "&:hover": {
        backgroundColor: "#C2531B",
      },
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        marginBottom: "0.5rem",
        marginLeft: "5%",
        marginRight: "5%",
      },
    },

    autoComplete: {
      width: "100%",
      "& .MuiFilledInput-root": {
        backgroundColor: "#63656A14",
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
        padding: "3px 0px 0 5px",
      },
      "& .MuiFilledInput-underline:before": {
        borderBottom: "none",
      },
      ".MuiAutocomplete-popper": {
        // marginTop : "10px !important",
        border: "1px solid black",
      },
    },

    linkStyle: {
      color: "#d57f00",
      fontSize: "15px",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },

    icon: {
      margin: "5px 3px -7px 0",
    },
    selectLang: {
      float: 'right',
      position: 'sticky',
      top: 0,
      zIndex: 9,
    },
    displayName: {
      padding: '0px 7px',
      color: "#000000",
      fontWeight: 600
    },
    changeLang: {
      backgroundColor: "white",
      color: "#d57f00",
      margin: "20px 5px",
      borderRadius: "5px",
      padding: "10px 30px",
      "&:hover": {
        backgroundColor: "#ffffff",
      },
    },
    dialogDiv: {
      background: "linear-gradient(#D57F00, #C2531B)",
      color: "white",
      padding: "20px",
    },
    cancelIcon: {
      cursor: "pointer",
      position: "absolute",
      right: 10,
      top: 8,
    },
    langBox: {
      padding: "30px",
      backgroundColor: "rgba(255,255,255,0.2)",
      margin: "15px",
      borderRadius: "10px",
      cursor: "pointer",
    },
    welcomeKit: {
      height: "11.5rem",
      width: "100%",
    },
    termsLink: {
      marginLeft: "0.5rem",
      color: theme.palette.primary.main,
    },
    registrationContainer: {
      backgroundColor: "rgba(0,0,0,0.8)",
      paddingTop: "20px",
      paddingBottom: "40px",
      borderRadius: "15px",
    },
    registrationTitleDiv: {
      margin: "4rem auto",
      height: "10rem",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        height: "fit-content",
      },
    },
    notificationContainer: {
      marginLeft: "0.8rem",
    },
    loyaltyText: {
      marginTop: "-130px",
      marginLeft: "60px",
      fontSize: "1.6rem",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
    addressField: {
      marginBottom: "-20px",
      [theme.breakpoints.down("sm")]: {
        margin: "1rem 0  0 0",
      },
    },
    stepContainer: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "1rem",
      },
    },
    stepContainerHeadline: {
      fontSize: "30px",
      color: "white",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    stepContainerText: {
      fontSize: "15px",
      color: "white",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    stepIconText: {
      fontSize: "15px",
      color: "#bbbbbb",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "6rem",
      },
    },
    stepCreditText: {
      fontSize: "15px",
      color: "#bbbbbb",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "1rem",
        textAlign: "center",
      },
    },
    stepCollectPoints: {
      fontSize: "15px",
      color: "#bbbbbb",
      overflowWrap: "break-word",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    languageChangeHeadline: {
      fontSize: "4rem",
      color: "white",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "3rem",
      },
    },
    chooseLanguage: {
      fontSize: "2rem",
      color: "white",
      textAlign: "center",

      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    rnc: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      maxWidth: "255px",
      backgroundColor: "#eee",
      borderRadius: "6px",
      padding: "10px",
      boxSizing: "border-box",
    },

    rncRow: {
      display: "flex",
      alignItems: "stretch",
      margin: "0 0 10px 0",
    },

    rncColumn: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      margin: "0 0 0 10px",
    },

    rncCanvas: {
      boxSizing: "border-box",
      backgroundColor: "#fff",
      borderRadius: "4px",
    },

    rncButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "16px",
      background: "#fff",
      color: "inherit",
      border: "none",
      padding: 0,
      width: "25px",
      height: "20px",
      boxSizing: "border-box",
      borderRadius: "4px",
    },

    "rncButton svg": {
      width: "1em",
      height: "1em",
      fill: "currentColor",
    },

    rncInput: {
      border: "none",
      padding: "10px 6px",
      fontSize: "inherit",
      fontFamily: "inherit",
    },
    title: {
      fontSize: "1.2rem",
      color: "#fff",
      fontWeight: "bold",
    },
    content: {
      fontSize: "1rem",
      color: "#fff",
    },
    phoneNumber: {
      width: "84%",
      float: "right",
    },
    phoneCode: {
      backgroundColor: "#ffffff40",
      color: "white",
      width: "13%",
      display: "inline-block",
      margin: "0",
      fontSize: "1rem",
      lineHeight: "1.43",
      letterSpacing: "0.01071em",
      height: "65%",
      borderRadius: "5px",
      marginRight: "3%",
      textAlign: "center",
      paddingTop: "3%",
    },
    contactDialogPaper: {
      maxHeight: "100vh",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "15px",
      width: "100%",
      padding: "2%",
      [tabScreen]: {
        padding: "1%",
      },
    },
    fieldsContainer: {
      overflow: "hidden",
      background: "#FFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 5px 5px #00000029",
      borderRadius: "10px",
      padding: "0.5rem",
      margin: "3%",
      "& .MuiInput-underline:after": {
        borderBottomColor: theme.palette.secondary.light,
      },
      [largeScreen]: {
        margin: "1.5%",
      },
    },
    dialogTitle: {
      fontSize: "1.2rem",
      color: theme.palette.primary.main,
      display: "inline-block",
      marginLeft: "0.5rem",
    },
    contactBtn: {
      color: "#fff",
      borderRadius: "20px",
      textDecoration: "none",
      width: "80%",
      margin: "0.5rem 10%",
      display: "block",
      fontSize: "1rem",
    },
    backNavigation: {
      marginLeft: "-1rem",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    label: {
      fontSize: "1rem",
      paddingTop: "5px",
    },
    contactDialogRoot: {
      padding: 0,
      [tabScreen]: {
        paddingLeft: "1%",
      },
    },
    mapImg: {
      display: "block",
      marginLeft: "1rem",
      height: "auto",
      width: "100%",
    },
    field: {
      padding: "0% 5%",
    },
    cancelButton: {
      color: "#000",
      borderRadius: "20px",
      textDecoration: "none",
      width: "80%",
      margin: "0.5rem 10%",
      display: "block",
      fontSize: "1rem",
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    buttonContainer: {
      padding: "1rem 0",
    },
    contactText: {
      padding: "1rem 5%",
    },
    contactUsTitle: {
      fontSize: "1.2rem",
      paddingLeft: "1%",
      [tabScreen]: {
        fontSize: "1.5rem",
      },
      [largeScreen]: {
        fontSize: "1.7rem",
      },
    },
    contactUsIcon: {
      width: "11%",
      height: "11%",
      paddingRight: "1%",
      [tabScreen]: {
        width: "8%",
        height: "8%",
      },
      [largeScreen]: {
        width: "4.5%",
        height: "4.5%",
        paddingRight: "0.1%",
      },
    },
    languageSelectPaper: {
      background: `url(${mapImg}) no-repeat center center fixed`,
      borderRadius: "12px",
      backgroundColor: theme.palette.primary.main,
      backgroundSize: "contain",
      width: "100%",
      height: "auto",
      padding: "5%",
      [largeScreen]: {
        height: "80vh",
        backgroundSize: "70%",
      },
    },
    countryButton: {
      color: "#fff",
      borderRadius: "20px",
      textDecoration: "none",
      width: "100%",
      display: "block",
      margin: "0.5rem auto",
      fontSize: "1.2rem",
      fontWeight: 600,
      borderColor: "white",
      [tabScreen]: {
        width: "50%",
      },
      [largeScreen]: {
        width: "30%",
      },
      "&.MuiButton-containedSecondary": {
        "&:hover": {
          background: "#333",
        },
      },
    },
    proceedButton: {
      color: theme.palette.primary.main,
      borderRadius: "20px",
      textDecoration: "none",
      width: "100%",
      display: "block",
      margin: "0.5rem auto",
      fontSize: "1.2rem",
      fontWeight: 600,
      borderColor: "white",
      [tabScreen]: {
        width: "50%",
      },
      [largeScreen]: {
        width: "30%",
        marginTop: "1rem",
      },
    },
    languageSelectText: {
      color: "#fff",
      textAlign: "center",
      fontSize: "1.2rem",
      fontWeight: 600,
      marginBottom: "1rem",
    },
  };
});
