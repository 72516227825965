import { Box, Dialog, Slide, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "state/store";

import amphochefMobileFrenchImg from "assets/images/amphochefMobileFrench.jpg";
import amphochefMobileSpanishImg from "assets/images/amphochefMobileSpanish.jpg";
import amphochefMobilePortugalImg from "assets/images/amphochefMobilePortugal.jpg";
import CancelIcon from "@material-ui/icons/Cancel";
import { showMobileQrDialog } from "state/actions/user/user.action";

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  paper: {
    width: "840px",
    height: "580px",
    overflow: "hidden",
  },
  cancelIcon: {
    position: "absolute",
    right: "0",
    cursor: "pointer",
  },
});

const MobileQRCodeDialog = () => {
  const openMobileQRCodeDialog = useSelector((state: RootState) => state.user.showMobileQRCodeDialog);
  const countryCode = useSelector((state: RootState) => state.user.countryCode);
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const handleClose = (): void => {
    dispatch(showMobileQrDialog(false));
  };
  const getPopupImage = () => {
    if (countryCode === "FRA") return amphochefMobileFrenchImg;
    else if (countryCode === "ESP") return amphochefMobileSpanishImg;
    else if (countryCode === "PRT") return amphochefMobilePortugalImg;
    return "";
  };

  return (
    <Dialog
      open={openMobileQRCodeDialog}
      TransitionComponent={Slide}
      maxWidth={"md"}
      keepMounted
      onClose={handleClose}
      aria-labelledby='Amphochef QR Code'
      aria-describedby='Amphochef QR Code for Apple Store and Play Store'
      classes={{ root: classes.root, paper: classes.paper }}
    >
      <CancelIcon color='primary' fontSize={"large"} className={classes.cancelIcon} onClick={handleClose} />
      <Box>
        <img src={getPopupImage()} alt='Amphochef QR Code for Apple and Play Store' height='100%' />
      </Box>
    </Dialog>
  );
};

export default MobileQRCodeDialog;
