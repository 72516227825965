import { useAppDispatch } from "state/store";
import { useOktaAuth } from "@okta/okta-react";
import { basketItemsActionCreator } from "state/actions/basket/basket.action";

const useLogout = () => {
  const dispatch = useAppDispatch();
  const { oktaAuth } = useOktaAuth();
  return async () => {
    oktaAuth.signOut();
    dispatch(basketItemsActionCreator([]));
    sessionStorage.removeItem("isMobileQRDialogDisplayed");
  };
};

export default useLogout;
