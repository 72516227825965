import {
  DISTRIBUTORS,
  NEW_INVOICES,
  ACCEPTED_INVOICES,
  REJECTED_INVOICES,
  PRODUCTS,
  PRODUCTS_CAPACITY_LIST,
  DISTRIBUTOR_REFERENCE_MAP,
  PRODUCT_REFERENCE_MAP,
  INVOICE_UPLOAD_ATTACHMENT_STATUS,
  INVOICE_ATTACHMENT_UPLOAD_PROGRESS,
  INVOICE_SUCCESS,
  INVOICE_FAILURE_MESSAGE,
  INVOICE_ATTCHMENT_NAME,
  INVALID_FILE,
  InvoiceActionType,
} from "state/actions/invoice/invoice.type";

import { DistributorDataI, invoiceState, ProductCapacityI } from "interface/invoice.model";

const INITIAL_STATE: invoiceState = {
  distributerList: [],
  distributorDispName: [],
  productList: [],
  productCapacityList: {} as ProductCapacityI,
  newInvoiceList: [],
  acceptedInvoiceList: [],
  rejectedInvoiceList: [],
  productReferenceMap: {},
  distributorData: [] as DistributorDataI[],
  invoicePdfName: "",
  invoicePdfUploadStatus: undefined,
  invoicePdfUploadProgress: 0,
  invoiceUploadStatus: undefined,
  invoiceSubmitFailureMessage: "",
  invalidFile: false,
  fileSrc: {},
};

export const invoiceReducer = (state: invoiceState = INITIAL_STATE, action: InvoiceActionType): invoiceState => {
  switch (action.type) {
    case DISTRIBUTORS:
      return { ...state, distributorDispName: action.payload };
    case NEW_INVOICES:
      return { ...state, newInvoiceList: action.payload };
    case ACCEPTED_INVOICES:
      return { ...state, acceptedInvoiceList: action.payload };
    case REJECTED_INVOICES:
      return { ...state, rejectedInvoiceList: action.payload };
    case PRODUCTS:
      return { ...state, productList: action.payload };
    case DISTRIBUTOR_REFERENCE_MAP:
      return { ...state, distributorData: action.payload };
    case PRODUCT_REFERENCE_MAP:
      return { ...state, productReferenceMap: action.payload };
    case PRODUCTS_CAPACITY_LIST:
      return { ...state, productCapacityList: action.payload };
    case INVOICE_ATTCHMENT_NAME:
      return { ...state, invoicePdfName: action.payload };
    case INVOICE_UPLOAD_ATTACHMENT_STATUS:
      return { ...state, invoicePdfUploadStatus: action.payload };
    case INVOICE_ATTACHMENT_UPLOAD_PROGRESS:
      return { ...state, invoicePdfUploadProgress: action.payload };
    case INVOICE_SUCCESS:
      return { ...state, invoiceUploadStatus: action.payload };
    case INVOICE_FAILURE_MESSAGE:
      return { ...state, invoiceSubmitFailureMessage: action.payload };
    case INVALID_FILE:
      return { ...state, invalidFile: action.payload };
    default:
      return state;
  }
};
