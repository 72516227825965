export const basket = {
  shopping_cart: {
    en: "Shopping Cart",
    fr: "Panier",
    es: "Carrito de compras",
    pt: "Página de encomenda",
  },
  check_out: {
    en: "Check Out",
    fr: "Récapitulatif",
    es: "Verificar",
    pt: "Verificar",
  },
  hi: {
    en: "Hi",
    fr: "Bonjour",
    es: "Hola",
    pt: "Oi",
  },
  thanks_shopping: {
    en: "Thank you for shopping with us",
    fr: "Merci pour votre commande",
    es: "Gracias por comprar con nosotros",
    pt: "Muito obrigado por comprar connosco",
  },
  thanks_text: {
    en: "Your order has been successfully placed and will be delivered to you in the near future.You can track the delivery status in the order history.",
    fr: "Votre commande a bien été enregistrée et vous sera livrée prochainement. Vous pouvez suivre le statut de la livraison dans la rubrique ",
    es: "Su pedido se ha realizado correctamente y se le entregará próximamente. Puede rastrear el estado de la entrega en el historial de pedidos.",
    pt: "A sua encomenda foi corretamente realizada e será entregue dentro em breve. Pode consultar o estado da entrega no histórico de encomendas.",
  },
  continue_shopping: {
    en: "Continue Shopping",
    fr: "Continuer les achats",
    es: "Seguir comprando",
    pt: "Continue comprando",
  },
  place_order: {
    en: "Place Order",
    fr: "Passer la commande",
    es: "Realizar pedido",
    pt: "Realizar encomenda",
  },
  confirmation: {
    en: "Confirmation",
    fr: "Confirmation de la commande",
    es: "Confirmación",
    pt: "Confirmação",
  },
  proceed: {
    en: "Proceed",
    fr: "Procéder",
    es: "Proceder",
    pt: "Continuar",
  },
  order_placed: {
    en: "Order Placed",
    fr: "Commande enregistrée",
    es: "Pedido Realizado",
    pt: "Encomenda realizada",
  },
  delivery_Address: {
    en: "Delivery Address",
    fr: "Adresse de livraison",
    es: "Seleccionar dirección de entrega",
    pt: "Selecionar endereço de entrega",
  },
  item: {
    en: "Item",
    fr: "Produit(s)",
    es: "Artículo",
    pt: "Artigo",
  },
  category: {
    en: "Category",
    fr: "Catégorie(s)",
    es: "Categoría",
    pt: "Categoria",
  },
  quantity: {
    en: "Quantity",
    fr: "quantité(s)",
    es: "Cantidad",
    pt: "Quantidade",
  },
  status: {
    en: "Status",
    fr: "Statut",
    es: "Estado",
    pt: "Estado",
  },
  in_stock: {
    en: "In stock",
    fr: "En stock",
    es: "En stock",
    pt: "Em estoque",
  },
  stock: {
    en: "Stock",
    fr: "Stock",
    es: "Stock",
    pt: "Stock",
  },
  delivered: {
    en: "Delivered",
    fr: "Livré(s)",
    es: "Enviado",
    pt: "Enviada",
  },
  my_orders: {
    en: "My Orders",
    fr: "Mes commandes",
    es: "Mis pedidos",
    pt: "As minhas encomendas",
  },
  order_summary: {
    en: "Order Summary",
    fr: "Résumé des commandes",
    es: "Resumen de la orden",
    pt: "Resumo do pedido",
  },
  order_no: {
    en: "Order Number",
    fr: "Numéro de commande",
    es: "Número de orden",
    pt: "As minhas encomendas",
  },
  est_delivery: {
    en: "Est delivery date",
    fr: "Date de livraison estimée",
    es: "fecha estimada de entrega",
    pt: "Data de Entrega Estimada",
  },
  shipping_detail: {
    en: "Shipping Detail",
    fr: "Détail de l'envoi",
    es: "Detalle de envio",
    pt: "Detalhes de envio",
  },
  redeemed_points: {
    en: "Redeemed points",
    fr: "Points utilisés",
    es: "Puntos canjeados",
    pt: "Pontos trocados",
  },
  remaining_points: {
    en: "Remaining points",
    fr: "Points restants",
    es: "Puntos restantes",
    pt: "Pontos restantes",
  },
  order_details: {
    en: "Order Details",
    fr: "Détails de la commande",
    es: "detalles del pedido",
    pt: "detalhes do pedido",
  },
  purchase_details: {
    en: "Purchase Details",
    fr: "Détails d'achat",
    es: "Detalles de la compra",
    pt: "Detalhes da compra",
  },
  summary: {
    en: "Summary",
    fr: "Récapitulatif",
    es: "Resumen",
    pt: "Resumo",
  },
  subtotal: {
    en: "SUBTOTAL",
    fr: "SOUS-TOTAL",
    es: "Total parcial",
    pt: "Total parcial",
  },
  my_points: {
    en: "My Points",
    fr: "Mes points",
    es: "Mis puntos",
    pt: "Os meus pontos",
  },
  next_step: {
    en: "Go to the next step",
    fr: "Aller à la prochaine étape",
    es: "Vaya al siguiente paso",
    pt: "Vaya al siguiente paso",
  },
};
