import { useSelector } from "react-redux";
import { RootState } from "state/store";

const useGiftsCountInCart = () => {
  const items: number = useSelector((state: RootState) =>
    state.cart?.cartItems?.length !== 0
      ? state.cart?.cartItems
          .map((a) => a.quantity)
          .reduce(function (a: number, b: number) {
            return Number(a) + Number(b);
          })
      : 0
  );
  return items;
};

export default useGiftsCountInCart;
