export const invoice_screen = {
  by_post: {
    en: "By Post",
    fr: "Par courrier",
    es: "Correo postal",
    pt: "Correio postal",
  },
  by_mail: {
    en: "By Mail",
    fr: "Par email",
    es: "Correo electrónico",
    pt: "Correio eletrónico",
  },
  by_upload: {
    en: "By Upload",
    fr: "Par téléchargement",
    es: "Cargar factura",
    pt: "Carregar fatura",
  },
  post_text: {
    en: "Send us the invoice on following postal address",
    fr: "Envoyez-nous vos factures à l'adresse suivante",
    es: "Compartir factura en esta dirección",
    pt: "Partilhar fatura neste endereço",
  },

  mail_text: {
    en: "Send the invoice to",
    fr: "Envoyez vos factures à",
    es: "Comparte la factura en",
    pt: "Partilha a fatura em",
  },
  send_email: {
    en: "Send Email",
    fr: "Envoyer un email",
    es: "Enviar correo electrónico",
    pt: "Enviar correio eletrónico",
  },
  upload_invoice: {
    en: "Upload Invoice",
    fr: "Télécharger la facture",
    es: "Subir factura",
    pt: "Carregar fatura",
  },
  invoice_number: {
    en: "Invoice Number",
    fr: "Numéro de la facture",
    es: "Número de factura",
    pt: "Número de fatura",
  },
  distributor_name: {
    en: "Distributor",
    fr: "Nom de distributeur",
    es: "nombre del distribuidor",
    pt: "Distribuidor",
  },
  list_products: {
    en: "List of Purchased Products",
    fr: "Liste des produits achetés",
    es: "Lista de productos comprados",
    pt: "Lista de produtos comprados",
  },
  amphora_products: {
    en: "Amphora Product",
    fr: "Produits Amphora",
    es: "Producto Amphora",
    pt: "Produto Amphora",
  },
  capacity: {
    en: "Capacity",
    fr: "Format",
    es: "Capacidad",
    pt: "Capacidade",
  },
  quantity: {
    en: "Quantity",
    fr: "Quantité",
    es: "Cantidad",
    pt: "Quantidade",
  },
  terms_conditions: {
    en: "Terms and conditions",
    fr: "Conditions et engagements",
    es: "Términos y condiciones",
    pt: "Termos e Condições",
  },
  history_text: {
    en: "Invoice History",
    fr: "Historique des factures",
    es: "Historial de facturas",
    pt: "Histórico de faturas",
  },
  invoice_date: {
    en: "Invoice Date",
    fr: "Date de la facture",
    es: "Fecha de factura",
    pt: "Data de fatura",
  },
  no: {
    en: "No.",
    fr: "Non",
    es: "No",
    pt: "Número",
  },
  invoice_data_success_snack: {
    en: "Invoice submitted successfully",
    fr: "Facture envoyée avec succès",
    es: "Factura enviada con éxito",
    pt: "Fatura carregada corretamente",
  },
  failed_invoice_message: {
    en: "Failed to submit :",
    fr: "L’envoi a échoué :",
    es: "No se pudo enviar",
    pt: "Falha ao enviar:",
  },
  invalid_file: {
    en: "Invalid File Uploaded",
    fr: "Fichier non valide téléchargé",
    es: "Se cargó un archivo no válido",
    pt: "Arquivo inválido enviado",
  },
  no_invoices_to_display: {
    en: "No invoices to show",
    fr: "Aucune facture",
    es: "Sin factura",
    pt: "Sem faturas",
  },
  rejection_comments: {
    fr: "Raison du refus",
    en: "Rejection Comments",
    es: "Motivo del rechazo",
    pt: "Razão para rejeição",
  },
  disclaimer: {
    en: "Disclaimer",
    fr: "Important",
    es: "Descargo de responsabilidad",
    pt: "Isenção de responsabilidade",
  },
  disclaimerText: {
    en: "For hassle free services, we request you to upload the clear image or pdf of your Amphora product purchase invoice containing all the below information- ",
    fr: "Merci de bien vous assurez que les éléments ci-après soient bien lisibles sur le facture transmise:",
    es: "Para servicios sin complicaciones, le solicitamos que cargue la imagen clara o el pdf de la factura de compra de su producto Amphora que contiene toda la información siguiente:",
    pt: "Para serviços sem complicações, pedimos que você carregue uma imagem clara ou pdf de sua fatura de compra do produto Amphora contendo todas as seguintes informações:",
  },
  email_disclaimer: {
    en: "Please make sure that the following elements are clearly legible on the transmitted invoice -",
    fr: "Merci de bien vous assurez que les éléments ci-après soient bien lisibles sur le facture transmise:",
    es: "Asegúrese de que los siguientes elementos sean claramente legibles en la factura transmitida",
    pt: "Certifique-se de que os seguintes itens estejam claramente legíveis na fatura transmitida",
  },
  name_and_address: {
    en: "Your name and address of the restaurant",
    fr: "Nom et adresse du restaurant",
    es: "nombre y dirección del restaurante",
    pt: "nome e endereço do restaurante",
  },
  product_and_quantity: {
    en: "Amphora product and quantity details",
    fr: "Nom et quantité du/des produit(s) Amphora",
    es: "Detalles del producto Amphora y cantidad",
    pt: "Detalhes e quantidade do produto Ânfora",
  },
  attach_file: {
    en: "Attach a file",
    fr: "Joinder un finchier",
    es: "Adjuntar un archivo",
    pt: "Anexar um arquivo",
  },
  file: {
    en: "File",
    fr: "Déposer",
    es: "el expediente",
    pt: "Arquivo",
  },
  date: {
    en: "Date",
    fr: "Date",
    es: "Fecha",
    pt: "Encontro",
  },
};
