import { makeStyles, Theme } from "@material-ui/core/styles";

export const OfferCardStyles = makeStyles((theme: Theme) => {
  const tabScreen = theme.breakpoints.down("md");
  const mobileScreen = theme.breakpoints.down("xs");
  return {
    mainBox: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      height: "44vh",
      padding: "2% 0%",
      borderRadius: "12px",
      width: "100%",
      [tabScreen]: {
        height: "23vh",
        borderRadius: "12px",
      },
      [mobileScreen]: {
        height: "34vh",
      },
    },

    contentBox: {
      paddingLeft: "8%",
      animation: `$myEffect 0.5s ease-in-out`,
      zIndex: 900,
      whiteSpace: "pre-line",
      [mobileScreen]: {
        paddingLeft: "2%",
      },
      [tabScreen]: {
        paddingLeft: "8%",
      },
    },
    imageBox: {
      animation: `$myEffect 0.8s ease-in-out`,
      [tabScreen]: {
        paddingRight: "0%",
      },
      [mobileScreen]: {
        paddingLeft: "2%",
      },
    },
    offerImage: {
      display: "block",
      marginLeft: "0",
      height: "auto",
      width: "100%",
      marginRight: "auto",
    },

    rightIndex: {
      transform: "rotate(90deg)",
      marginBottom: "15px",
    },
    divider: {
      height: "25px",
      backgroundColor: "black",
      marginBottom: "15px",
    },

    "@keyframes myEffect": {
      "0%": {
        opacity: 0.6,
      },
      "100%": {
        opacity: 1,
      },
    },
    title: {
      fontSize: "45px",
      fontWeight: "bold",
      lineHeight: "70px",
      marginBottom: 1,
      textAlign: "left",
      [tabScreen]: {
        fontSize: "24px",
        fontWeight: "bold",
        lineHeight: "30px",
      },
      [mobileScreen]: {
        fontSize: "18px",
      },
    },
    description: {
      fontSize: "18px",
      lineHeight: "24px",
      color: "#000",
      marginBottom: 2,
      [tabScreen]: {
        fontSize: "12px",
        lineHeight: "18px",
      },
    },
  };
});

export const giftCardStyles = makeStyles((theme: Theme) => {
  const tabScreen = theme.breakpoints.down("md");
  const mobileScreen = theme.breakpoints.down("xs");
  return {
    giftcard: {
      position: "relative",
      padding: "8%",
      backgroundColor: "#fff",
      borderRadius: "12px",
      boxShadow: "0px 0px 15px #00000029",
      transition: "transform 500ms ease",
      margin: "auto",
    },

    title: {
      height: "3.7rem",
      position: "relative",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      marginBottom: "1rem",
      fontWeight: 800,
      [mobileScreen]: {
        fontSize: "1.75rem",
        height: "4.5rem",
      },
      "@media screen and (max-height:768px) and (min-width:1366px)": {
        fontSize: "1.5rem",
      },
      [tabScreen]: {
        fontSize: "1.6rem",
      },
    },
    brand: {
      height: "3.7rem",
      position: "relative",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      marginBottom: "1rem",
      [mobileScreen]: {
        fontSize: "1.4rem",
        height: "4.5rem",
      },
      "@media screen and (max-height:768px) and (min-width:1366px)": {
        fontSize: "1.2rem",
      },
      [tabScreen]: {
        fontSize: "1.2rem",
      },
    },

    description: {
      height: "3rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
    productQuantity: {
      color: "red",
      opacity: "1",
      marginTop: "1rem",
      borderTop: "1px solid red",
      borderBottom: "1px solid red",
      "@media screen and (max-height:768px) and (min-width:1366px)": {
        fontSize: "0.8rem",
      },
    },
    buttonBox: {
      left: "0px",
      bottom: "0px",
      width: "100%",
      marginTop: "20px",
    },
    addToCartButton: {
      width: "fit-content",
      borderRadius: "5px",
      fontWeight: 600,
    },
    pointsBox: {
      flexGrow: 1,
      width: "40%",
      color: "black",
      textAlign: "center",
      float: "right",
      justifyContent: "flex-end",
    },
    imgBox: {
      cursor: "pointer",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      height: "10rem",
      [mobileScreen]: {
        height: "10rem",
      },
    },

    pointsCartBox: {
      marginTop: "5%",
    },
    productQuantityBox: {
      height: "3rem",
    },
  };
});

export const useStyles = makeStyles((theme: Theme) => {
  const tabScreen = theme.breakpoints.down("md");
  const mobileScreen = theme.breakpoints.down("xs");
  return {
    // landing styles
    landingBox: {
      marginTop: "10rem",
      paddingLeft: "6%",
      paddingRight: "6%",
      [tabScreen]: {
        marginTop: "145px",
      },
      [mobileScreen]: {
        marginTop: "135px",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },

    spaceBox: {
      border: "1px solid #fff",
    },

    homeContainer: {
      width: "100%",
      minHeight: "calc(100vh - 155px)",
      overflow: "hidden",
    },

    // Top layout
    invoiceButton: {
      height: "67px",
      width: "170px",
    },
    myPointsDiv: {
      height: "67px",
      paddingRight: "40px",
      paddingLeft: "40px",
      border: "0.1px solid #707070",
      borderRadius: "3px",
      boxShadow: theme.shadows[0],
    },

    // home page filter classes
    filtersBox: {
      paddingLeft: "6% !important",
      paddingRight: "6% !important",
      // display: 'flex',
      "& .MuiAccordionSummary-content": {
        justifyContent: "flex-end",
      },
      "& .MuiAccordionSummary-root": {
        padding: "0px",
      },
      "& .MuiAccordionDetails-root": {
        padding: "0px",
      },
    },

    searchDiv: {
      display: "flex",
      justifyContent: "space-between",
      "& .MuiInputBase-root": {
        borderRadius: "5px",
      },
    },

    filterDiv: {
      border: "0.2px solid  #707070",
    },
    filterEleDiv: {
      width: "20%",
      overflowY: "hidden",
      whiteSpace: "nowrap",
      "& .MuiInputBase-root": {
        maxHeight: "40px",
      },
    },
    filterButtonDiv: {
      width: "30%",
    },
    valueDiv: {
      width: "40%",
    },
    searchButton: {
      ...theme.typography.button,
      textTransform: "none",
      borderRadius: "30px",
      width: "85%",
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      [tabScreen]: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
      },
      [mobileScreen]: {
        marginLeft: "2px",
        fontSize: "1rem",
        width: "100%",
        marginTop: "1rem",
      },
    },

    resetButton: {
      ...theme.typography.button,
      textTransform: "none",
      borderRadius: "30px",
      width: "85%",
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      backgroundColor: "#fff",
      [tabScreen]: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
      },
      [mobileScreen]: {
        marginLeft: "2px",
        fontSize: "1rem",
        width: "100%",
        marginTop: "1rem",
      },
    },

    // Filtered product cards
    productCard: {
      backgroundColor: "#EBEBEB",
      height: "477px",
      padding: "2%",
      width: "48%",
    },
    pointsBox: {
      width: "100%",
      backgroundColor: "white",
      borderRadius: 0,
      fontWeight: "bold",
      fontSize: "14px",
      // flexGrow : 1
    },
    addtoCartButton: {
      width: "100%",
      borderRadius: 0,
    },
    paginationBox: {
      paddingTop: "3%",
    },

    // Offers and promotions
    cardsFlexBox: {
      height: "",
    },
    cardBox: {
      minHeight: "230px",
      width: "30%",
      backgroundColor: theme.palette.primary.light,
    },
    leftBox: {
      display: "flex",
      padding: "7%",
      justifyContent: "center",
      alignItems: "center",
    },
    rightBox: {
      paddingTop: "15%",
      paddingRight: "5%",
    },
    searchContainer: {
      background: `#FFC442 0% 0% no-repeat padding-box`,
      // boxShadow: "0px 0px 12px #00000029",
      borderRadius: "12px",
      width: "99.4%",
      padding: "2rem 1rem",
      margin: "2% 0.4% 2% 0.4%",
      [tabScreen]: {
        boxShadow: "0px 0px 4px #00000029",
        width: "99%",
        margin: "2% 0.4% 8% 0.4%",
      },
    },
    searchTextBox: {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      padding: "10px",
      borderRadius: "30px",
      boxShadow: "inset 0px 0px 12px #00000029;",
      width: "95%",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    filtersContainer: {
      width: "95%",
      marginTop: "25px",
      "@media screen and (max-height:768px) and (min-width:1366px)": {
        marginTop: "25px",
      },
    },
    slider: {
      marginTop: "20px",
      [tabScreen]: {
        marginTop: "10px",
      },
      [mobileScreen]: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        width: "100%",
        marginTop: "30px",
      },
      "@media screen and (max-width:1366px)": {
        marginTop: "15px",
      },
    },
    sliderText: {
      marginTop: "-25px",
      textAlign: "center",
      color: "#000",
      [tabScreen]: {
        marginTop: "-25px",
      },
      [mobileScreen]: {
        marginTop: "-25px",
      },
    },
    filterBtnContainer: {
      marginTop: "10px",
      [tabScreen]: {
        marginTop: "10px",
      },
    },
    giftCardContainer: {
      background: "#FFC442 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 12px #00000029",
      borderRadius: "12px",
      margin: "1% auto",
      width: "99.4%",
      padding: "1.1%",
      [tabScreen]: {
        boxShadow: "0px 0px 4px #00000029",
        width: "99%",
        margin: "2% 0.4% 8% 0.4%",
      },
      [mobileScreen]: {
        borderRadius: "12px",
      },
    },
    noItemsIcon: {
      width: "100px",
      height: "100px",
      marginBottom: "20px",
    },
    outerContainer: {
      background: "transparent linear-gradient(to top, #FFE147B3 0%, #FFFFFF 20%) 0% 0% no-repeat padding-box",
      opacity: 1,
      paddingTop: "2rem",
      paddingBottom: "2rem",
    },
  };
});
