import { GiftsState, GiftImageI } from "interface/gift.model";

// product action types
export const GIFTS = "gifts";
export const GIFTS_IMG_SRC = "gifts_img_src";
export const GIFTS_IND = "gifts_ind";

interface GiftItemActionType {
  type: typeof GIFTS;
  payload: GiftsState;
}

interface GiftsImageSrcActionType {
  type: typeof GIFTS_IMG_SRC;
  payload: GiftImageI;
}

interface GiftsIndActionType {
  type: typeof GIFTS_IND;
  payload: number;
}

export type GiftsActionType =
  | GiftItemActionType
  | GiftsImageSrcActionType
  | GiftsIndActionType;
