import { makeStyles, Theme } from "@material-ui/core/styles";
import machanismBackground from "assets/images/mechanismBackground.png";

export const useStyles = makeStyles(
  (theme: Theme) => {
    const tabScreen = theme.breakpoints.down("sm");
    const mobileScreen = theme.breakpoints.down("xs");
    const largeScreen = theme.breakpoints.up("md");
    const extraLargeScreen = theme.breakpoints.up("xl");

    return {
      stripContainer: {
        height: "40px",
        minHeight: "40px",
        background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
        borderRadius: "0px 0px 30px 30px",
        "& .MuiInputBase-input": {
          color: "white",
        },
        "& .MuiSelect-icon": {
          color: "white",
        },
        "& .MuiInput-underline:before": {
          borderBottom: "0px solid white",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
          borderBottom: "0px solid white",
        },
        "& .MuiInput-underline:after": {
          borderBottom: "0px",
        },
        [tabScreen]: {
          height: "40px",
          borderRadius: "0",
          minHeight: "25px",
        },
        [mobileScreen]: {
          height: "25px",
          minHeight: "20px",
        },
      },
      stripLeftSection: {
        marginLeft: "100px",
        height: "100%",
        width: "300px",
      },
      stripRightSection: {
        padding: "0 6.1%",
        height: "100%",
      },
      select: {
        paddingInline: "4px",
        fontSize: "18px",
        height: "100%",
        "& .MuiSelect-select:focus": {
          backgroundColor: "transparent",
        },
      },
      callUs: {
        flexGrow: 1,
        color: "white",
      },
      showMechanism: {
        height: "100%",
        margin: "7px 32px 5px 5px",
        [tabScreen]: {
          display: "block",
          margin: "auto 30px",
        },
      },
      link: {
        color: "white",
        fontSize: "18px",
        cursor: "pointer",
        textAlign: "center",
        letterSpacing: "1.2px",
        [tabScreen]: {
          fontSize: "14px",
        },
      },
      phone: {
        height: "100%",
        textAlign: "center",
        marginRight: "20px",
      },
      pointsBox: {
        width: "200px",
        background: `${theme.palette.secondary.main} 0% 0% no-repeat padding-box`,
        borderRadius: "0px 0px 20px 20px",
        height: "98%",
        verticalAlign: "center",
        // margin: "5px 20px 5px 5px",
      },

      pointsText: {
        fontFamily: "Helvetica",
        fontSize: "18px",
        letterSpacing: "1.2px",
        textAlign: "center",
        height: "100%",
        paddingTop: "7px",
        [mobileScreen]: {
          paddingTop: "0",
        },
      },

      headerLogoutText: {
        color: "white",
        fontSize: "18px",
        cursor: "pointer",
        textAlign: "left",
        letterSpacing: "1.2px",
        paddingTop: "7px",
      },
      appBar: {
        backgroundColor: "white",
        color: "black",
      },

      navbarContainer: {
        height: "90px",
        width: "100%",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "0px 0px 25px 25px",
        padding: "0 5.5% 0 5.7%",
        [tabScreen]: {
          borderRadius: 0,
          height: "95px",
        },
        [mobileScreen]: {
          height: "75px",
        },
      },

      iconContainer: {
        margin: "15px 20px 0 30px",
        cursor: "pointer",
        [tabScreen]: {
          margin: "10px 20px 0 0px",
        },
        [mobileScreen]: {
          margin: "10px 0px 0 0px",
        },
      },
      icon: {
        [largeScreen]: {
          height: 60,
        },
        [tabScreen]: {
          height: 55,
        },
        [mobileScreen]: {
          height: 38,
        },
      },
      referIcon: {
        [largeScreen]: {
          height: 38,
        },
        [tabScreen]: {
          height: 30,
        },
        [mobileScreen]: {
          height: 20,
        },
      },
      searchBox: {
        width: "25%",
        marginTop: "20px",
        marginRight: "20px",
        backgroundColor: "#D1D0CF24",
        borderRadius: "12px",
      },
      input: {
        paddingLeft: "20px",
        flexGrow: 1,
      },
      searchIcon: {
        paddingRight: "20px",
      },
      navigationEleContainer: {
        width: "70%",
        height: "100%",
      },
      navEle: {
        height: "100%",
        paddingRight: "50px",
        paddingLeft: "20px",
      },

      navlink: {
        textDecoration: "none",
        color: "white",
        fontSize: "18px",
        fontFamily: "Helvetica",
        letterSpacing: "1px",
        "&.active": {
          borderBottom: `7px solid ${theme.palette.secondary.light}`,
          paddingBottom: "14px",
        },
        "&.active p": {
          color: theme.palette.primary.main,
        },
        "&.active .MuiBadge-root .MuiBadge-badge": {
          visibility: "hidden",
        },
      },
      dialogDiv: {
        padding: "2rem 1rem",
        [tabScreen]: {
          padding: "3rem 2rem",
        },
        [largeScreen]: {
          padding: "3rem 2rem",
        },
      },
      line: {
        border: "1px solid lightgrey",
      },
      gridItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      },
      border: {
        borderBottom: "1px solid lightgrey",
        height: "50%",
      },

      mechanismBackgroundStyles: {
        background: `url(${machanismBackground}) no-repeat center center fixed`,
        backgroundSize: "cover",
        borderRadius: "10px",
      },
      supportSection: {
        fontSize: "0.8rem",
        marginTop: "1rem",
      },

      languageIcon: {
        margin: "7px 5px 5px 5px",
      },

      navbarPointsText: {
        color: theme.palette.primary.main,
        fontFamily: "Helvetica",
        fontSize: "18px",
        textAlign: "center",
      },
      mobileLangSelector: {
        padding: "0.5rem",
      },
      mobileLangPaper: {
        borderRadius: "0.5rem",
      },
      cartPopoverContainer: {
        padding: "5%",

        [tabScreen]: {
          padding: "8%",
        },
      },
      giftTitle: {
        fontSize: "1.3rem",
        // overflowWrap: "break-word",
        // wordWrap: "break-word",
      },
      cartContainerPaper: {
        borderRadius: "12px",
        border: "1px solid #707070",
        boxShadow: "0px 0px 10px #00000029",
        position: "relative",
        [tabScreen]: {
          width: "calc(100vw - 40%)",
        },
        [mobileScreen]: {
          width: "calc(100vw - 25%)",
          maxHeight: "calc(100vh - 20%)",
          overflowY: "scroll",
        },
        [largeScreen]: {
          width: "calc(100vw - 50%)",
        },
        [extraLargeScreen]: {
          width: "calc(100vw - 70%)",
        },
      },
      giftPoints: {
        color: "#D5012C",
      },
      viewCartContainer: {
        position: "sticky",
        backgroundColor: theme.palette.secondary.light,
        height: "4rem",
        bottom: "0px",
        marginBottom: "10px",
      },
      viewCartBtn: {
        color: "#fff",
        position: "sticky",
        width: "100%",
        // height: "4rem",
        bottom: "0px",
        left: "0",
        marginBottom: "10px",
        borderRadius: "20px",
      },
      logo: {
        cursor: "pointer",
        width: "100%",
        height: "auto",
        display: "block",

        [tabScreen]: {
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
      cancelIcon: {
        position: "absolute",
        right: "0px",
        top: "0px",
        cursor: "pointer",
        [tabScreen]: {
          right: "5px",
          top: "5px",
        },
        [largeScreen]: {
          right: "10px",
          top: "10px",
        },
      },
      productsTitle: {
        fontSize: "1.2rem",
        textAlign: "left",
        fontWeight: 600,
        marginBottom: "1rem",
      },
      pointsTitle: {
        fontSize: "1.2rem",
        textAlign: "center",
        fontWeight: 600,
        marginBottom: "1rem",
      },
      productName: {
        fontSize: "0.9rem",
        textAlign: "left",
      },
      productPoints: {
        fontSize: "0.9rem",
        textAlign: "right",
      },
      productRow: {
        marginBottom: "0.5rem",
      },
      referBox: {
        background: `${theme.palette.secondary.main} 0% 0% no-repeat padding-box`,
        borderRadius: "20px",
        height: "95%",
        padding: "0.25rem 1.5rem",
        marginLeft: "0.5rem",
        marginTop: "1px",
        cursor: "pointer",
        marginRight: "0.5rem",
      },
      referText: {
        textAlign: "center",
        height: "100%",
        paddingLeft: "7px",
        paddingBottom: "10px",
      },
      referPopoverContainer: {
        borderRadius: "10px",
        background: `${theme.palette.secondary.main} 0% 0% no-repeat padding-box`,
        width: "400px",
        padding: "1rem",
        boxShadow: "none",
        marginTop: "-10px",
        marginLeft: "-1rem",
      },
      referContent: {
        fontSize: "0.9rem",
      },
      referralCodeContainer: {
        color: "white",
        background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
        borderRadius: "10px",
        padding: "0.25rem 0.5rem",
        marginTop: "1rem",
      },
      referralCode: {
        fontSize: "0.9rem",
        color: "white",
        marginRight: "1rem",
        [mobileScreen]: {
          marginRight: "0.5rem",
        },
      },
      copyIcon: {
        width: "22px",
        height: "22px",
        marginLeft: "5px",
      },
      toolTip: {
        fontSize: "0.9rem",
        borderRadius: "10px",
        background: `${theme.palette.secondary.main} 0% 0% no-repeat padding-box`,
        color: "#000",
        padding: "5px",
      },
      logoContainer: {
        padding: "1%",
        marginLeft: "10px",
        [tabScreen]: {
          marginLeft: "0%",
        },
        [mobileScreen]: {
          marginLeft: "0px",
        },
      },
      basketIconContainer: {
        margin: "15px 20px 0 30px",
        cursor: "pointer",
        [tabScreen]: {
          margin: "10px 0px 0 0px",
          marginLeft: "10%",
        },
        [mobileScreen]: {
          margin: "5px 0px 0 0px",
        },
      },
      phoneIcon: {
        color: theme.palette.primary.main,
        fontSize: "2rem",
        background: "white",
        padding: "2px",
        borderRadius: "50%",
      },

      headerIcons: {
        width: "32px",
        height: "32px",
        marginRight: "5px",
        marginTop: "7px",
        paddingLeft: "2px",
      },
      headerText: {
        fontSize: "1rem",
      },
      headerLowerText: {
        fontSize: "1rem",
        fontWeight: "bold",
      },

      headerSectionGrid: {
        margin: "auto",
      },
      logoutSection: {
        background: theme.palette.primary.dark,
        position: "relative",
        zIndex: 90,
        cursor: "pointer",
      },
      myPointsSection: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      logoutText: {
        color: "white",
      },
      mobileSection: {
        position: "absolute",
        width: "100%",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "0 0 20px 0",
        boxShadow: "0px 2px 6px #00000045",
        marginBottom: "1px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        maxWidth: "88%",
        padding: "10px",
        zIndex: 91,
        cursor: "pointer",
        [largeScreen]: {
          paddingRight: "1%",
        },
        "@media screen and (min-width:1500px)": {
          paddingRight: "2%",
        },
      },
      referSection: {
        position: "absolute",
        width: "100%",
        backgroundColor: "#fcbd30",
        borderRadius: "0 0 20px 0",
        boxShadow: "0px 0px 6px #00000045",
        marginBottom: "1px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        maxWidth: "73%",
        padding: "10px",
        zIndex: 92,
        cursor: "pointer",
        [largeScreen]: {
          paddingRight: "4%",
        },
      },
      mechanismSection: {
        position: "absolute",
        width: "100%",
        backgroundColor: "#fcbd30",
        borderRadius: "0 0 20px 0",
        boxShadow: "0px 0px 6px #00000045",
        marginBottom: "1px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "10px",
        maxWidth: "58%",
        zIndex: 93,
        cursor: "pointer",
        [largeScreen]: {
          paddingRight: "4%",
        },
      },
      logoSection: {
        position: "absolute",
        width: "100%",
        backgroundColor: "white",
        borderRadius: "0 0 20px 0",
        marginBottom: "1px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "10px",
        maxWidth: "43%",
        zIndex: 93,
        paddingRight: 0,
        boxShadow: "0px 0px 6px #00000045",
        paddingLeft: "5.5%",
      },
      nonLargeScreenHeader: {
        height: "20px",
        backgroundColor: theme.palette.primary.main,
      },
      wideNavbarContainer: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "0px 0px 25px 25px",
        padding: "0 5.5% 0 5.7%",
      },
      badgeColor: {
        backgroundColor: "#fff",
      },
    };
  },
  { index: 1 }
);
