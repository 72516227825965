import {
  BASKET_ITEMS,
  BASKET_ITEM_IMG_SRC,
  UPDATE_ARTICLE_IN_BASKET,
  REMOVE_ARTICLE_FROM_BASKET,
  UPDATE_POINTS_IN_BASKET,
  UPDATE_BASKET_ADDRESS_ID,
  BasketActionType,
  QUANTITY_ERROR,
} from "state/actions/basket/basket.type";
import { BasketState } from "interface/basket.model";

const intialState: BasketState = {
  cartItems: [],
  points: 0,
  userAddressId: 0,
  productQuantityError: {
    msg: undefined,
    flag: undefined,
  },
};

export const basketReducer = (state: BasketState = intialState, action: BasketActionType): BasketState => {
  switch (action.type) {
    case BASKET_ITEMS: {
      return {
        ...state,
        cartItems: action.payload,
      };
    }
    case BASKET_ITEM_IMG_SRC: {
      return {
        ...state,
        cartItems: state.cartItems.map((item) => {
          if (item.basketId === action.payload.basketId) {
            return {
              ...item,
              image1src: action.payload.src,
            };
          }
          return item;
        }),
      };
    }
    case QUANTITY_ERROR: {
      return {
        ...state,
        productQuantityError: action.payload,
      };
    }
    case REMOVE_ARTICLE_FROM_BASKET: {
      let cartItems = [...state.cartItems];
      cartItems = cartItems.filter((item) => item.basketId !== action.payload);
      return {
        ...state,
        cartItems: cartItems,
      };
    }
    case UPDATE_ARTICLE_IN_BASKET: {
      return {
        ...state,
        cartItems: state.cartItems.map((item) => {
          if (item.basketId === parseInt(action.payload.basketId)) {
            return { ...item, quantity: action.payload.quantity, points: action.payload.points };
          }
          return item;
        }),
      };
    }
    case UPDATE_POINTS_IN_BASKET: {
      return {
        ...state,
        points: action.payload,
      };
    }
    case UPDATE_BASKET_ADDRESS_ID: {
      return {
        ...state,
        userAddressId: action.payload,
      };
    }
    default:
      return state;
  }
};
