import axios from "axios";
import { authHeader, BASE_URL } from "config";
import { showLoaderActionCreator } from "state/actions/home/home.action";
import store from "state/store";
let currentApiCount = 0;

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
});
let token = { ...authHeader() };

const HandleLoader = (isApiTriggered: boolean) => {
  isApiTriggered ? currentApiCount++ : currentApiCount--;
  currentApiCount > 0 ? store.dispatch(showLoaderActionCreator(true)) : store.dispatch(showLoaderActionCreator(false));
};

const getToken = () => {
  if (token?.Authorization) return token;
  else return { ...authHeader() };
};

axiosInstance.interceptors.request.use(
  (config) => {
    HandleLoader(true);
    config.headers = getToken();
    return config;
  },
  (error) => {
    HandleLoader(false);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    HandleLoader(false);
    return response;
  },
  (error) => {
    HandleLoader(false);
    return Promise.reject(error);
  }
);
