export const orders = {
  active: {
    fr: "En cours",
    en: "ACTIVE",
    es: "Activo",
    pt: "Activo",
  },
  complete: {
    fr: "Finalisées",
    en: " COMPLETED ",
    es: "Completado",
    pt: "Completado",
  },
  order_id: {
    fr: "Numéro de commande",
    en: "ORDER ID",
    es: "Número de pedido",
    pt: "Número de pedido",
  },

  delivery_date: {
    fr: "Date de livraison",
    en: "Delivery Date",
    es: "Fecha de entrega",
    pt: "Data de entrega",
  },
  status: {
    fr: "Statut",
    en: "Status",
    es: "Estado",
    pt: "Estado",
  },
  in_progress: {
    fr: "En cours",
    en: "In-progress",
    es: "En curso",
    pt: "Em curso",
  },
  delivered: {
    fr: "Livré",
    en: "Delivered",
    es: "Enviado",
    pt: "Enviada",
  },
  ship_to: {
    fr: "Envoyé à l’adresse suivante",
    en: "Ship To",
    es: "Enviar a",
    pt: "Enviar para",
  },
  reason: {
    en: "Reason",
    fr: "La raison",
    es: "Razón",
    pt: "Razão",
  },

  new: {
    fr: "nouveau",
    en: "New",
    es: "Nuevo",
    pt: "Nova",
  },
  accepted: {
    fr: "acceptée",
    en: "Accepted",
    es: "Aceptado",
    pt: "Aceitaram",
  },
  declined: {
    fr: "rejetée",
    en: "Declined",
    es: "Declinado",
    pt: "Declinada",
  },
  no_of_items: {
    en: "Number of Items",
    fr: "Nombre d'objets",
    es: "Número de items",
    pt: "Número de ítens",
  },
  total_points: {
    en: "Total Debited Points",
    fr: "Total des points débités",
    es: "Total de puntos debitados",
    pt: "Total de pontos debitados",
  },
  order_cancel: {
    en: "Are you sure to cancel this order",
    fr: "Êtes-vous sûr d'annuler cette commande",
    es: "¿Estás seguro de cancelar este pedido?",
    pt: "Tem certeza de que deseja cancelar este pedido?",
  },
  order_cancel_success: {
    en: "Order cancelled successfully",
    fr: "Commande annulée avec succès",
    es: "Pedido cancelado correctamente",
    pt: "Pedido cancelado com sucesso",
  },
  order_cancel_error: {
    en: "Order cancel failed",
    fr: "Échec de l'annulation de la commande",
    es: "No se pudo cancelar el pedido",
    pt: "Não foi possível cancelar o pedido",
  },
  view_order_details: {
    en: "View Order Details",
    fr: "Voir d'autres détails",
    es: "Ver detalles de la orden",
    pt: "Ver detalhes do pedido",
  },
};
