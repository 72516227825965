import { makeStyles } from "@material-ui/core/styles";

export const footerStyles = makeStyles((theme) => {
  const tabScreen = theme.breakpoints.up("sm");
  const largeScreen = theme.breakpoints.up("md");
  // const extraLargeScreen = theme.breakpoints.up("lg");
  return {
    outerDiv: {
      background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
      boxShadow: "0px 3px 6px #00000029",
      marginTop: "5px",
      padding: "10px",
      [largeScreen]: {
        padding: "1% 6%",
      },
    },

    font1: {
      fontSize: "1.6rem",
      marginBottom: "10px",
      color: "white",
    },

    font3: {
      cursor: "pointer",
      color: "#fff",
      fontSize: "1rem",
      marginLeft: "0.5rem",
      opacity: 1,
      "&:active &:visited": {
        color: "black",
      },
      [largeScreen]: {
        fontSize: "1.1rem",
        marginLeft: "1rem",
      },
    },
    address: {
      cursor: "pointer",
      color: "#fff",
      opacity: 1,
      fontSize: "1rem",
      "&:active &:visited": {
        color: "black",
      },
      [largeScreen]: {
        fontSize: "1.1rem",
      },
    },

    listDiv: {
      marginTop: "1rem",
      marginBottom: "1rem",
      [largeScreen]: {
        marginTop: "3rem",
      },
    },

    copyright: {
      textAlign: "right",
      font: "normal normal normal 12px/14px Helvetica",
      color: "#000",
    },

    bottomDiv: {
      padding: "0.5rem 0 2rem",
    },

    link: {
      whiteSpace: "nowrap",
      color: "#fff",
      textDecoration: "none",
      opacity: 1,
    },

    dialogDiv: {
      margin: "2rem 2rem 0 2rem",
    },
    copyRightContainer: {
      width: "90%",
      margin: "20px auto",
    },
    mt10: {
      marginTop: "10px",
    },
    copyrightText: {
      fontSize: "1rem",
      [tabScreen]: {
        fontSize: "1.1rem",
      },
    },
    emailId: {
      color: "white",
      fontSize: "0.8rem",
      wordBreak: "break-all",
      margin: "1rem 0",
      textDecoration: "underline",
      opacity: 1,
      [largeScreen]: {
        fontSize: "0.9rem",
      },
    },
    serviceRequestContainer: {
      borderRadius: "10px",
    },
    cancelIcon: {
      position: "absolute",
      right: "0px",
      top: "0px",
      cursor: "pointer",
      [tabScreen]: {
        right: "5px",
        top: "5px",
      },
      [largeScreen]: {
        right: "10px",
        top: "10px",
      },
    },
    label: {
      fontSize: "1rem",
      paddingTop: "5px",
    },
    cancelButton: {
      textTransform: "none",
      borderRadius: "20px",
      width: "100%",
      textAlign: "center",
      display: "block",
      fontSize: "1rem",
      background: theme.palette.secondary.light,
      color: "#000",

      "&:hover": {
        background: theme.palette.secondary.main,
      },
    },
    successButton: {
      textDecoration: "none",
      borderRadius: "20px",
      width: "100%",
      textAlign: "center",
      display: "block",
      fontSize: "1rem",
      color: "#fff",
    },
    cargillLogoContainer: {
      padding: "1% 6%",
    },
    termsLink: {
      whiteSpace: "nowrap",
      color: "#000",
      textDecoration: "none",
      opacity: 1,
    },
    consentBlackbar: {
      "& div#truste-consent-track": {
        zIndex: 999,
      },
    },
  };
});
