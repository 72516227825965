import { combineReducers } from "redux";
// This is local storage for session storage import is different
import storage from "redux-persist/lib/storage";
import { giftReducer } from "./gift.reducer";
import { basketReducer } from "./basket.reducer";
import { homeReducer } from "./home.reducer";
import { invoiceReducer } from "./invoice.reducer";
import { orderReducer } from "./order.reducer";
import { accountReducer } from "./account.reducer";
import { userReducer } from "./user.reducer";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "amphoraState",
  storage,
  whitelist: ["products", "cart", "home", "invoice", "orders", "account", "user"],
};

export const rootReducer = combineReducers({
  products: giftReducer,
  cart: basketReducer,
  home: homeReducer,
  invoice: invoiceReducer,
  orders: orderReducer,
  account: accountReducer,
  user: userReducer,
});

export default persistReducer(persistConfig, rootReducer);
