import { axiosInstance } from "utils/axios";
import {
  SET_LANG,
  REGISTER_USER,
  REGISTER_USER_ERROR_MESSAGE,
  SET_USER,
  SET_OKTAID,
  SET_TOKEN,
  UPDATE_USER,
  UPDATE_USER_POINTS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
  UserActionType,
  addressActionType,
  SHOW_MOBILE_QR_CODE_DIALOG,
  UserRegisterActionType,
  COUNTRIES,
  TOKEN_EXPIRED,
  ContactUs,
  ZONE,
  COUNTRY_CODE,
} from "./user.type";
import {
  languages,
  UserI,
  AddressFormI,
  EditUserI,
  CountryI,
  ZoneI,
  ContactUsI,
  CountryCode,
} from "interface/user.model";
import { AppThunk, AppDispatch } from "state/store";
import { getOktaId } from "config";
import { getCartItems } from "../basket/basket.action";
import { fetchLogo, getPointsMechanismData, fetchLegalDocs } from "../home/home.action";
import i18n from "translations/i18";

export const registerUserActionCreator = (data: boolean | undefined): UserRegisterActionType => {
  return {
    type: REGISTER_USER,
    payload: data,
  };
};

export const registerUserErrMsgActionCreator = (data: string): UserRegisterActionType => {
  return {
    type: REGISTER_USER_ERROR_MESSAGE,
    payload: data,
  };
};

export const setLangActionCreator = (lang: languages): UserActionType => {
  i18n.changeLanguage(lang);
  return {
    type: SET_LANG,
    payload: lang,
  };
};

export const setOktaIdActionCreator = (id: string): UserActionType => {
  return {
    type: SET_OKTAID,
    payload: id,
  };
};

export const setTokenActionCreator = (token: string): UserActionType => {
  return {
    type: SET_TOKEN,
    payload: token,
  };
};

export const setUserActionCreator = (user: UserI): UserActionType => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export const updateUserActionCreator = (userEdit: EditUserI): UserActionType => {
  return {
    type: UPDATE_USER,
    payload: userEdit,
  };
};

export const updatePointsActionCreator = (points: number): UserActionType => {
  return {
    type: UPDATE_USER_POINTS,
    payload: points,
  };
};

export const showMobileQrDialog = (showDialog: boolean): UserActionType => {
  return {
    type: SHOW_MOBILE_QR_CODE_DIALOG,
    payload: showDialog,
  };
};
const updateAddressActionCreator = (address: AddressFormI): addressActionType => {
  return {
    type: UPDATE_ADDRESS,
    payload: address,
  };
};

export const deleteAddressActionCreator = (addressId: number): addressActionType => {
  return {
    type: DELETE_ADDRESS,
    payload: addressId,
  };
};

export const fetchCountriesActionCreator = (regionInfo: CountryI[]): UserRegisterActionType => {
  return {
    type: COUNTRIES,
    payload: regionInfo,
  };
};

export const tokenExpiredActionCreator = (isExpired: boolean): UserActionType => {
  return {
    type: TOKEN_EXPIRED,
    payload: isExpired,
  };
};

export const zoneActionCreator = (data: ZoneI): ContactUs => {
  return {
    type: ZONE,
    payload: data,
  };
};

export const countryCodeActionCreator = (country: CountryCode) => {
  return {
    type: COUNTRY_CODE,
    payload: country,
  };
};

export const userRegistration = (data: any) => {
  return (dispatch: AppDispatch) => {
    const registerUserURL = "users/createUser";
    axiosInstance
      .post(registerUserURL, data)
      .then((response) => {
        dispatch(registerUserActionCreator(true));
      })
      .catch((error) => {
        dispatch(registerUserActionCreator(false));
        dispatch(registerUserErrMsgActionCreator(error.response.data));
      });
  };
};

export const fetchCountries = () => {
  return (dispatch: AppDispatch) => {
    const pathParameter = "countries";
    axiosInstance
      .get(pathParameter)
      .then((response) => dispatch(fetchCountriesActionCreator(response.data)))
      .catch((err) => console.error(err));
  };
};

export const getUser = (oktaId?: any): AppThunk => {
  return async (dispatch: AppDispatch) => {
    if (oktaId === undefined) oktaId = getOktaId();
    const url = "users/getUsersByOktaId/" + oktaId;
    axiosInstance
      .get(url)
      .then((resp) => {
        const { data }: { data: UserI } = resp;
        dispatch(setLangActionCreator(data.userLocale));
        dispatch(countryCodeActionCreator(data.countryCode));
        dispatch(setUserActionCreator(data));
        dispatch(fetchLogo(data.countryCode));
        dispatch(fetchLegalDocs(data.countryCode));
        dispatch(getPointsMechanismData());
        dispatch(getCartItems());
      })
      .catch((error) => console.error(error));
  };
};

export const editUSER = (userEdit: EditUserI): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = "users/updateUser/";
    axiosInstance
      .put(url, userEdit)
      .then((resp) => {
        dispatch(updateUserActionCreator(userEdit));
      })
      .catch((error) => {
        console.error("Failure while editing user details", error);
      });
  };
};

export const addUserAddress = (address: AddressFormI): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = "users/userAddress";
    axiosInstance
      .post(url, address)
      .then((resp) => {
        dispatch(getUser());
      })
      .catch((error) => {
        console.error("Failure while adding user address", error);
      });
  };
};

export const editUserAddress = (address: AddressFormI): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = "users/updateUserAddress";
    axiosInstance
      .put(url, address)
      .then((resp) => {
        dispatch(updateAddressActionCreator(address));
      })
      .catch((error) => {
        console.error("Failure while editing user address", error);
      });
  };
};

export const deleteUserAddress = (userAddressId: number): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const url = "users/deleteUserAddressById/" + userAddressId;
    axiosInstance
      .delete(url)
      .then((resp) => {
        dispatch(deleteAddressActionCreator(userAddressId));
      })
      .catch((error) => {
        console.error("Failure while deleting user address", error);
      });
  };
};

export const getAllZones = (countryCode: CountryCode): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      const endPoint = "zone/getAllZones";
      const { data }: { data: ZoneI } = await axiosInstance.get(endPoint, {
        params: {
          countryCode,
        },
      });
      if (data) dispatch(zoneActionCreator(data));
    } catch (error) {
      console.error("Failure while fetching Zones Data", error);
    }
  };
};

export const submitContactForm = (contactUsForm: ContactUsI): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      const endPoint = "users/createContactUsRequestForCms";
      const { data } = await axiosInstance.post(endPoint, contactUsForm);
      return data;
    } catch (error: any) {
      throw error.response.data;
    }
  };
};
