import {
  ORDERS,
  ORDER_IMGSRC,
  PLACED_ORDER,
  PLACED_ORDER_IMGSRC,
  ORDER_COMPONENT_MOUNTED,
  orderActionType,
  ORDER_DETAILS_VIEW,
} from "../actions/order/order.type";

import { OrderState } from "interface/order.model";

const intialState: OrderState = {
  orders: [],
  placedOrder: { orderId: 0 },
  isOrderMounted: false,
  orderDetailsView: undefined,
};

export const orderReducer = (state: OrderState = intialState, action: orderActionType): OrderState => {
  switch (action.type) {
    case ORDERS: {
      return {
        ...state,
        orders: [...action.payload],
      };
    }
    case ORDER_IMGSRC: {
      const orderDetailsId = action.payload.orderDetailsId;
      const src = action.payload.src;
      return {
        ...state,
        orders: state.orders.map((order) => {
          order.orderDetails.map((item) => {
            if (item.orderDetailsId === orderDetailsId) {
              return {
                ...item,
                giftProduct: {
                  ...item.giftProduct,
                  img1src: src,
                },
              };
            }
            return item;
          });
          return order;
        }),
      };
    }
    case PLACED_ORDER: {
      return {
        ...state,
        placedOrder: action.payload,
      };
    }
    case PLACED_ORDER_IMGSRC: {
      return {
        ...state,
        placedOrder: {
          ...state.placedOrder,
          itemResponseDTOList: state.placedOrder?.itemResponseDTOList?.map((item, index) => {
            if (item.productId === action.payload.orderDetailsId) {
              return {
                ...item,
                image1src: action.payload.src,
              };
            }
            return item;
          }),
        },
      };
    }
    case ORDER_COMPONENT_MOUNTED: {
      return {
        ...state,
        isOrderMounted: action.payload,
      };
    }
    case ORDER_DETAILS_VIEW: {
      return {
        ...state,
        orderDetailsView: action.payload,
      };
    }
    default:
      return state;
  }
};
