import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import { Landing } from "routes/Landing";
import LetsStart from "components/login/LetsStart.component";
import Login from "views/Login.page";
import { PdfEmbeder } from "components/common/pdfEmbeder.component";
import { fetchCountries } from "state/actions/user/user.action";
import { fetchLegalDocs } from "state/actions/home/home.action";
import ScrollToTop from "utils/ScrollToTop";
import history from "./history";
import { oidc } from "../config";
import { useSelector } from "react-redux";
import { useAppDispatch } from "state/store";
import { useEffect } from "react";
// import { changeTrustArcSrc } from "utils/changeTrustArcSrc";

const oktaAuth = new OktaAuth(oidc);

function RootNavigator() {
  const countryCode = useSelector((state) => state.user.countryCode);
  // const localeCode = useSelector((state) => state.user.localeCode);
  const legalDocsObj = useSelector((state) => state.home.legalDocsObj);
  const dispatch = useAppDispatch();
  const customAuthHandler = () => {
    history.push("/login");
  };

  async function restoreOriginalUri(_oktaAuth, originalUri) {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  }

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);
  
  useEffect(() => {
    dispatch(fetchLegalDocs(countryCode));
  }, [dispatch, countryCode]);

  // useEffect(() => {
  //   changeTrustArcSrc(localeCode)
  // }, [localeCode]);

  return (
    <Router history={history}>
      <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
        <ScrollToTop />
        <Switch>
          <Route path='/login' render={() => <Login />} />
          <Route path='/lets-start' component={LetsStart}></Route>
          <Route exact path='/loyalty-terms' render={() => <PdfEmbeder src={legalDocsObj.loyaltyTermPdfSrc} />} />
          <Route exact path='/web-terms' render={() => <PdfEmbeder src={legalDocsObj.webTermPdfSrc} />} />
          <SecureRoute exact path='/'>
            <Redirect to='/home' />
          </SecureRoute>
          <SecureRoute path='/' component={Landing} />
          <Route path='/login/callback' component={LoginCallback} />
        </Switch>
      </Security>
    </Router>
  );
}

export default RootNavigator;
