import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#CB521E",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FDBE31",
    },

    text: {
      primary: "rgb(0,0,0)",
      secondary: "rgba(0,0,0,0.50)",
    },
  },
  typography: {
    h1: {
      fontFamily: "Helvetica",
      fontSize: "5rem",
      textAlign: "left",
      color: "#000",
      fontWeight: "bold",
      letterSpacing: "1.4px",
    },
    h3: {
      fontFamily: "Helvetica",
      fontSize: "1.7rem",
      textAlign: "left",
      color: "#000",
      // fontWeight: "bold",
      letterSpacing: "1.2px",
    },
    h4: {
      color: "#707070",
      fontWeight: "bold",
    },
    h5: {
      fontWeight: 100,
      fontSize: "30px",
      lineHeight: "42px",
    },
    h6: {
      color: "#707070",
      fontSize: "1rem",
    },
    subtitle1: {
      fontFamily: "Helvetica",
      fontSize: "1rem",
      textAlign: "left",
      color: "#000",
      letterSpacing: "1px",
      opacity: "0.9",
    },
    subtitle2: {
      fontFamily: "Helvetica",
      fontSize: "1rem",
      textAlign: "left",
      color: "#000",
      letterSpacing: "1px",
      opacity: "0.6",
    },
    body1: {
      fontFamily: "Helvetica",
      fontSize: "1.1rem",
      textAlign: "left",
      color: "#000",
      letterSpacing: "1.1px",
      opacity: 1,
    },
    body2: {
      fontSize: "1rem",
    },
    button: {
      borderRadius: "5px",
      textTransform: "capitalize",
    },
  },

  spacing: (factor) => `${factor}%`,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
      text: {
        color: "white",
      },
      containedSecondary: {
        backgroundColor: "#000",
        color: "#fff",
      },
    },
    MuiGrid: {
      "spacing-xs-5": {
        width: "100%",
        margin: 0,
      },
    },
    MuiFormLabel: {
      colorSecondary: {
        root: "red",
      },
      root: {
        color: "grey",
        paddingBottom: "10px",
      },
    },
  },
});
