import { Box, Dialog, Divider, IconButton, List, ListItem, ListItemText, Switch, makeStyles } from "@material-ui/core";
import { Button, Typography } from "./Common.component";
import React, { useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    padding: "0px 40px 0px 40px",
    position: "fixed",
    width: "100%",
    minHeight: "35px",
    bottom: "0px",
    left: "0px",
    zIndex: 1000,
  },
  text: {
    color: "white",
    flexGrow: 1,
  },
  link: {
    whiteSpace: "nowrap",
    color: "white",
    fontWeight: "bold",
    margin: "0px 0px 0px 0.5rem",
    textDecoration: "underline",
    cursor: "pointer",
  },
  button: {
    backgroundColor: "white",
    minWidth: "100px",
    color: "black",
    margin: "0px 3% 0px 3%",
    padding: "1px 30px 1px 30px",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#ffe4bc",
      // backgroundColor: "white"
    },
  },

  dialogRoot: {
    // maxWidth: "500px"
  },
  dialogheader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "bold",
  },
  contentBox: {
    minHeight: "300px",
  },
  leftBar: {
    minWidth: "200px",
    backgroundColor: "rgb(220, 220, 220)",
    "& ul": {
      padding: "0px 0px 0px 0px !important",
      "& li div span": {
        fontSize: "15px",
        fontWeight: "bold",
      },
    },
    "& ul .not-active": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgb(200, 200, 200)",
      },
    },
    "& ul .active": {
      cursor: "pointer",
      backgroundColor: "white",
    },
  },
  rightContent: {
    padding: "20px 30px 20px 30px",
  },

  footer: {
    backgroundColor: theme.palette.primary.dark,
    padding: "13px 20px 13px 20px",
  },
}));

interface DialogProps {
  open: boolean;
  setDisplay: (val: React.CSSProperties["display"]) => void;
  handleClose: () => void;
}
export function CookieDialog(props: DialogProps) {
  const [t] = useTranslation();
  const { open, setDisplay, handleClose } = props;
  const [actIndex, setactIndex] = useState(0);
  const cookies_list = [t("required_cookies"), t("performance_cookies"), t("functional_cookies")];
  const cookie_content = [t("required_cookies_text"), t("performance_cookies_text"), t("functional_cookies_text")];

  const classes = useStyles();
  return (
    <Dialog className={classes.dialogRoot} open={open} onClose={handleClose}>
      <MuiDialogTitle disableTypography className={classes.dialogheader}>
        <Typography fw='bold' fs='22px' ml={15}>
          {t("advanced_cookie_settings")}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <Divider />
      <Box className={classes.contentBox} display='flex' alignItems='start'>
        <Box className={classes.leftBar}>
          <List>
            {cookies_list.map((item, index) => (
              <ListItem
                className={index === actIndex ? "active" : "not-active"}
                key={index}
                onClick={() => setactIndex(index)}
              >
                <ListItemText>{item}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box className={classes.rightContent}>
          <Box display='flex' justifyContent='space-between'>
            <Typography fs='15px' fw='bold'>
              {cookies_list[actIndex]}
            </Typography>
            <Switch />
          </Box>
          <Typography fs='14px'>{cookie_content[actIndex]}</Typography>
        </Box>
      </Box>
      <Box className={classes.footer} display='flex' justifyContent='space-between' alignItems='center'>
        <Button
          className={classes.button}
          onClick={() => {
            handleClose();
            setDisplay("none");
          }}
        >
          {t("confirm_my_choices")}
        </Button>
        <Button
          className={classes.button}
          onClick={() => {
            handleClose();
            setDisplay("none");
          }}
        >
          {t("accept_all")}
        </Button>
      </Box>
    </Dialog>
  );
}

export function CookieBanner() {
  // const display: React.CSSProperties["display"] = localStorage.getItem("cookie_visibility") as React.CSSProperties["display"]
  const [t] = useTranslation();
  const [display, setDsp] = useState<React.CSSProperties["display"]>(
    localStorage.getItem("cookie_visibility") as React.CSSProperties["display"]
  );
  const setDisplay = (val: React.CSSProperties["display"]) => {
    localStorage.setItem("cookie_visibility", val as string);
    setDsp(val);
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <Box style={{ display: display }}>
      <Box className={classes.root} display='flex' alignItems='center'>
        <Typography fs='0.8rem' fc='#fff'>
          {t("cookies_text")}
          <a
            className={classes.link}
            href='https://amphora-restauration.fr/mentions-legales'
            rel='noreferrer'
            target='_blank'
          >
            {t("view_privacy_policy")}
          </a>
        </Typography>
        <p className={classes.link} onClick={() => setOpen(true)}>
          {t("cookies_setting")}
        </p>
        <Button className={classes.button} onClick={() => setDisplay("none")}>
          {t("accept_all")}
        </Button>
      </Box>

      <CookieDialog open={open} setDisplay={setDisplay} handleClose={handleClose} />
    </Box>
  );
}
