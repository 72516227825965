import { OrderDetailsI, OrderI } from "interface/order.model";

export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function buildOfferStructure(offerList: any): any {
  var enOfferList: any = [];
  var frOfferList: any = [];
  var offerObj: any = {
    en: enOfferList,
    fr: frOfferList,
  };

  if (offerList.length !== 0) {
    offerList.forEach((offer: any) => {
      if (offer.localeCode === "EN") {
        enOfferList.push(offer);
      }
      if (offer.localeCode === "FR") {
        frOfferList.push(offer);
      }
    });
    offerObj = {
      en: enOfferList,
      fr: frOfferList,
    };
    return offerObj;
  }

  return offerObj;
}

function amphoraProductList(productList: any): any {
  if (productList.length !== 0) {
    var products: any = [];
    productList.map((item: any) => products.push(item.productName));
    return products;
  }
  return [];
}

function amphoraProductCapacityList(productList: any): any {
  if (productList.length !== 0) {
    var productCapacityObject: any = {};
    productList.forEach((item: any) => {
      var volumeList: any = [];
      if (item.volumeList.length !== 0) {
        item.volumeList.map((volumeItem: any) => volumeList.push(volumeItem.volume));
        productCapacityObject[item.productName] = volumeList;
      }
    });
    return productCapacityObject;
  }

  return {};
}

function buildProductReferenceMap(productList: any): any {
  if (productList.length !== 0) {
    var productReferenceObject: any = {};
    productList.forEach((item: any) => {
      productReferenceObject[item.productName] = item.referenceNumber;
      productReferenceObject[item.productName + "_volumeRef"] = item.volumeList;
    });
    return productReferenceObject;
  }

  return {};
}

function buildDistributorReferenceMap(distributorList: any): any {
  if (distributorList.length !== 0) {
    var distributorReferenceObject: any = {};
    distributorList.forEach((item: any) => {
      var name = item.name1 + "-" + item.postalCode; //+ " " + item.name2;
      distributorReferenceObject[name] = item.referenceNumber;
    });
    return distributorReferenceObject;
  }

  return {};
}

function messageListSplit(messages: any): any {
  var messageOpenList: any = [];
  var messageClosedList: any = [];
  var messageObject: any = {
    messagesOpen: messageOpenList,
    messagesClosed: messageClosedList,
  };
  if (messages.length !== 0) {
    messages.forEach((message: any) => {
      if (message.status === "Open") {
        messageOpenList.push(message.comments);
      }
      if (message.status === "Closed") {
        messageClosedList.push(message.comments);
      }
    });
    messageObject = {
      messagesOpen: messageOpenList,
      messagesClosed: messageClosedList,
    };
    return messageObject;
  }
  return messageObject;
}

type filterList = {
  [orderId: string]: OrderDetailsI[];
};
function filterOrdersBasedOnStatus(orderList: Array<OrderI>, orderStatuses: Array<string>) {
  const orderByStatus: filterList = {};
  orderList.forEach((order) => {
    const { orderDetails, orderId } = order;
    orderDetails.forEach((item) => {
      orderStatuses.forEach((orderStatus) => {
        if (item.statusCode === orderStatus) {
          if (!orderByStatus[orderId]) orderByStatus[orderId] = [item];
          else {
            const tempArr = orderByStatus[orderId];
            orderByStatus[orderId] = [...tempArr, item];
          }
        }
      });
    });
  });
  return orderByStatus;
}

function formatDate(value: Date) {
  let date = new Date(value);

  let day = date.getDate();
  let monthIndex = date.getMonth();
  let monthName = monthNames[monthIndex];
  let year = date.getFullYear();
  return `${day}-${monthName}-${year}`;
}

const productQuantityMessage = (quantity: string, t: any): string => {
  if (parseInt(quantity) < 5) {
    return `${t("only")} ${quantity} ${t("stock_left")}`;
  }
  return "";
};

const noSplCharForName = /^[^*|":<>.[\]{}`\\()';#%@!,?_+*=/\-&*$0-9]+$/;

const getDateFormat = (date: string) => {
  const completeDate = new Date(date);
  const onlyDate = completeDate.getDate();
  const month = completeDate.getMonth();
  const year = completeDate.getFullYear();

  return `${onlyDate ? String(onlyDate).padStart(2, "0") : null} / ${
    month >= 0 ? String(month + 1).padStart(2, "0") : null
  } / ${year}`;
};

export {
  buildOfferStructure,
  amphoraProductList,
  amphoraProductCapacityList,
  buildDistributorReferenceMap,
  buildProductReferenceMap,
  messageListSplit,
  filterOrdersBasedOnStatus,
  formatDate,
  productQuantityMessage,
  noSplCharForName,
  getDateFormat,
};
